<template>
  <div
    class="demo_platform_wrap form-part"
    v-if="
      (brokerData !== null && currentDemoPlatforms.length > 0) ||
      ($route.name !== 'brokerReview' && getSelectedTab.title !== 'logs') ||
      ($route.name === 'brokerEdit' && getSelectedTab.title !== 'logs')
    "
  >
    <h3 class="fieldset__title">Demo account platforms</h3>
    <DemoPlatformContainer
      v-for="(demoPlatform, index) in currentDemoPlatforms"
      :key="demoPlatform.id"
      :id="demoPlatform.id"
      :canBeDeleted="canBeDeleted"
      :platformData="
        brokerData !== undefined &&
        ($route.name === 'brokerReview' || getSelectedTab.title !== 'logs') &&
        brokerData.find((item) => item.id === demoPlatform.id)
          ? brokerData.find((item) => item.id === demoPlatform.id)
          : brokerData !== undefined &&
            ($route.name === 'brokerReview' ||
              getSelectedTab.title !== 'logs') &&
            brokerData.find((item) => item.id === demoPlatform.id) === -1
          ? demoPlatform
          : brokerData !== undefined &&
            $route.name === 'brokerEdit' &&
            brokerData.find((item) => item.id === demoPlatform.id)
          ? brokerData.find((item) => item.id === demoPlatform.id)
          : preparingDataServices.createDemoPlatform()
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      :changesData="
        changesBrokerData !== undefined
          ? changesBrokerData.find((item) => item.id === demoPlatform.id)
            ? brokerData.length > 0
              ? changesBrokerData.find((item) => item.id === demoPlatform.id)
              : demoPlatform
            : preparingDataServices.createDemoPlatform()
          : !changesBrokerData
          ? undefined
          : !getBrokerData.length && changesBrokerData[index]
          ? changesBrokerData[index]
          : undefined
      "
      @demoPlatformRemoved="checkDemoPlatformsLength"
    />
    <button
      type="button"
      class="add_history_item"
      @click="addDemoPlatformItem(preparingDataServices.createDemoPlatform())"
      v-if="
        ($route.name !== 'brokerReview' && getSelectedTab.title !== 'logs') ||
        ($route.name === 'brokerEdit' && getSelectedTab.title !== 'logs')
      "
    >
      <img src="../../assets/svg/ico-plus.svg" alt="+" />
      <span> Add another demo account platform </span>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import PreparingDataServices from "@/services/PreparingDataServices";
import DemoPlatformContainer from "@/components/formFields/DemoPlatformContainer";
import CompairingService from "@/services/CompairingService";

export default {
  name: "demoPlatformsInput",

  components: {
    DemoPlatformContainer,
  },
  props: {
    id: [String, Number],
    brokerData: [Array, Object],
    changesBrokerData: Array,
    checker: String,
  },
  data() {
    return {
      preparingDataServices: new PreparingDataServices(),
      compairingService: new CompairingService(),
      created: false,
    };
  },
  computed: {
    ...mapGetters([
      "getBrokerData",
      "getCurrentMode",
      "getIsChanged",
      "getSelectedTab",
    ]),
    canBeDeleted: function () {
      return (
        (this.$route.name !== "brokerReview" &&
          this.getSelectedTab.title !== "logs") ||
        (this.$route.name === "brokerEdit" &&
          this.getSelectedTab.title !== "logs")
      );
    },
    currentDemoPlatforms: (vm) => {
      if (
        vm.$route.name !== "brokerReview" &&
        vm.getSelectedTab.title !== "logs"
      ) {
        if (
          !vm.getBrokerData.brokerDemoPlatforms.length &&
          !vm.created &&
          !vm.getIsChanged
        ) {
          vm.addDemoPlatformItem(vm.preparingDataServices.createDemoPlatform());
          vm.created = true;
        }
        return vm.getBrokerData.brokerDemoPlatforms;
      } else if (vm.getSelectedTab.title === "logs" && !vm.brokerData) {
        return vm.changesBrokerData;
      } else if (
        vm.changesBrokerData !== undefined &&
        vm.brokerData !== undefined
      ) {
        return vm.compairingService.getComparedInputData(
          vm.brokerData,
          vm.changesBrokerData,
          "brokerDemoPlatforms"
        );
      } else if (vm.changesBrokerData === undefined && vm.brokerData) {
        return vm.brokerData;
      }
    },
  },
  methods: {
    ...mapMutations(["addDemoPlatformItem", "removeDemoPlaform"]),
    checkDemoPlatformsLength() {
      if (
        !this.currentDemoPlatforms.length &&
        this.getCurrentMode !== "review"
      ) {
        this.addDemoPlatformItem(
          this.preparingDataServices.createDemoPlatform()
        );
      }
    },
  },
  mounted() {
    this.created = false;
  },
};
</script>
