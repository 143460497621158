var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return ((_vm.accountTypes || _vm.generalReview) && _vm.showForm)?_c('form',{staticClass:"account_types",attrs:{"id":"account_types"}},[(
      (_vm.currentBrokerData &&
        !(
          Object.hasOwnProperty(_vm.currentBrokerData, 'deletingMode') &&
          _vm.currentBrokerData.deletingMode
        ) &&
        _vm.getSelectedTab.title !== 'logs') ||
      (_vm.getSelectedTab.title === 'logs' && this.oldData.name)
    )?_c('button',{staticClass:"btn save_broker",attrs:{"type":"button","disabled":(_vm.getCurrentMode === 'review' && _vm.toReview(_vm.getAccountType)) ||
      (_vm.getCurrentMode !== 'review' && !_vm.getIsChanged)},on:{"click":function($event){$event.preventDefault();_vm.getCurrentMode === 'edit' &&
      (_vm.currentAccountTypeId === 'newAccType' || _vm.currentAccountTypeId === null)
        ? (_vm.checkAnchor = false)
        : _vm.checkError()}}},[_vm._v(" Save ")]):_vm._e(),(
      ((_vm.accountTypes && _vm.accountTypes.length) ||
        (_vm.generalReview && _vm.generalReview.accountTypes.length)) &&
      _vm.getSelectedTab.title !== 'logs'
    )?_c('div',{staticClass:"account_types__toggler"},[_vm._l((this.$route.name !== 'brokerReview'
        ? _vm.accountTypes
        : _vm.generalReview.accountTypes),function(accountType){return _c('button',{key:accountType.id,staticClass:"account_types__toggler-item",class:_vm.currentAccountTypeId === accountType.id
          ? 'account_types__toggler-item--active'
          : '',attrs:{"disabled":accountType && accountType.deletingMode},on:{"click":function($event){$event.preventDefault();return _vm.changeSubTab(accountType.id, accountType.name)}}},[_vm._v(" "+_vm._s(accountType.name)+" ")])}),(_vm.$route.name !== 'brokerReview')?_c('button',{staticClass:"account_types__toggler-item",class:_vm.currentAccountTypeId === 'newAccType'
          ? 'account_types__toggler-item--active'
          : '',on:{"click":function($event){$event.preventDefault();return _vm.changeSubTab('newAccType')}}},[_vm._v(" New Account ")]):_vm._e()],2):_vm._e(),(_vm.currentBrokerData && !_vm.currentBrokerData.deletingMode)?_c('div',{key:_vm.currentAccountTypeId !== null && _vm.currentAccountTypeId !== 'newAccType'
        ? _vm.currentBrokerData.id
        : 'new',staticClass:"form__content",staticStyle:{"width":"100%"}},[_c('AccountTypeMain',{key:_vm.currentAccountTypeId !== null && _vm.currentAccountTypeId !== 'newAccType'
          ? _vm.currentAccountTypeId
          : 'new',attrs:{"accountTypeData":{
        name: _vm.currentBrokerData.name,
        minAccountSize: _vm.currentBrokerData.minAccountSize,
        maxAccountSize: _vm.currentBrokerData.maxAccountSize,
        minPositionSize: _vm.currentBrokerData.minPositionSize,
      },"accountTypeChanges":(this.getCurrentMode === 'review' ||
          _vm.getSelectedTab.title === 'logs') &&
        _vm.changedBrokerData
          ? {
              name: _vm.changedBrokerData.name,
              minAccountSize: _vm.changedBrokerData.minAccountSize,
              maxAccountSize: _vm.changedBrokerData.maxAccountSize,
              minPositionSize: _vm.changedBrokerData.minPositionSize,
            }
          : null,"anchorExist":this.anchorExist}}),_c('CurrencyPairsBlock',{attrs:{"brokerData":_vm.currentCurrencyPairs,"changesBrokerData":(_vm.getSelectedTab.title === 'logs' || _vm.getCurrentMode === 'review') &&
        _vm.changedBrokerData
          ? _vm.changedBrokerData.accountTypeCurrencyPairs
          : undefined}}),_c('AccountTypeFinancial',{attrs:{"accountTypeData":{
        minDistanceToLimitOrders: _vm.currentBrokerData.minDistanceToLimitOrders,
        commission: _vm.currentBrokerData.commission,
        marginCallLevel: _vm.currentBrokerData.marginCallLevel,
        commissionCfd: _vm.currentBrokerData.commissionCfd,
        stopOutLevel: _vm.currentBrokerData.stopOutLevel,
        minDistanceToStopOrders: _vm.currentBrokerData.minDistanceToStopOrders,
        maxPositionsSimultaneous: _vm.currentBrokerData.maxPositionsSimultaneous,
      },"accountTypeChanges":(this.getCurrentMode === 'review' ||
          _vm.getSelectedTab.title === 'logs') &&
        _vm.changedBrokerData
          ? {
              minDistanceToLimitOrders:
                _vm.changedBrokerData.minDistanceToLimitOrders,
              commission: _vm.changedBrokerData.commission,
              marginCallLevel: _vm.changedBrokerData.marginCallLevel,
              commissionCfd: _vm.changedBrokerData.commissionCfd,
              stopOutLevel: _vm.changedBrokerData.stopOutLevel,
              minDistanceToStopOrders:
                _vm.changedBrokerData.minDistanceToStopOrders,
              maxPositionsSimultaneous:
                _vm.changedBrokerData.maxPositionsSimultaneous,
            }
          : null}}),_c('AccountPermissions',{attrs:{"accountTypeData":{
        scalpingAllowed: _vm.currentBrokerData.scalpingAllowed,
        scalpingExplanations: _vm.currentBrokerData.scalpingExplanations,
        eaAllowed: _vm.currentBrokerData.eaAllowed,
        eaExplanations: _vm.currentBrokerData.eaExplanations,
      },"accountTypeChanges":(this.getCurrentMode === 'review' ||
          _vm.getSelectedTab.title === 'logs') &&
        _vm.changedBrokerData
          ? {
              scalpingAllowed: _vm.changedBrokerData.scalpingAllowed,
              scalpingExplanations: _vm.changedBrokerData.scalpingExplanations,
              eaAllowed: _vm.changedBrokerData.eaAllowed,
              eaExplanations: _vm.changedBrokerData.eaExplanations,
            }
          : null}}),_c('SpecialConditions',{attrs:{"accountTypeData":_vm.currentBrokerData.accountTypeSpecialConditions
          ? _vm.currentBrokerData.accountTypeSpecialConditions
          : null,"accountTypeChanges":(this.getCurrentMode === 'review' ||
          _vm.getSelectedTab.title === 'logs') &&
        _vm.changedBrokerData
          ? _vm.changedBrokerData.accountTypeSpecialConditions
          : null}}),_c('AccountSelects',{attrs:{"accountTypeData":{
        hedgingAllowed: _vm.currentBrokerData.hedgingAllowed,
        interestRate: _vm.currentBrokerData.interestRate,
        digitsAfterDot: _vm.currentBrokerData.digitsAfterDot,
        centAccount: _vm.currentBrokerData.centAccount,
        executionMethod: _vm.currentBrokerData.executionMethod,
      },"accountTypeChanges":(this.getCurrentMode === 'review' ||
          _vm.getSelectedTab.title === 'logs') &&
        _vm.changedBrokerData
          ? {
              hedgingAllowed: _vm.changedBrokerData.hedgingAllowed,
              interestRate: _vm.changedBrokerData.interestRate,
              digitsAfterDot: _vm.changedBrokerData.digitsAfterDot,
              centAccount: _vm.changedBrokerData.centAccount,
              executionMethod: _vm.changedBrokerData.executionMethod,
            }
          : null}}),_c('CheckboxSelect',{key:_vm.checkboxSelectKey,attrs:{"type":"instruments","title":"Instrument","subtitle":"Max. Leverage (w/o \"1:\")","brokerData":_vm.currentInstruments,"changesBrokerData":_vm.getSelectedTab.title === 'logs'
          ? _vm.changedBrokerData.accountTypeInstruments
          : this.getCurrentMode === 'review' &&
            _vm.accountTypeReview &&
            _vm.accountTypeReview.changesData
          ? _vm.accountTypeReview.changesData.accountTypeInstruments
          : undefined}}),_c('AccountTradingPlatform',{attrs:{"accountTypeData":{
        tradingPlatforms: _vm.currentBrokerData.tradingPlatforms,
        currencyPairsQuantity: _vm.currentBrokerData.currencyPairsQuantity,
        exoticCurrencyPairsQuantity:
          _vm.currentBrokerData.exoticCurrencyPairsQuantity,
      },"accountTypeChanges":(this.getCurrentMode === 'review' ||
          _vm.getSelectedTab.title === 'logs') &&
        _vm.changedBrokerData
          ? {
              tradingPlatforms: _vm.changedBrokerData.tradingPlatforms,
              currencyPairsQuantity: _vm.changedBrokerData.currencyPairsQuantity,
              exoticCurrencyPairsQuantity:
                _vm.changedBrokerData.exoticCurrencyPairsQuantity,
            }
          : null}}),_c('AccountProperties',{attrs:{"accountTypeData":{
        automated: _vm.currentBrokerData.automated,
        api: _vm.currentBrokerData.api,
        web_based: _vm.currentBrokerData.web_based,
        oneClick: _vm.currentBrokerData.oneClick,
        oco: _vm.currentBrokerData.oco,
        pendingOrders: _vm.currentBrokerData.pendingOrders,
        trailingStop: _vm.currentBrokerData.trailingStop,
        operatingSystems: _vm.currentBrokerData.operatingSystems,
      },"accountTypeChanges":(this.getCurrentMode === 'review' ||
          _vm.getSelectedTab.title === 'logs') &&
        _vm.changedBrokerData
          ? {
              automated: _vm.changedBrokerData.automated,
              api: _vm.changedBrokerData.api,
              web_based: _vm.changedBrokerData.web_based,
              oneClick: _vm.changedBrokerData.oneClick,
              oco: _vm.changedBrokerData.oco,
              pendingOrders: _vm.changedBrokerData.pendingOrders,
              trailingStop: _vm.changedBrokerData.trailingStop,
              operatingSystems: _vm.changedBrokerData.operatingSystems,
            }
          : null}})],1):(_vm.currentBrokerData && _vm.currentBrokerData.deletingMode)?_c('div',{},[_c('center',{staticStyle:{"font-size":"18px"}},[_vm._v(" User ask to delete the \""+_vm._s(_vm.currentBrokerData.name)+"\" account. Please approve or cancel it. ")]),_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c('button',{staticClass:"btn",staticStyle:{"margin":"15px"},on:{"click":function($event){$event.preventDefault();return _vm.approveDeleting(true)}}},[_vm._v(" Approve ")]),_c('button',{staticClass:"btn btn--error",staticStyle:{"margin":"15px"},attrs:{"type":"button"},on:{"click":function($event){return _vm.approveDeleting(false)}}},[_vm._v(" Cancel ")])])],1):_vm._e(),(
      _vm.getCurrentMode === 'review' &&
      _vm.currentBrokerData &&
      !_vm.currentBrokerData.deletingMode
    )?_c('MassToggler'):_vm._e(),(
      (_vm.currentBrokerData &&
        !_vm.currentBrokerData.deletingMode &&
        this.getSelectedTab.title !== 'logs') ||
      (this.getSelectedTab.title === 'logs' && this.oldData.name)
    )?_c('button',{staticClass:"btn btn--big",attrs:{"type":"button","disabled":(_vm.getCurrentMode === 'review' && _vm.toReview(_vm.getAccountType)) ||
      (_vm.getCurrentMode !== 'review' && !_vm.getIsChanged)},on:{"click":function($event){$event.preventDefault();_vm.getCurrentMode === 'edit' &&
      (_vm.currentAccountTypeId === null || _vm.currentAccountTypeId === 'newAccType')
        ? (_vm.checkAnchor = false)
        : _vm.checkError()}}},[_vm._v(" "+_vm._s(this.getSelectedTab.title === "logs" ? "Rollback" : this.getCurrentMode === "review" ? "Review" : "Save")+" account ")]):_vm._e(),(
      _vm.currentAccountTypeId !== 'newAccType' &&
      !(
        Object.hasOwnProperty(_vm.currentBrokerData, 'deletingMode') &&
        _vm.currentBrokerData.deletingMode
      ) &&
      this.getSelectedTab.title !== 'logs'
    )?_c('button',{staticClass:"delete_btn",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.showDeleteAccModal.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/svg/basket.svg"),"alt":"EarnForex"}}),_vm._v(" Delete Account ")]):_vm._e(),_c('Modal',{attrs:{"title":"Save data?","id":"unsavedData"}},[_c('template',{slot:'modal-content'},[_c('center',[_vm._v("All unsaved data will be lost!")])],1),_c('template',{slot:"modal-controls"},[_c('button',{staticClass:"btn",attrs:{"disabled":_vm.getCurrentMode === 'review' && _vm.toReview(_vm.getAccountType)},on:{"click":function($event){$event.preventDefault();_vm.isUpdateAccType ? (_vm.checkAnchor = false) : _vm.checkError(),
            !_vm.isUpdateAccType ? _vm.switchAccType() : null,
            _vm.closeModal(),
            _vm.getCurrentMode === 'edit'
              ? _vm.$apollo.queries.accountTypes.refetch()
              : null}}},[_vm._v(" Save and exit ")]),_c('button',{staticClass:"btn btn--error",attrs:{"type":"button"},on:{"click":function($event){_vm.switchAccType(),
            _vm.changePage(),
            _vm.closeModal(),
            _vm.isFormChangedDetect(false)}}},[_vm._v(" Exit without saving ")])])],2),_c('Modal',{attrs:{"title":"Delete Account?","id":"deleteAccType"}},[_c('template',{slot:'modal-content'},[_c('center',[_vm._v("Are you sure you want to delete account?")])],1),_c('template',{slot:"modal-controls"},[_c('button',{staticClass:"btn btn--error",attrs:{"disabled":_vm.getCurrentMode === 'review' && _vm.toReview(_vm.getAccountType)},on:{"click":function($event){$event.preventDefault();_vm.removeAccType(), _vm.closeModal()}}},[_vm._v(" Delete ")])])],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }