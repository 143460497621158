<template>
  <div class="form-part">
    <Radio
      :halfWidth="true"
      label="Is hedging allowed?"
      :values="hedgingAllowedOptions"
      :name="'hedgingAllowed'"
      :selected="
        accountTypeData && accountTypeData.hedgingAllowed
          ? accountTypeData.hedgingAllowed
          : hedgingAllowedOptions[0].val
      "
      :changesSelected="
        accountTypeChanges ? accountTypeChanges.hedgingAllowed : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              accountTypeData.hedgingAllowed
                ? accountTypeData.hedgingAllowed
                : 'no',
              accountTypeChanges ? accountTypeChanges.hedgingAllowed : null
            )
          : true
      "
    />
    <Radio
      :halfWidth="true"
      label="Execution method"
      :values="executionMethodOptions"
      name="executionMethod"
      :selected="
        accountTypeData && accountTypeData.executionMethod
          ? accountTypeData.executionMethod
          : executionMethodOptions[0].val
      "
      :changesSelected="
        accountTypeChanges
          ? accountTypeChanges.executionMethod
          : executionMethodOptions[0].val
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              accountTypeData.executionMethod ||
                accountTypeData.executionMethod === null
                ? accountTypeData.executionMethod
                : 'null',
              accountTypeChanges
                ? accountTypeChanges.executionMethod
                : undefined
            )
          : true
      "
    />
    <Select
      :optionalLabel="(option) => option.name"
      :options="yesNoOptions"
      halfWidth
      inline
      label="Interest rate on open positions?"
      optionalValue="val"
      name="interestRate"
      :selected="
        accountTypeData && accountTypeData.interestRate
          ? accountTypeData.interestRate
          : yesNoOptions[0].val
      "
      :changesSelected="
        accountTypeChanges ? accountTypeChanges.interestRate : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              accountTypeData.interestRate
                ? accountTypeData.interestRate.toLowerCase()
                : yesNoOptions[0].val.toLowerCase(),
              accountTypeChanges
                ? accountTypeChanges.interestRate.toLowerCase()
                : null
            )
          : true
      "
    />
    <Select
      :optionalLabel="(option) => option.name"
      :options="digintsAfterDotOptions"
      halfWidth
      inline
      label="How many digits after dot?"
      optionalValue="val"
      name="digitsAfterDot"
      :selected="
        accountTypeData && accountTypeData.digitsAfterDot
          ? accountTypeData.digitsAfterDot
          : digintsAfterDotOptions[0].val
      "
      :changesSelected="
        accountTypeChanges ? accountTypeChanges.digitsAfterDot : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              accountTypeData.digitsAfterDot
                ? accountTypeData.digitsAfterDot.toLowerCase()
                : digintsAfterDotOptions[0].val.toLowerCase(),
              accountTypeChanges ? accountTypeChanges.digitsAfterDot : null
            )
          : true
      "
    />
    <Select
      :optionalLabel="(option) => option.name"
      :options="yesNoOptions"
      halfWidth
      inline
      label="Is it a Cent Account?"
      optionalValue="val"
      name="centAccount"
      :selected="
        accountTypeData && accountTypeData.centAccount
          ? accountTypeData.centAccount
          : yesNoOptions[0].val
      "
      :changesSelected="
        accountTypeChanges ? accountTypeChanges.centAccount : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              accountTypeData.centAccount
                ? accountTypeData.centAccount.toLowerCase()
                : yesNoOptions[0].val.toLowerCase(),
              accountTypeChanges ? accountTypeChanges.centAccount : null
            )
          : true
      "
    />
  </div>
</template>

<script>
import Select from "@/components/formFields/Select";
import CompairingService from "@/services/CompairingService";
import Radio from "@/components/formFields/Radio";
import { mapGetters } from "vuex";

export default {
  name: "AccountSelects",
  components: { Select, Radio },
  props: {
    accountTypeData: Object,
    accountTypeChanges: Object,
  },
  data() {
    return {
      hedgingAllowedOptions: [
        {
          label: "No",
          value: "NO",
        },
        {
          label: "Yes",
          value: "YES",
        },
        {
          label: "Coming Soon",
          value: "COMING_SOON",
        },
      ],
      executionMethodOptions: [
        {
          label: "Unset",
          value: "null",
        },
        {
          label: "Market execution",
          value: "MARKET",
        },
        {
          label: "Instant execution",
          value: "INSTANT",
        },
      ],
      yesNoOptions: [
        {
          name: "No",
          val: "NO",
        },
        {
          name: "Yes",
          val: "YES",
        },
      ],
      digintsAfterDotOptions: [
        {
          name: "4",
          val: "FOUR",
        },
        {
          name: "5",
          val: "FIVE",
        },
        {
          name: "4&5",
          val: "BOTH",
        },
      ],
      CompairingService: new CompairingService(),
    };
  },
  computed: {
    ...mapGetters(["getSelectedTab"]),
  },
};
</script>
