var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-part"},[(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.accountTypeData && _vm.accountTypeData.scalpingAllowed
              ? _vm.accountTypeData.scalpingAllowed.toLowerCase()
              : 'unknown',
            _vm.accountTypeChanges && _vm.accountTypeChanges.scalpingAllowed
              ? _vm.accountTypeChanges.scalpingAllowed.toLowerCase()
              : null
          )
        : true
    )?_c('Select',{attrs:{"label":"Scalping allowed?","options":_vm.permissionsOptions,"optionalLabel":function (option) { return option.name; },"optionalValue":"val","halfWidth":"","selected":_vm.accountTypeData && _vm.accountTypeData.scalpingAllowed
        ? _vm.accountTypeData.scalpingAllowed
        : _vm.permissionsOptions[0].val,"changesSelected":_vm.accountTypeChanges ? _vm.accountTypeChanges.scalpingAllowed : undefined,"name":"scalpingAllowed","checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),(
      (_vm.accountTypeData &&
        _vm.accountTypeData.scalpingAllowed &&
        _vm.accountTypeData.scalpingAllowed.toUpperCase() ===
          'YES_WITH_LIMITS') ||
      (_vm.getAccountType &&
        _vm.getAccountType.scalpingAllowed &&
        _vm.getAccountType.scalpingAllowed.toUpperCase() === 'YES_WITH_LIMITS') ||
      ((_vm.getCurrentMode === 'review' || _vm.getSelectedTab.title === 'logs') &&
        ((_vm.getAccountType &&
          _vm.getAccountType.scalpingAllowed &&
          _vm.getAccountType.scalpingAllowed.toUpperCase() ===
            'YES_WITH_LIMITS') ||
          (_vm.accountTypeChanges &&
            _vm.accountTypeChanges.scalpingAllowed &&
            _vm.accountTypeChanges.scalpingAllowed.toUpperCase() ===
              'YES_WITH_LIMITS')))
    )?_c('LangTabs',{attrs:{"name":"scalpingLimits","languages":_vm.getCurrentMode === 'review' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.compareLangData(
            _vm.accountTypeData &&
              _vm.accountTypeData.scalpingExplanations &&
              _vm.accountTypeData.scalpingExplanations.length
              ? _vm.accountTypeData.scalpingExplanations
              : null,
            _vm.accountTypeChanges && _vm.accountTypeChanges.scalpingExplanations
              ? _vm.accountTypeChanges.scalpingExplanations
              : null
          )
        : !_vm.accountTypeChanges && _vm.getCurrentMode === 'review'
        ? _vm.CompairingService.compareLangData(
            _vm.accountTypeData.scalpingExplanations
          )
        : _vm.languages,"brokerData":_vm.accountTypeData.scalpingExplanations,"changesData":(_vm.getCurrentMode === 'review' || _vm.getSelectedTab.title === 'logs') &&
      _vm.accountTypeChanges &&
      _vm.accountTypeChanges.scalpingExplanations
        ? _vm.accountTypeChanges.scalpingExplanations
        : undefined}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.accountTypeData && _vm.accountTypeData.eaAllowed
              ? _vm.accountTypeData.eaAllowed.toLowerCase()
              : 'unknown',
            _vm.accountTypeChanges && _vm.accountTypeChanges
              ? _vm.accountTypeChanges.eaAllowed.toLowerCase()
              : null
          )
        : true
    )?_c('Select',{attrs:{"label":"EAs allowed?","options":_vm.permissionsOptions,"optionalLabel":function (option) { return option.name; },"optionalValue":"val","halfWidth":"","selected":_vm.accountTypeData && _vm.accountTypeData.eaAllowed
        ? _vm.accountTypeData.eaAllowed
        : _vm.permissionsOptions[0].val,"changesSelected":_vm.accountTypeChanges ? _vm.accountTypeChanges.eaAllowed : undefined,"name":"eaAllowed","checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),(
      (_vm.accountTypeData.eaAllowed &&
        _vm.accountTypeData.eaAllowed.toUpperCase() === 'YES_WITH_LIMITS') ||
      (_vm.getAccountType.eaAllowed &&
        _vm.getAccountType.eaAllowed.toUpperCase() === 'YES_WITH_LIMITS') ||
      ((_vm.getCurrentMode === 'review' || _vm.getSelectedTab.title === 'logs') &&
        ((_vm.getAccountType &&
          _vm.getAccountType.eaAllowed &&
          _vm.getAccountType.eaAllowed.toUpperCase() === 'YES_WITH_LIMITS') ||
          (_vm.accountTypeChanges &&
            _vm.accountTypeChanges.eaAllowed &&
            _vm.accountTypeChanges.eaAllowed.toUpperCase() ===
              'YES_WITH_LIMITS')))
    )?_c('LangTabs',{attrs:{"name":"eaLimits","languages":_vm.getCurrentMode === 'review' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.compareLangData(
            _vm.accountTypeData &&
              _vm.accountTypeData.eaExplanations &&
              _vm.accountTypeData.eaExplanations.length
              ? _vm.accountTypeData.eaExplanations
              : null,
            _vm.accountTypeChanges &&
              _vm.accountTypeChanges.eaExplanations &&
              _vm.accountTypeChanges.eaExplanations.length
              ? _vm.accountTypeChanges.eaExplanations
              : null
          )
        : !_vm.accountTypeChanges && _vm.getCurrentMode === 'review'
        ? _vm.CompairingService.compareLangData(_vm.accountTypeData.eaExplanations)
        : _vm.languages,"brokerData":_vm.accountTypeData.eaExplanations,"changesData":(_vm.getCurrentMode === 'review' || _vm.getSelectedTab.title === 'logs') &&
      _vm.accountTypeChanges &&
      _vm.accountTypeChanges.eaExplanations
        ? _vm.accountTypeChanges.eaExplanations
        : undefined}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }