var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-part account_trading_platform"},[(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.accountTypeData.tradingPlatforms
              ? _vm.accountTypeData.tradingPlatforms
              : [],
            _vm.accountTypeChanges ? _vm.accountTypeChanges.tradingPlatforms : null
          )
        : true
    )?_c('Select',{attrs:{"optionalLabel":function (option) { return option.name; },"options":_vm.tradingPlatforms,"label":"Trading platforms","optionalValue":"id","name":"tradingPlatforms","selected":_vm.accountTypeData ? _vm.accountTypeData.tradingPlatforms : '',"changesSelected":_vm.accountTypeChanges ? _vm.accountTypeChanges.tradingPlatforms : undefined,"multiple":"","required":"","checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.accountTypeData.currencyPairsQuantity
              ? _vm.accountTypeData.currencyPairsQuantity
              : null,
            _vm.accountTypeChanges
              ? _vm.accountTypeChanges.currencyPairsQuantity
              : null
          )
        : true
    )?_c('Input',{attrs:{"label":"How many currency pairs?","column":"","name":"currencyPairsQuantity","type":"number","value":_vm.accountTypeData.currencyPairsQuantity,"changesValue":_vm.accountTypeChanges
        ? _vm.accountTypeChanges.currencyPairsQuantity
        : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.accountTypeData.exoticCurrencyPairsQuantity
              ? _vm.accountTypeData.exoticCurrencyPairsQuantity
              : null,
            _vm.accountTypeChanges
              ? _vm.accountTypeChanges.exoticCurrencyPairsQuantity
              : null
          )
        : true
    )?_c('Input',{attrs:{"label":"Of which exotic:","column":"","name":"exoticCurrencyPairsQuantity","type":"number","value":_vm.accountTypeData.exoticCurrencyPairsQuantity,"changesValue":_vm.accountTypeChanges
        ? _vm.accountTypeChanges.exoticCurrencyPairsQuantity
        : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }