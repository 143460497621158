var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.brokerLog)?_c('div',{class:[
    'rollBack',
    {
      'first-log':
        (_vm.brokerLog.typeLog === 'MAIN_DATA' &&
          !_vm.brokerLog.logData.initialData.anchor) ||
        (_vm.brokerLog.typeLog === 'ACCOUNT_TYPE' &&
          !_vm.brokerLog.logData.initialData.name),
    } ],attrs:{"id":"rollBack"}},[_c('rollback-hint',{attrs:{"type":_vm.brokerLog.typeLog,"dateTime":_vm.brokerLog.updatedAt}}),(_vm.brokerLog.typeLog === 'MAIN_DATA')?_c('BrokerForm',{attrs:{"oldData":_vm.brokerLog.logData.initialData,"newData":_vm.brokerLog.logData.confirmedData,"currentData":_vm.brokerLog.logData.currentData}}):(_vm.brokerLog.typeLog === 'ACCOUNT_TYPE')?_c('AccountTypes',{attrs:{"oldData":_vm.brokerLog.logData.initialData,"newData":_vm.brokerLog.logData.confirmedData,"currentData":_vm.brokerLog.logData.currentData}}):_vm._e()],1):_c('div',[_vm._v("loading...")])}
var staticRenderFns = []

export { render, staticRenderFns }