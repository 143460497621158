var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-part"},[(_vm.showAllowedCounties)?_c('Select',{attrs:{"label":"Allowed countries","options":_vm.options,"halfWidth":true,"optionalLabel":function (option) { return option.name; },"optionalValue":"id","multiple":"","name":'allowedCountries',"selected":_vm.brokerData ? _vm.brokerData.allowedCountries : '',"changesSelected":_vm.brokerChanges ? _vm.brokerChanges.allowedCountries : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null},on:{"approved":_vm.setAllowedCountriesReviewed,"input":_vm.addAllowedCountry}}):_vm._e(),(_vm.showProhibitedCounties)?_c('Select',{attrs:{"label":"Prohibited countries","options":_vm.options,"halfWidth":true,"optionalLabel":function (option) { return option.name; },"optionalValue":"id","multiple":"","name":'prohibitedCountries',"selected":_vm.brokerData ? _vm.brokerData.prohibitedCountries : '',"changesSelected":_vm.brokerChanges ? _vm.brokerChanges.prohibitedCountries : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null},on:{"approved":_vm.setProhibitedCountriesReviewed,"input":_vm.addProhibitedCountry}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.paymentOptions ? _vm.brokerData.paymentOptions : [],
            _vm.brokerChanges && _vm.brokerChanges.paymentOptions
              ? _vm.brokerChanges.paymentOptions
              : []
          )
        : true
    )?_c('Select',{attrs:{"label":"Payment options","options":_vm.paymentOptions,"halfWidth":true,"optionalLabel":function (option) { return option.name; },"optionalValue":"id","multiple":"","name":'paymentOptions',"selected":_vm.brokerData ? _vm.brokerData.paymentOptions : '',"changesSelected":_vm.brokerChanges ? _vm.brokerChanges.paymentOptions : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.accountCurrencies ? _vm.brokerData.accountCurrencies : [],
            _vm.brokerChanges && _vm.brokerChanges.accountCurrencies
              ? _vm.brokerChanges.accountCurrencies
              : []
          )
        : true
    )?_c('Select',{attrs:{"label":"Account currencies","options":_vm.accountCurrencies,"halfWidth":true,"optionalLabel":function (option) { return option.name; },"name":'accountCurrencies',"multiple":"","optionalValue":"id","selected":_vm.brokerData ? _vm.brokerData.accountCurrencies : '',"changesSelected":_vm.brokerChanges ? _vm.brokerChanges.accountCurrencies : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.currencyPairs,
            _vm.brokerChanges ? _vm.brokerChanges.currencyPairs : null
          )
        : true
    )?_c('Textarea',{attrs:{"label":"Currency pairs in XXX/YYY format","halfWidth":"","name":'currencyPairs',"value":_vm.brokerData ? _vm.brokerData.currencyPairs : '',"changesValue":_vm.brokerChanges ? _vm.brokerChanges.currencyPairs : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),_vm._v(" "),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.acceptsUsClients,
            _vm.brokerChanges ? _vm.brokerChanges.acceptsUsClients : null
          )
        : true
    )?_c('Radio',{attrs:{"halfWidth":true,"label":"Accepts US clients","values":_vm.radioSelections,"column":"","name":"acceptsUsClients","selected":_vm.brokerData ? _vm.brokerData.acceptsUsClients : '',"changesSelected":_vm.brokerChanges ? _vm.brokerChanges.acceptsUsClients : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }