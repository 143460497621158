<template>
  <div class="modal" :id="id">
    <div class="modal-container">
      <slot>
        <button
          type="button"
          class="modal-close"
          @click.prevent="closeModal"
        ></button>
      </slot>

      <h6 class="modal-title" v-if="title">
        {{ title }}
      </h6>
      <div class="modal-content">
        <slot name="modal-content"></slot>
      </div>
      <div class="modal-controls">
        <slot name="modal-controls"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "modal",
  props: {
    title: String,
    id: String,
  },

  methods: {
    ...mapMutations(["isFormChangedDetect", "setLinkToGo"]),
    closeModal() {
      if (this.id === "unsavedData") {
        this.isFormChangedDetect(true);
        this.setLinkToGo(null);
      }
      event.target.closest(".modal--open").classList.remove("modal--open");
    },
  },
};
</script>
