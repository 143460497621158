<template>
  <div id="log" class="log">
    <table class="log__table">
      <thead>
        <tr>
          <td v-for="(item, index) in tableHeaders" :key="index">
            {{ item }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, index) in brokerLogs" :key="index">
          <td>
            <time>
              {{ data.updatedAt.slice(0, 10) }}
              <span>
                {{ data.updatedAt.slice(11, 19) }}
              </span>
            </time>
          </td>
          <td>
            {{ data.userType }}
          </td>
          <td>{{ data.typeLog === "MAIN_DATA" ? "MD" : "AT" }}</td>
          <td>
            <a>
              {{ data.accountTypeName ? data.accountTypeName + "@ " : "" }}
            </a>
            {{ data.changes }}
            <button
              @click.prevent="
                openModalToRestore(data.accountTypeName, data.accountTypeId)
              "
              class="btn"
              v-if="data.accountTypeDeletedStatus"
            >
              Restore
            </button>
            <button
              class="btn"
              @click.prevent="
                setSelectedTab({
                  title: 'logs',
                  innerTab: 'broker rollback',
                  id: data.id,
                  type: data.typeLog,
                })
              "
              v-else-if="!data.deletingMode"
            >
              View changes
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <Modal id="restoreAccount">
      <template :slot="'modal-content'">
        <center>Restore the account "{{ accountNameToRestore }}"</center>
      </template>
      <template slot="modal-controls">
        <button class="btn" @click.prevent="closeModal(true)">Restore</button>
        <button type="button" @click="closeModal(false)" class="btn btn--error">
          Leave
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { brokerLogs } from "@/components/graphQL/logs.gql";
import Modal from "@/components/Modal";
import { restoreAccountType } from "@/components/graphQL/brokerMutations";

export default {
  name: "Log",
  components: { Modal },
  data() {
    return {
      tableHeaders: ["Data", "User", "Main Data / Account types", "Fields"],
      accountNameToRestore: null,
      accountIdToRestore: null,
    };
  },
  methods: {
    ...mapMutations(["setSelectedTab"]),
    openModalToRestore(accountTypeName, accountTypeId) {
      this.accountNameToRestore = accountTypeName;
      this.accountIdToRestore = accountTypeId;
      document.querySelector("#restoreAccount").classList.add("modal--open");
    },
    closeModal(restoreStatus) {
      if (restoreStatus) {
        this.$apollo
          .mutate({
            mutation: restoreAccountType,

            variables: {
              accountTypeId: this.accountIdToRestore,
            },
          })
          .then(() => {
            this.accountIdToRestore = this.accountNameToRestore = null;
            document
              .querySelector("#restoreAccount")
              .classList.remove("modal--open");
            this.$apollo.queries.brokerLogs.refresh();
          });
      } else {
        document
          .querySelector("#restoreAccount")
          .classList.remove("modal--open");
      }
    },
  },
  apollo: {
    brokerLogs: {
      query: brokerLogs,
      variables() {
        return {
          brokerId: this.$route.params.id,
        };
      },
      skip() {
        return localStorage.getItem("Mode") !== "manager";
      },
    },
  },
};
</script>
