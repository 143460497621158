var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.checkboxMain && _vm.checkbox)?_c('fieldset',{class:[
    'checkbox',
    { 'checkbox--checked': _vm.checkboxState },
    { 'checkbox--instrument': _vm.inputType === 'number' } ],attrs:{"name":"checkboxItem","data-id":_vm.id,"data-licenseId":_vm.checkboxMain['licenseId']
      ? _vm.checkboxMain['licenseId']
      : _vm.checkboxMain['instrumentId']
      ? _vm.checkboxMain['instrumentId']
      : _vm.checkboxMain.id}},[_c('label',{staticClass:"checkbox__wrap"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkboxState),expression:"checkboxState"}],attrs:{"type":"checkbox","name":_vm.$route.name !== 'brokerReview'
          ? _vm.checkboxMain['name']
          : _vm.checkboxMain['licenseName'] || _vm.checkboxMain['instrumentName']},domProps:{"value":_vm.checkboxMain['id'],"checked":Array.isArray(_vm.checkboxState)?_vm._i(_vm.checkboxState,_vm.checkboxMain['id'])>-1:(_vm.checkboxState)},on:{"input":function($event){return _vm.isFormChangedDetect(true)},"change":function($event){var $$a=_vm.checkboxState,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.checkboxMain['id'],$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkboxState=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkboxState=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkboxState=$$c}}}}),_c('span',{staticClass:"checkbox__label"},[_vm._v(" "+_vm._s(_vm.checkboxMain["name"] || _vm.checkboxMain["licenseName"] || _vm.checkboxMain["instrumentName"])+" ")])]),(_vm.checkboxState && _vm.inputType === 'number')?_c('Radio',{attrs:{"column":"","label":"Leverage kind","values":[
      {
        value: "FIXED",
        label: 'Fixed',
      },
      {
        value: "VARIABLE",
        label: 'Variable',
      } ],"name":((_vm.internalCheckbox['name'] ||
      _vm.internalCheckbox['licenseName'] ||
      _vm.internalCheckbox['instrumentName']) + "-kindLeverage"),"checker":null,"selected":_vm.checkbox.kindLeverage,"changesSelected":_vm.internalChangedValue ? _vm.internalChangedValue.kindLeverage : undefined},on:{"input":function($event){return _vm.changedCheckboxData()}},model:{value:(_vm.instrument.kindLeverage),callback:function ($$v) {_vm.$set(_vm.instrument, "kindLeverage", $$v)},expression:"instrument.kindLeverage"}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkboxState && _vm.showSimpleInput),expression:"checkboxState && showSimpleInput"}],staticClass:"checkbox__input_container"},[(_vm.inputType === 'number')?_c('strong',{staticClass:"input__label"},[_vm._v(" Max Leverage (w/o \"1:\")* ")]):_vm._e(),((_vm.inputType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"checkbox__input",attrs:{"placeholder":_vm.inputType === 'number' ? '' : 'license number',"name":_vm.inputType === 'number' ? 'maxLeverage' : _vm.internalCheckbox['name'],"data-id":_vm.internalCheckbox['id'],"data-licenseId":_vm.internalCheckbox['licenseId']
          ? _vm.internalCheckbox['licenseId']
          : _vm.internalCheckbox['instrumentId']
          ? _vm.internalCheckbox['instrumentId']
          : null,"data-type":"currentValue","data-deleted":_vm.changedValue === null,"required":((_vm.$route.name !== 'brokerReview' &&
          _vm.getSelectedTab.title !== 'logs') ||
          (_vm.$route.name === 'brokerEdit' &&
            _vm.getSelectedTab.title !== 'logs')) &&
        _vm.showSimpleInput,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputValue)?_vm._i(_vm.inputValue,null)>-1:(_vm.inputValue)},on:{"blur":function($event){_vm.changedCheckboxData(), _vm.isFormChangedDetect(true)},"change":function($event){var $$a=_vm.inputValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputValue=$$c}}}}):((_vm.inputType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"checkbox__input",attrs:{"placeholder":_vm.inputType === 'number' ? '' : 'license number',"name":_vm.inputType === 'number' ? 'maxLeverage' : _vm.internalCheckbox['name'],"data-id":_vm.internalCheckbox['id'],"data-licenseId":_vm.internalCheckbox['licenseId']
          ? _vm.internalCheckbox['licenseId']
          : _vm.internalCheckbox['instrumentId']
          ? _vm.internalCheckbox['instrumentId']
          : null,"data-type":"currentValue","data-deleted":_vm.changedValue === null,"required":((_vm.$route.name !== 'brokerReview' &&
          _vm.getSelectedTab.title !== 'logs') ||
          (_vm.$route.name === 'brokerEdit' &&
            _vm.getSelectedTab.title !== 'logs')) &&
        _vm.showSimpleInput,"type":"radio"},domProps:{"checked":_vm._q(_vm.inputValue,null)},on:{"blur":function($event){_vm.changedCheckboxData(), _vm.isFormChangedDetect(true)},"change":function($event){_vm.inputValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"checkbox__input",attrs:{"placeholder":_vm.inputType === 'number' ? '' : 'license number',"name":_vm.inputType === 'number' ? 'maxLeverage' : _vm.internalCheckbox['name'],"data-id":_vm.internalCheckbox['id'],"data-licenseId":_vm.internalCheckbox['licenseId']
          ? _vm.internalCheckbox['licenseId']
          : _vm.internalCheckbox['instrumentId']
          ? _vm.internalCheckbox['instrumentId']
          : null,"data-type":"currentValue","data-deleted":_vm.changedValue === null,"required":((_vm.$route.name !== 'brokerReview' &&
          _vm.getSelectedTab.title !== 'logs') ||
          (_vm.$route.name === 'brokerEdit' &&
            _vm.getSelectedTab.title !== 'logs')) &&
        _vm.showSimpleInput,"type":_vm.inputType},domProps:{"value":(_vm.inputValue)},on:{"blur":function($event){_vm.changedCheckboxData(), _vm.isFormChangedDetect(true)},"input":function($event){if($event.target.composing){ return; }_vm.inputValue=$event.target.value}}}),((_vm.inputType === 'number' ? 'number' : 'text')==='checkbox'&&(
        (_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs') &&
        this.internalChangedValue
      ))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputChangedValue),expression:"inputChangedValue"}],staticClass:"checkbox__input",attrs:{"placeholder":_vm.inputType === 'number' ? 'Max' : 'license number',"name":_vm.inputType === 'number'
          ? 'maxLeverage'
          : _vm.internalCheckbox['name'] || _vm.internalChangedValue['name'],"data-id":_vm.internalCheckbox['id'],"data-licenseId":_vm.internalCheckbox['licenseId']
          ? _vm.internalCheckbox['licenseId']
          : _vm.internalCheckbox['instrumentId']
          ? _vm.internalCheckbox['instrumentId']
          : null,"data-type":"changesValue","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputChangedValue)?_vm._i(_vm.inputChangedValue,null)>-1:(_vm.inputChangedValue)},on:{"blur":function($event){_vm.changedCheckboxData(), _vm.isFormChangedDetect(true)},"change":function($event){var $$a=_vm.inputChangedValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputChangedValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputChangedValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputChangedValue=$$c}}}}):((_vm.inputType === 'number' ? 'number' : 'text')==='radio'&&(
        (_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs') &&
        this.internalChangedValue
      ))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputChangedValue),expression:"inputChangedValue"}],staticClass:"checkbox__input",attrs:{"placeholder":_vm.inputType === 'number' ? 'Max' : 'license number',"name":_vm.inputType === 'number'
          ? 'maxLeverage'
          : _vm.internalCheckbox['name'] || _vm.internalChangedValue['name'],"data-id":_vm.internalCheckbox['id'],"data-licenseId":_vm.internalCheckbox['licenseId']
          ? _vm.internalCheckbox['licenseId']
          : _vm.internalCheckbox['instrumentId']
          ? _vm.internalCheckbox['instrumentId']
          : null,"data-type":"changesValue","type":"radio"},domProps:{"checked":_vm._q(_vm.inputChangedValue,null)},on:{"blur":function($event){_vm.changedCheckboxData(), _vm.isFormChangedDetect(true)},"change":function($event){_vm.inputChangedValue=null}}}):(
        (_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs') &&
        this.internalChangedValue
      )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputChangedValue),expression:"inputChangedValue"}],staticClass:"checkbox__input",attrs:{"placeholder":_vm.inputType === 'number' ? 'Max' : 'license number',"name":_vm.inputType === 'number'
          ? 'maxLeverage'
          : _vm.internalCheckbox['name'] || _vm.internalChangedValue['name'],"data-id":_vm.internalCheckbox['id'],"data-licenseId":_vm.internalCheckbox['licenseId']
          ? _vm.internalCheckbox['licenseId']
          : _vm.internalCheckbox['instrumentId']
          ? _vm.internalCheckbox['instrumentId']
          : null,"data-type":"changesValue","type":_vm.inputType === 'number' ? 'number' : 'text'},domProps:{"value":(_vm.inputChangedValue)},on:{"blur":function($event){_vm.changedCheckboxData(), _vm.isFormChangedDetect(true)},"input":function($event){if($event.target.composing){ return; }_vm.inputChangedValue=$event.target.value}}}):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkboxState && !_vm.showSimpleInput),expression:"checkboxState && !showSimpleInput"}],staticClass:"variable_leverage"},[_c('div',{staticClass:"variable_leverage__position_wrapper"},_vm._l((_vm.instrument.accountTypeInstrumentPairs),function(position){return _c('div',{key:position.id,staticClass:"variable_leverage__position"},[_c('div',{staticClass:"double_inputs__wrapper"},[_c('strong',{staticClass:"input__label"},[_vm._v(" Position* ")]),_c('div',{staticClass:"double_inputs"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(position.minPosition),expression:"position.minPosition"}],staticClass:"checkbox__input",attrs:{"type":"number","placeholder":"Min","name":"minPosition","data-type":"currentValue","required":((_vm.$route.name !== 'brokerReview' &&
                  _vm.getSelectedTab.title !== 'logs') ||
                  (_vm.$route.name === 'brokerEdit' &&
                    _vm.getSelectedTab.title !== 'logs')) &&
                !_vm.showSimpleInput},domProps:{"value":(position.minPosition)},on:{"blur":function($event){_vm.changedCheckboxData(), _vm.isFormChangedDetect(true)},"input":function($event){if($event.target.composing){ return; }_vm.$set(position, "minPosition", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(position.maxPosition),expression:"position.maxPosition"}],staticClass:"checkbox__input",attrs:{"type":"number","placeholder":"Max","name":"maxPosition","data-type":"currentValue","required":((_vm.$route.name !== 'brokerReview' &&
                  _vm.getSelectedTab.title !== 'logs') ||
                  (_vm.$route.name === 'brokerEdit' &&
                    _vm.getSelectedTab.title !== 'logs')) &&
                _vm.$route.name !== 'brokerEdit' &&
                !_vm.showSimpleInput},domProps:{"value":(position.maxPosition)},on:{"blur":function($event){_vm.changedCheckboxData(), _vm.isFormChangedDetect(true)},"input":function($event){if($event.target.composing){ return; }_vm.$set(position, "maxPosition", $event.target.value)}}})]),(
              (_vm.$route.name === 'brokerReview' ||
                _vm.getSelectedTab.title === 'logs') &&
              _vm.internalChangedValue
            )?_c('div',{staticClass:"double_inputs"},[_c('input',{staticClass:"checkbox__input",attrs:{"placeholder":"Min","name":"minPosition","data-type":"changedValue","required":((_vm.$route.name !== 'brokerReview' &&
                  _vm.getSelectedTab.title !== 'logs') ||
                  (_vm.$route.name === 'brokerEdit' &&
                    _vm.getSelectedTab.title !== 'logs')) &&
                !_vm.showSimpleInput},domProps:{"value":_vm.findMinPosition(position.id)},on:{"blur":function($event){_vm.changedCheckboxData(), _vm.isFormChangedDetect(true)}}}),_c('input',{staticClass:"checkbox__input",attrs:{"placeholder":"Max","name":"maxPosition","data-type":"changedValue","required":((_vm.$route.name !== 'brokerReview' &&
                  _vm.getSelectedTab.title !== 'logs') ||
                  (_vm.$route.name === 'brokerEdit' &&
                    _vm.getSelectedTab.title !== 'logs')) &&
                !_vm.showSimpleInput},domProps:{"value":_vm.findMaxPosition(position.id)},on:{"blur":function($event){_vm.changedCheckboxData(), _vm.isFormChangedDetect(true)}}})]):_vm._e()]),(_vm.checkboxState && !_vm.showSimpleInput)?_c('Radio',{attrs:{"column":"","label":"Volume Type","values":[
            {
              value: "CURRENCY",
              label: 'Currency',
            },
            {
              value: "LOT",
              label: 'Lot',
            } ],"name":((_vm.internalCheckbox['name'] ||
            _vm.internalCheckbox['licenseName'] ||
            _vm.internalCheckbox['instrumentName']) + "-typeLeverage-" + (position.id)),"checker":null,"selected":(_vm.altTypeLeverageModel
              ? position.typeLeverage
              : _vm.internalCheckbox.typeLeverage) || 'CURRENCY',"changesSelected":_vm.findPositionTypeLeverage(position.id)},on:{"input":function($event){return _vm.changedCheckboxData()}},model:{value:(position.typeLeverage),callback:function ($$v) {_vm.$set(position, "typeLeverage", $$v)},expression:"position.typeLeverage"}}):_vm._e(),_c('div',{staticClass:"checkbox__input_container"},[(_vm.inputType === 'number')?_c('strong',{staticClass:"input__label"},[_vm._v(" Leverage ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(position.leverage),expression:"position.leverage"}],staticClass:"checkbox__input",attrs:{"placeholder":"(w/o \"1:\")*","name":_vm.inputType === 'number'
                ? 'maxLeverage'
                : _vm.internalCheckbox['name'],"data-id":_vm.internalCheckbox['id'],"data-licenseId":_vm.internalCheckbox['licenseId']
                ? _vm.internalCheckbox['licenseId']
                : _vm.internalCheckbox['instrumentId']
                ? _vm.internalCheckbox['instrumentId']
                : null,"data-type":"currentValue","required":_vm.inputValue === '' &&
              ((_vm.$route.name !== 'brokerReview' &&
                _vm.getSelectedTab.title !== 'logs') ||
                (_vm.$route.name === 'brokerEdit' &&
                  _vm.getSelectedTab.title !== 'logs')),"type":"number"},domProps:{"value":(position.leverage)},on:{"blur":function($event){_vm.changedCheckboxData(), _vm.isFormChangedDetect(true)},"input":function($event){if($event.target.composing){ return; }_vm.$set(position, "leverage", $event.target.value)}}}),(
              _vm.$route.name === 'brokerReview' ||
              _vm.getSelectedTab.title === 'logs'
            )?_c('input',{staticClass:"checkbox__input",attrs:{"placeholder":"(w/o \"1:\")*","type":"number","name":_vm.inputType === 'number' ? 'maxLeverage' : _vm.checkbox['name'],"data-id":_vm.internalCheckbox['id'],"data-licenseId":_vm.internalCheckbox['licenseId']
                ? _vm.internalCheckbox['licenseId']
                : _vm.internalCheckbox['instrumentId']
                ? _vm.internalCheckbox['instrumentId']
                : null,"data-type":"changesValue"},domProps:{"value":_vm.findPositionLeverage(position.id)},on:{"blur":function($event){_vm.changedCheckboxData(), _vm.isFormChangedDetect(true)}}}):_vm._e()]),_c('button',{staticClass:"history_item__remove",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.removePosition(position)}}})],1)}),0),(
        _vm.checkboxState &&
        !_vm.showSimpleInput &&
        _vm.instrument.accountTypeInstrumentPairs.length < 10 &&
        _vm.getCurrentMode !== 'reviews' &&
        _vm.getSelectedTab.title !== 'logs'
      )?_c('button',{staticClass:"add_history_item",attrs:{"type":"button"},on:{"click":function($event){return _vm.addNewPosition()}}},[_c('p',{staticClass:"img-alt"},[_vm._v("+")]),_c('span',[_vm._v("Add position")])]):_vm._e()]),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null
    )?_c('Checker',{on:{"set-approve":_vm.setApprove}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }