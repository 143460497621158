<template>
  <div
    id="rollBack"
    :class="[
      'rollBack',
      {
        'first-log':
          (brokerLog.typeLog === 'MAIN_DATA' &&
            !brokerLog.logData.initialData.anchor) ||
          (brokerLog.typeLog === 'ACCOUNT_TYPE' &&
            !brokerLog.logData.initialData.name),
      },
    ]"
    v-if="brokerLog"
  >
    <rollback-hint :type="brokerLog.typeLog" :dateTime="brokerLog.updatedAt" />
    <BrokerForm
      v-if="brokerLog.typeLog === 'MAIN_DATA'"
      :oldData="brokerLog.logData.initialData"
      :newData="brokerLog.logData.confirmedData"
      :currentData="brokerLog.logData.currentData"
    />
    <AccountTypes
      v-else-if="brokerLog.typeLog === 'ACCOUNT_TYPE'"
      :oldData="brokerLog.logData.initialData"
      :newData="brokerLog.logData.confirmedData"
      :currentData="brokerLog.logData.currentData"
    />
  </div>
  <div v-else>loading...</div>
</template>

<script>
import RollbackHint from "@/components/RollbackHint";
import BrokerForm from "@/components/tabs/BrokerForm";
import AccountTypes from "@/components/tabs/AccountTypes";
import { mapGetters } from "vuex";
import { brokerLog } from "@/components/graphQL/logs.gql";
export default {
  name: "Log",
  components: { RollbackHint, BrokerForm, AccountTypes },
  apollo: {
    brokerLog: {
      query: brokerLog,
      variables() {
        return {
          brokerLogId: this.getSelectedTab.id,
        };
      },
      skip() {
        return localStorage.getItem("Mode") !== "manager";
      },
    },
  },
  data() {
    return {
      rollback: {
        broker: false,
        accType: false,
      },
    };
  },
  computed: {
    ...mapGetters(["getSelectedTab"]),
  },
};
</script>
