var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('LangTabs',{attrs:{"name":"specialConditions","languages":_vm.getCurrentMode === 'review' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.compareLangData(
            _vm.accountTypeData && _vm.accountTypeData.length
              ? _vm.accountTypeData
              : null,
            _vm.accountTypeChanges && _vm.accountTypeChanges.length
              ? _vm.accountTypeChanges
              : null
          )
        : _vm.languages,"brokerData":_vm.accountTypeData,"changesData":(_vm.getCurrentMode === 'review' || _vm.getSelectedTab.title === 'logs') &&
      _vm.accountTypeChanges &&
      _vm.accountTypeChanges.length
        ? _vm.accountTypeChanges
        : undefined}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }