class BrokerCompany {
  constructor(id) {
    this.id = id;
    this.name = "";
    this.ticker = "";
    this.linkTicker = "";
  }
}

export default BrokerCompany;
