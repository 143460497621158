<template>
  <div class="site-preview">
    <div
      class="site-preview__label"
      v-if="$route.name !== 'brokerReview' && getSelectedTab.title !== 'logs'"
    >
      Broker website screenshot
    </div>
    <div
      class="site-preview__content"
      v-if="$route.name !== 'brokerReview' && getSelectedTab.title !== 'logs'"
    >
      <div class="site-preview__controls">
        <div class="site-preview__btns">
          <button
            @click="takeAScreenshot"
            :class="['btn', { '--disabled': loading }]"
          >
            Take a screenshot
          </button>
          <span class="site-preview__btn-divider">or</span>
          <form
            @submit.prevent="uploadScreenshot"
            method="POST"
            enctype="multipart/form-data"
          >
            <label :class="['btn', { '--disabled': loading }]">
              <input
                accept="image/png, image/jpeg, image/jpg"
                type="file"
                name="file"
                @change="uploadScreenshot"
              />
              <span>Upload screenshot</span>
            </label>
          </form>
        </div>
        <div v-if="fileName" class="site-preview__created">
          File name: {{ fileName }}
        </div>
        <div v-if="created" class="site-preview__created">{{ created }}</div>
        <div v-if="errorMsg" class="site-preview__error">{{ errorMsg }}</div>
        <div v-if="loading" class="site-preview__loader">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div v-if="imageUrl" class="site-preview__image">
        <img @click="showBrokerPreview" :src="imageUrl" alt="" />
        <button
          @click="removeScreenshot"
          class="site-preview__remove-image"
        ></button>
      </div>
    </div>

    <Radio
      label="Not change screenshot on update"
      :values="[
        { label: 'No', value: 'false' },
        { label: 'Yes', value: 'true' },
      ]"
      name="noCreateScreenshot"
      :selected="
        noCreateScreenshot !== undefined
          ? noCreateScreenshot.toString()
          : 'false'
      "
      :changesSelected="
        changedNoCreateScreenshot !== undefined
          ? changedNoCreateScreenshot
          : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              noCreateScreenshot,
              changedNoCreateScreenshot
            )
          : true
      "
    />
    <transition name="fade-modal">
      <div v-if="modalVisibility" class="site-preview__modal">
        <div @click="hideBrokerPreview" class="site-preview__modal-hider"></div>
        <div class="site-preview__modal-content">
          <span @click="hideBrokerPreview" class="site-preview__modal-close"
            >&#215;</span
          >
          <img :src="imageUrl" alt="" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  brokerScreenshot,
  uploadScreenshot,
  deleteScreenshot,
} from "@/components/graphQL/brokerMutations";
import Radio from "@/components/formFields/Radio.vue";
import { mapGetters } from "vuex";
import CompairingService from "@/services/CompairingService";

export default {
  name: "SitePreview",
  components: { Radio },
  props: {
    anchor: String,
    screenshotCreated: String,
    screenshotUrl: String,
    noCreateScreenshot: Boolean,
    changedNoCreateScreenshot: Boolean,
  },
  created() {
    if (this.screenshotCreated)
      this.created = `Updated: ${this.parseDate(this.screenshotCreated)}`;
    if (this.screenshotUrl) this.imageUrl = this.screenshotUrl;
  },
  computed: {
    ...mapGetters(["getSelectedTab"]),
  },
  data() {
    return {
      loading: false,
      errorMsg: null,
      created: null,
      fileName: null,
      imageUrl: null,
      modalVisibility: false,
      CompairingService: new CompairingService(),
    };
  },
  methods: {
    async takeAScreenshot() {
      this.created = null;
      this.fileName = null;
      this.errorMsg = null;
      this.imageUrl = null;
      this.loading = true;

      try {
        const { data } = await this.$apollo.query({
          query: brokerScreenshot,
          errorPolicy: "all",
          fetchPolicy: "no-cache",
          variables: {
            anchor: this.anchor,
          },
        });

        if (data && data.brokerScreenshot.text_result === "successfully") {
          this.imageUrl = data.brokerScreenshot.url;
          this.created = `Updated: ${this.parseDate()}`;
        } else {
          this.errorMsg = `Error: ${data.brokerScreenshot.text_result}!`;
        }
      } catch (e) {
        this.errorMsg = `Server error: ${this.parseDate()}`;
        console.log(e);
      }
      this.loading = false;
    },

    uploadScreenshot(event) {
      const fileData = event.target.files[0];
      if (!fileData) return false;

      this.errorMsg = null;
      this.created = null;
      this.fileName = null;
      this.imageUrl = null;
      this.loading = true;

      try {
        this.$apollo
          .mutate({
            mutation: uploadScreenshot,
            variables: {
              file: fileData,
              broker_id: this.$route.params.id,
            },
            context: {
              hasUpload: true,
            },
          })
          .then(({ data }) => {
            if (data.uploadScreenshot.text_result === "successfully") {
              this.imageUrl = data.uploadScreenshot.url;
              this.fileName = fileData ? fileData.name : null;
              this.created = `Updated: ${this.parseDate()}`;
            } else {
              this.errorMsg = `Error: ${data.uploadScreenshot.text_result}!`;
            }
            this.loading = false;
          })
          .catch((error) => {
            console.error(error);
            this.loading = false;
            this.errorMsg = "Something went wrong while uploading the file!";
          });
      } catch (e) {
        this.loading = false;
        this.errorMsg = `Server error: ${this.parseDate()}`;
        console.log(e);
      }
    },

    async removeScreenshot() {
      this.loading = true;

      try {
        this.$apollo
          .mutate({
            mutation: deleteScreenshot,
            variables: {
              broker_id: this.$route.params.id,
            },
          })
          .then(({ data }) => {
            if (data.deleteScreenshot.text_result === "successfully") {
              this.imageUrl = null;
              this.fileName = null;
              this.errorMsg = null;
              this.created = "Screenshot deleted successfully!";
            } else {
              this.errorMsg = `Error: ${data.deleteScreenshot.text_result}!`;
            }
            this.loading = false;
          })
          .catch((error) => {
            console.error(error);
            this.loading = false;
            this.errorMsg = "Something went wrong while deleting the file!";
          });
      } catch (e) {
        this.loading = false;
        this.errorMsg = `Server error: ${new Date().toLocaleString()}`;
        console.log(e);
      }
    },

    showBrokerPreview() {
      this.modalVisibility = true;
      document.body.style = "overflow: hidden;";
    },

    hideBrokerPreview() {
      this.modalVisibility = false;
      document.body.style = "overflow: visible;";
    },

    parseDate(dateVal) {
      let date = null;
      if (dateVal) {
        date = new Date(dateVal);
      } else {
        date = new Date();
      }
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })}`;
    },
  },
};
</script>

<style lang="scss">
.site-preview {
  margin-bottom: 35px;
  display: flex;
  flex-wrap: wrap;

  &__label {
    width: 155px;
    padding-right: 15px;
  }

  &__content {
    width: calc(100% - 155px);
    display: flex;
    flex-wrap: wrap;
  }

  &__controls {
    width: 330px;
    padding-right: 20px;

    .btn {
      margin: 0;
    }
  }

  &__btns {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    input[type="file"] {
      display: none;
    }
  }

  &__created {
    margin: 10px 0;
    color: #717171;
    font-size: 13px;
  }

  &__error {
    margin: 10px 0;
    color: #ff2828;
    font-size: 13px;
  }

  &__image {
    width: 130px;
    position: relative;
    font-size: 0;
    line-height: 0;
    max-height: 100px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center top;
    }
  }

  &__remove-image {
    width: 20px;
    height: 20px;
    background-image: url(../../assets/svg/ico-close-black.svg);
    position: absolute;
    right: -30px;
    cursor: pointer;
    top: 0;
    outline: 0;
    border: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 11px;
    background-color: transparent;
  }

  &__loader {
    margin: 10px 0;

    span {
      display: inline-block;
      height: 15px;
      width: 15px;
      background-color: #c7c7c7;

      &:nth-child(1) {
        animation: rotateX 2s 0.1s infinite
          cubic-bezier(0.65, 0.03, 0.735, 0.045);
      }

      &:nth-child(2) {
        animation: rotateX 2s 0.2s infinite
          cubic-bezier(0.65, 0.03, 0.735, 0.045);
      }

      &:nth-child(3) {
        animation: rotateX 2s 0.3s infinite
          cubic-bezier(0.65, 0.03, 0.735, 0.045);
      }

      &:nth-child(4) {
        animation: rotateX 2s 0.4s infinite
          cubic-bezier(0.65, 0.03, 0.735, 0.045);
      }

      &:nth-child(5) {
        animation: rotateX 2s 0.5s infinite
          cubic-bezier(0.65, 0.03, 0.735, 0.045);
      }
    }
  }

  &__modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;

    &-hider {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.45);
      cursor: pointer;
    }

    &-content {
      position: relative;
      z-index: 2;
      font-size: 0;
      line-height: 0;

      img {
        object-fit: cover;
        max-width: 95vw;
        max-height: 90vh;
        position: relative;
        z-index: 1;
      }
    }

    &-close {
      width: 25px;
      height: 25px;
      font-size: 32px;
      line-height: 27px;
      text-align: center;
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      color: white;
      cursor: pointer;
      z-index: 2;
      background-color: #b4b4b4;
      transition: background-color 0.3s ease-in-out;

      &:hover {
        background-color: #6f6f6f;
      }
    }
  }

  .radio {
    margin: 20px 0;
    max-width: 455px;

    &__label {
      max-width: 155px;
      padding-right: 30px;
    }
  }
}

.fade-modal {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }
}

@keyframes rotateX {
  0% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(90deg) scale(0.5, 0.5);
    background: var(--accent);
  }
  100% {
    transform: rotateX(0deg);
  }
}
</style>
