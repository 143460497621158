<template>
  <label class="textarea" :class="halfWidth ? 'width-50' : ''">
    <span
      class="textarea__main"
      :class="inline ? 'textarea__main--inline' : ''"
    >
      <span v-if="label" class="textarea__label">
        {{ label }}
      </span>
      <span class="relations__desc" v-if="desc">{{ desc }}</span>
      <div class="textarea__container">
        <textarea
          :name="name"
          :placeholder="placeholder"
          v-model="input"
          class="textarea__field"
          data-type="currentValue"
          :class="
            $route.name === 'brokerReview' && !input
              ? 'textarea__field--small'
              : ''
          "
          @input="isFormChangedDetect(true)"
        ></textarea>
        <textarea
          :name="name"
          :placeholder="placeholder"
          v-model="changesInput"
          class="textarea__field"
          :class="
            $route.name === 'brokerReview' && !changesInput
              ? 'textarea__field--small'
              : ''
          "
          v-if="changesValue !== undefined"
          data-type="changesValue"
        ></textarea>
      </div>
    </span>
    <Checker v-if="checker" />
  </label>
</template>

<script>
import Checker from "@/components/formFields/Checker.vue";
import { mapMutations } from "vuex";

export default {
  components: { Checker },
  name: "Textarea",
  props: {
    label: String,
    desc: String,
    placeholder: String,
    value: String,
    changesValue: String,
    checker: {
      type: String,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    halfWidth: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      approved: "",
      input: "",
      changesInput: "",
    };
  },
  created() {
    if (this.value) {
      this.input = this.value;
    }
    if (this.changesValue !== undefined) {
      this.changesInput = this.changesValue;
    }
  },
  methods: {
    ...mapMutations(["isFormChangedDetect"]),
  },
  watch: {
    value: function () {
      this.input = this.value;
    },
  },
};
</script>
