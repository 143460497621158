var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.$apollo.loading && _vm.currentBrokerData && _vm.showForm)?_c('form',{ref:"mainData",staticClass:"main_data",attrs:{"id":"main_data","name":"main_data"},on:{"submit":function($event){$event.preventDefault();}}},[(
      this.getSelectedTab.title !== 'logs' ||
      (this.getSelectedTab.title === 'logs' && this.oldData.anchor)
    )?_c('button',{staticClass:"btn save_broker",attrs:{"type":"button","disabled":(_vm.getCurrentMode === 'review' && _vm.toReview(_vm.getBrokerData)) ||
      (_vm.getCurrentMode !== 'review' && !_vm.getIsChanged)},on:{"keydown":function($event){$event.preventDefault();},"click":function($event){$event.preventDefault();_vm.needToCheckAnchor ? (_vm.checkAnchor = false) : _vm.checkError()}}},[_vm._v(" Save ")]):_vm._e(),_c('div',{staticClass:"form__content",staticStyle:{"width":"100%"}},[_c('LangTabs',{attrs:{"languages":_vm.getCurrentMode === 'review' || _vm.getSelectedTab.title === 'logs'
          ? _vm.CompairingService.compareLangData(
              _vm.brokerReview && _vm.brokerReview.currentData
                ? _vm.brokerReview.currentData.mainData
                : _vm.oldData
                ? _vm.oldData.mainData
                : null,
              _vm.brokerReview && _vm.brokerReview.changesData
                ? _vm.brokerReview.changesData.mainData
                : _vm.newData
                ? _vm.newData.mainData
                : null
            )
          : _vm.languages,"name":"mainData","brokerData":_vm.currentBrokerData.mainData,"changesData":_vm.changedBrokerData ? _vm.changedBrokerData.mainData : undefined}}),_c('DisableBroker',{attrs:{"brokerData":{ disableAll: _vm.getDisableAll(_vm.currentBrokerData.mainData) }}}),(_vm.currentBrokerData.anchor)?_c('SitePreview',{attrs:{"anchor":_vm.currentBrokerData.anchor,"screenshotCreated":_vm.currentBrokerData.screenshotUpdate,"screenshotUrl":_vm.currentBrokerData.screenshotUrl,"noCreateScreenshot":_vm.currentBrokerData.noCreateScreenshot,"changedNoCreateScreenshot":(this.getCurrentMode === 'review' ||
          _vm.getSelectedTab.title === 'logs') &&
        _vm.changedBrokerData
          ? _vm.changedBrokerData.noCreateScreenshot
          : undefined}}):_vm._e(),(
        _vm.currentBrokerData.anchor &&
        this.getSelectedTab.title !== 'logs' &&
        this.getCurrentMode !== 'review'
      )?_c('AddFavIcon',{attrs:{"iconUrl":_vm.currentBrokerData.favicon}}):_vm._e(),_c('LangTabs',{attrs:{"languages":_vm.getCurrentMode === 'review' || _vm.getSelectedTab.title === 'logs'
          ? _vm.CompairingService.compareLangData(
              _vm.brokerReview && _vm.brokerReview.currentData
                ? _vm.brokerReview.currentData.brokerCompanies
                : _vm.oldData
                ? _vm.oldData.brokerCompanies
                : null,
              _vm.brokerReview && _vm.brokerReview.changesData
                ? _vm.brokerReview.changesData.brokerCompanies
                : _vm.newData
                ? _vm.newData.brokerCompanies
                : null,
              'brokerCompanies'
            )
          : _vm.languages,"name":"companyBlock","brokerData":_vm.currentBrokerData.brokerCompanies,"changesData":(this.getCurrentMode === 'review' ||
          _vm.getSelectedTab.title === 'logs') &&
        _vm.changedBrokerData
          ? _vm.changedBrokerData.brokerCompanies
          : undefined}}),_c('AboutBroker',{attrs:{"brokerData":{
        anchor: _vm.currentBrokerData.anchor,
        foundedYear: _vm.currentBrokerData.foundedYear,
        onlineSinceYear: _vm.currentBrokerData.onlineSinceYear,
        contactsForAdmin: _vm.currentBrokerData.contactsForAdmin,
      },"brokerChanges":_vm.changedBrokerData
          ? {
              anchor: _vm.changedBrokerData.anchor,
              foundedYear: _vm.changedBrokerData.foundedYear,
              onlineSinceYear: _vm.changedBrokerData.onlineSinceYear,
              contactsForAdmin: _vm.changedBrokerData.contactsForAdmin,
            }
          : null,"anchorExist":_vm.anchorExist}}),_c('CheckboxSelect',{attrs:{"brokerData":_vm.currentRegulations,"changesBrokerData":_vm.getSelectedTab.title === 'logs'
          ? _vm.changedBrokerData.brokerLicenses
          : this.getCurrentMode === 'review' &&
            _vm.brokerReview &&
            _vm.brokerReview.changesData
          ? _vm.brokerReview.changesData.brokerLicenses
          : undefined,"type":"licenses","title":"Regulation / license number"}}),_c('PaymentFields',{attrs:{"brokerData":{
        allowedCountries: _vm.allowedCountries,
        prohibitedCountries: _vm.prohibitedCountries,
        paymentOptions: _vm.currentBrokerData.paymentOptions,
        currencyPairs: _vm.currentBrokerData.currencyPairs,
        acceptsUsClients: _vm.currentBrokerData.acceptsUsClients,
        accountCurrencies: _vm.currentBrokerData.accountCurrencies,
      },"brokerChanges":(this.getCurrentMode === 'review' ||
          _vm.getSelectedTab.title === 'logs') &&
        _vm.changedBrokerData
          ? {
              allowedCountries: _vm.changedAllowedCountries,
              prohibitedCountries: _vm.changedProhibitedCountries,
              paymentOptions: _vm.changedBrokerData.paymentOptions,
              currencyPairs: _vm.changedBrokerData.currencyPairs,
              acceptsUsClients: _vm.changedBrokerData.acceptsUsClients,
              accountCurrencies: _vm.changedBrokerData.accountCurrencies,
            }
          : undefined}}),(
        _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
          ? _vm.CompairingService.showField(
              _vm.currentBrokerData.ibBroker,
              _vm.changedBrokerData ? _vm.changedBrokerData.ibBroker : null
            )
          : true
      )?_c('BrokerRelations',{attrs:{"name":"ibBroker","label":"Is this broker IB of same other","desc":"Either select one from the list or, if it's absent, input the name into the field","selected":_vm.currentBrokerData.ibBroker,"changesSelected":(this.getCurrentMode === 'review' &&
          _vm.brokerReview &&
          _vm.brokerReview.changesData) ||
        _vm.getSelectedTab.title === 'logs'
          ? _vm.changedBrokerData.ibBroker
          : undefined}}):_vm._e(),_c('BrokerOptions',{attrs:{"brokerData":_vm.currentBrokerOptions,"brokerChanges":(this.getCurrentMode === 'review' ||
          _vm.getSelectedTab.title === 'logs') &&
        _vm.changedBrokerData
          ? _vm.changedBrokerData.brokerOptions
          : undefined}}),_c('DemoPlatformsInput',{attrs:{"brokerData":_vm.currentBrokerData.brokerDemoPlatforms,"changesBrokerData":_vm.changedBrokerData ? _vm.changedBrokerData.brokerDemoPlatforms : undefined}}),_c('BrokerWebsite',{attrs:{"brokerData":{
        websiteLanguages: _vm.currentBrokerData.websiteLanguages,
        supportLanguages: _vm.currentBrokerData.supportLanguages,
        freeEducation: _vm.currentBrokerData.freeEducation,
        vpsState: _vm.currentBrokerData.vpsState,
        vpsValue: _vm.currentBrokerData.vpsValue,
        serverTimezone: _vm.currentBrokerData.serverTimezone,
        stopOrdersRebates: _vm.currentBrokerData.stopOrdersRebates || '0',
        limitOrdersRebates: _vm.currentBrokerData.limitOrdersRebates || '0',
        allOrdersRebates: _vm.currentBrokerData.allOrdersRebates || '0',
        supportMethods: _vm.currentBrokerData.supportMethods,
      },"brokerChanges":(this.getCurrentMode === 'review' ||
          _vm.getSelectedTab.title === 'logs') &&
        _vm.changedBrokerData
          ? {
              countryOffices: _vm.changedBrokerData.countryOffices,
              websiteLanguages: _vm.changedBrokerData.websiteLanguages,
              supportLanguages: _vm.changedBrokerData.supportLanguages,
              freeEducation: _vm.changedBrokerData.freeEducation,
              vpsState: _vm.changedBrokerData.vpsState,
              vpsValue: _vm.changedBrokerData.vpsValue,
              serverTimezone: _vm.changedBrokerData.serverTimezone,
              stopOrdersRebates: _vm.changedBrokerData.stopOrdersRebates,
              limitOrdersRebates: _vm.changedBrokerData.limitOrdersRebates,
              allOrdersRebates: _vm.changedBrokerData.allOrdersRebates,
              supportMethods: _vm.changedBrokerData.supportMethods,
            }
          : null}}),_c('LangTabs',{attrs:{"languages":_vm.getCurrentMode === 'review' || _vm.getSelectedTab.title === 'logs'
          ? _vm.CompairingService.compareLangData(
              _vm.brokerReview && _vm.brokerReview.currentData
                ? _vm.brokerReview.currentData.brokerVipConditions
                : _vm.oldData
                ? _vm.oldData.brokerVipConditions
                : null,
              _vm.brokerReview && _vm.brokerReview.changesData
                ? _vm.brokerReview.changesData.brokerVipConditions
                : _vm.newData
                ? _vm.newData.brokerVipConditions
                : null
            )
          : _vm.languages,"name":"vipCondition","brokerData":_vm.currentBrokerData.brokerVipConditions,"changesData":(this.getCurrentMode === 'review' ||
          this.getSelectedTab.title === 'logs') &&
        _vm.changedBrokerData
          ? _vm.changedBrokerData.brokerVipConditions
          : undefined}}),(_vm.isAdmin)?_c('LangTabs',{attrs:{"languages":_vm.getCurrentMode === 'review' || _vm.getSelectedTab.title === 'logs'
          ? _vm.CompairingService.compareLangData(
              _vm.brokerReview && _vm.brokerReview.currentData
                ? _vm.brokerReview.currentData.brokerNotes
                : _vm.oldData && _vm.oldData.brokerNotes && _vm.oldData.brokerNotes.length
                ? _vm.oldData.brokerNotes
                : null,
              _vm.brokerReview && _vm.brokerReview.changesData
                ? _vm.brokerReview.changesData.brokerNotes
                : _vm.newData && _vm.newData.brokerNotes && _vm.newData.brokerNotes.length
                ? _vm.newData.brokerNotes
                : null
            )
          : _vm.languages,"name":"brokerNotes","brokerData":_vm.currentBrokerData.brokerNotes,"changesData":(this.getCurrentMode === 'review' ||
          this.getSelectedTab.title === 'logs') &&
        _vm.changedBrokerData
          ? _vm.changedBrokerData.brokerNotes
          : undefined}}):_vm._e(),(_vm.isAdmin)?_c('LangTabs',{attrs:{"languages":_vm.getCurrentMode === 'review' || _vm.getSelectedTab.title === 'logs'
          ? _vm.CompairingService.compareLangData(
              _vm.brokerReview && _vm.brokerReview.currentData
                ? _vm.brokerReview.currentData.brokerWarningSings
                : _vm.oldData &&
                  _vm.oldData.brokerWarningSings &&
                  _vm.oldData.brokerWarningSings.length
                ? _vm.oldData.brokerWarningSings
                : null,
              _vm.brokerReview && _vm.brokerReview.changesData
                ? _vm.brokerReview.changesData.brokerWarningSings
                : _vm.newData &&
                  _vm.newData.brokerWarningSings &&
                  _vm.newData.brokerWarningSings.length
                ? _vm.newData.brokerWarningSings
                : null
            )
          : _vm.languages,"name":"brokerWarningSings","brokerData":_vm.currentBrokerData.brokerWarningSings,"changesData":(this.getCurrentMode === 'review' ||
          this.getSelectedTab.title === 'logs') &&
        _vm.changedBrokerData
          ? _vm.changedBrokerData.brokerWarningSings
          : undefined}}):_vm._e(),_c('BrokerVipOptions',{attrs:{"brokerData":_vm.currentBrokerVipOptions,"brokerChanges":(this.getCurrentMode === 'review' ||
          _vm.getSelectedTab.title === 'logs') &&
        _vm.changedBrokerData
          ? _vm.changedBrokerData.brokerVipConditionOptions
          : undefined}}),(
        (_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
          ? _vm.CompairingService.showField(
              _vm.currentBrokerData.ebBroker,
              _vm.changedBrokerData ? _vm.changedBrokerData.ebBroker : null
            )
          : true) && this.getCurrentMode !== 'create'
      )?_c('BrokerRelations',{attrs:{"name":"ebBroker","label":"Was this broker acquired by some other","desc":"Either select one from the list or, if it's absent, input the name into the field","selected":_vm.currentBrokerData.ebBroker,"changesSelected":(this.getCurrentMode === 'review' &&
          _vm.brokerReview &&
          _vm.brokerReview.changesData) ||
        _vm.getSelectedTab.title === 'logs'
          ? _vm.changedBrokerData.ebBroker
          : undefined}}):_vm._e(),_c('BrokerFlags',{attrs:{"isAdmin":_vm.isAdmin,"brokerData":{
        autochartist: _vm.currentBrokerData.autochartist,
        bully: _vm.currentBrokerData.bully,
        scam: _vm.currentBrokerData.scam,
      },"brokerChanges":(this.getCurrentMode === 'review' ||
          _vm.getSelectedTab.title === 'logs') &&
        _vm.changedBrokerData
          ? {
              autochartist: _vm.changedBrokerData.autochartist,
              bully: _vm.changedBrokerData.bully,
              scam: _vm.changedBrokerData.scam,
            }
          : undefined}}),_c('LangTabs',{attrs:{"languages":_vm.getCurrentMode === 'review' || _vm.getSelectedTab.title === 'logs'
          ? _vm.CompairingService.compareLangData(
              _vm.brokerReview && _vm.brokerReview.currentData
                ? _vm.brokerReview.currentData.brokerFlags
                : _vm.oldData
                ? _vm.oldData.brokerFlags
                : null,
              _vm.brokerReview && _vm.brokerReview.changesData
                ? _vm.brokerReview.changesData.brokerFlags
                : _vm.newData
                ? _vm.newData.brokerFlags
                : null
            )
          : _vm.languages,"name":"brokerFlags","brokerData":_vm.currentBrokerData.brokerFlags,"changesData":(this.getCurrentMode === 'review' ||
          this.getSelectedTab.title === 'logs') &&
        _vm.changedBrokerData
          ? _vm.changedBrokerData.brokerFlags
          : undefined}}),_c('LangTabs',{attrs:{"languages":_vm.getCurrentMode === 'review' || _vm.getSelectedTab.title === 'logs'
          ? _vm.CompairingService.compareLangData(
              _vm.brokerReview && _vm.brokerReview.currentData
                ? _vm.brokerReview.currentData.brokerHistory
                : _vm.oldData
                ? _vm.oldData.brokerHistory
                : null,
              _vm.brokerReview && _vm.brokerReview.changesData
                ? _vm.brokerReview.changesData.brokerHistory
                : _vm.newData
                ? _vm.newData.brokerHistory
                : null,
              'brokerHistory'
            )
          : _vm.languages,"name":"history","brokerData":_vm.currentBrokerData.brokerHistory,"changesData":(this.getCurrentMode === 'review' ||
          _vm.getSelectedTab.title === 'logs') &&
        _vm.changedBrokerData
          ? _vm.changedBrokerData.brokerHistory
          : undefined}})],1),(_vm.getCurrentMode === 'review')?_c('MassToggler'):_vm._e(),(
      this.getSelectedTab.title !== 'logs' ||
      (this.getSelectedTab.title === 'logs' && this.oldData.anchor)
    )?_c('button',{staticClass:"btn btn--big",attrs:{"type":"button","disabled":(_vm.getCurrentMode === 'review' && _vm.toReview(_vm.getBrokerData)) ||
      (_vm.getCurrentMode !== 'review' && !_vm.getIsChanged)},on:{"keydown":function($event){$event.preventDefault();},"click":function($event){$event.preventDefault();_vm.needToCheckAnchor ? (_vm.checkAnchor = false) : _vm.checkError()}}},[_vm._v(" "+_vm._s(this.getSelectedTab.title === "logs" ? "Rollback" : this.getCurrentMode === "review" ? "Review" : "Save")+" broker ")]):_vm._e(),(!_vm.getSelectedTab.innerTab && !_vm.atLeastOneApprove)?_c('button',{staticClass:"delete_btn",attrs:{"type":"button"},on:{"click":_vm.openDeleteModal}},[_c('img',{attrs:{"src":require("@/assets/svg/basket.svg"),"alt":"delete icon"}}),_vm._v(" Delete broker ")]):_vm._e(),_c('Modal',{attrs:{"title":"Save data?","id":"unsavedData"}},[_c('template',{slot:'modal-content'},[_c('center',[_vm._v("All unsaved data will be lost!")])],1),_c('template',{slot:"modal-controls"},[_c('button',{staticClass:"btn",attrs:{"disabled":_vm.getCurrentMode === 'review' && _vm.toReview(_vm.getBrokerData)},on:{"click":function($event){$event.preventDefault();_vm.needToCheckAnchor ? (_vm.checkAnchor = false) : _vm.checkError(),
            _vm.closeModal()}}},[_vm._v(" Save and exit ")]),_c('button',{staticClass:"btn btn--error",attrs:{"type":"button"},on:{"click":_vm.changePage}},[_vm._v(" Exit without saving ")])])],2),_c('Modal',{attrs:{"title":"Delete broker?","id":"deletingBroker"}},[_c('template',{slot:'modal-content'},[_c('center',[_vm._v(" You are about to delete this broker! We can't undo that later! ")])],1),_c('template',{slot:"modal-controls"},[_c('button',{staticClass:"btn",on:{"click":function($event){$event.preventDefault();_vm.doDeleteBroker(), _vm.closeDeleteModal()}}},[_vm._v(" Yes, do it! ")]),_c('button',{staticClass:"btn btn--error",attrs:{"type":"button"},on:{"click":_vm.closeDeleteModal}},[_vm._v(" No, do not delete that broker ")])])],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }