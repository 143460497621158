class CurrencyPair {
  constructor(id, currencyPair) {
    this.id = id;
    this.nameCurrencyPair = currencyPair || "";
    this.typicalSpread = null;
    this.lowestSpread = null;
    this.typeSpread = "PIPS";
    this.kindSpread = "FIXED";
  }
}

export default CurrencyPair;
