<template>
  <fieldset name="brokerOptions" class="options_wrap">
    <Radio
      half-width
      :label="radio.optionName || radio.name"
      :values="radioSelections"
      :name="radio.optionName || radio.name"
      :key="index"
      v-for="(radio, index) in brokerData"
      :id="$route.name !== 'brokerCreate' ? +radio.id : null"
      :selected="brokerData.length > 0 ? radio.optionState : 'NO'"
      :changesSelected="
        brokerChanges
          ? brokerChanges.find((item) => item.optionId === radio.optionId)
              .optionState
          : undefined
      "
      :optionId="$route.name !== 'brokerCreate' ? radio.optionId : radio.id"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
    />
  </fieldset>
</template>
<script>
import Radio from "@/components/formFields/Radio";
import { mapGetters } from "vuex";
export default {
  name: "BrokerOptions",
  components: {
    Radio,
  },
  props: {
    brokerData: Array,
    brokerChanges: Array,
  },
  data() {
    return {
      radioSelections: [
        { label: "No", value: "NO" },
        { label: "Yes", value: "YES" },
        { label: "Coming Soon", value: "COMING_SOON" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getSelectedTab"]),
  },
};
</script>
