<template>
  <fieldset
    name="checkboxItem"
    :class="[
      'checkbox',
      { 'checkbox--checked': checkboxState },
      { 'checkbox--instrument': inputType === 'number' },
    ]"
    v-if="checkboxMain && checkbox"
    :data-id="id"
    :data-licenseId="
      checkboxMain['licenseId']
        ? checkboxMain['licenseId']
        : checkboxMain['instrumentId']
        ? checkboxMain['instrumentId']
        : checkboxMain.id
    "
  >
    <label class="checkbox__wrap">
      <input
        type="checkbox"
        :name="
          $route.name !== 'brokerReview'
            ? checkboxMain['name']
            : checkboxMain['licenseName'] || checkboxMain['instrumentName']
        "
        :value="checkboxMain['id']"
        v-model="checkboxState"
        @input="isFormChangedDetect(true)"
      />
      <span class="checkbox__label">
        {{
          checkboxMain["name"] ||
          checkboxMain["licenseName"] ||
          checkboxMain["instrumentName"]
        }}
      </span>
    </label>
    <Radio
      column
      label="Leverage kind"
      :values="[
        {
          value: `FIXED`,
          label: 'Fixed',
        },
        {
          value: `VARIABLE`,
          label: 'Variable',
        },
      ]"
      :name="`${
        internalCheckbox['name'] ||
        internalCheckbox['licenseName'] ||
        internalCheckbox['instrumentName']
      }-kindLeverage`"
      :checker="null"
      :selected="checkbox.kindLeverage"
      :changesSelected="
        internalChangedValue ? internalChangedValue.kindLeverage : undefined
      "
      v-model="instrument.kindLeverage"
      @input="changedCheckboxData()"
      v-if="checkboxState && inputType === 'number'"
    />
    <div
      class="checkbox__input_container"
      v-show="checkboxState && showSimpleInput"
    >
      <strong class="input__label" v-if="inputType === 'number'">
        Max Leverage (w/o "1:")*
      </strong>
      <input
        :placeholder="inputType === 'number' ? '' : 'license number'"
        v-model="inputValue"
        class="checkbox__input"
        :name="
          inputType === 'number' ? 'maxLeverage' : internalCheckbox['name']
        "
        :data-id="internalCheckbox['id']"
        :data-licenseId="
          internalCheckbox['licenseId']
            ? internalCheckbox['licenseId']
            : internalCheckbox['instrumentId']
            ? internalCheckbox['instrumentId']
            : null
        "
        data-type="currentValue"
        :data-deleted="changedValue === null"
        :required="
          (($route.name !== 'brokerReview' &&
            getSelectedTab.title !== 'logs') ||
            ($route.name === 'brokerEdit' &&
              getSelectedTab.title !== 'logs')) &&
          showSimpleInput
        "
        :type="inputType"
        @blur="changedCheckboxData(), isFormChangedDetect(true)"
      />
      <input
        v-if="
          ($route.name === 'brokerReview' || getSelectedTab.title === 'logs') &&
          this.internalChangedValue
        "
        :placeholder="inputType === 'number' ? 'Max' : 'license number'"
        :type="inputType === 'number' ? 'number' : 'text'"
        v-model="inputChangedValue"
        class="checkbox__input"
        :name="
          inputType === 'number'
            ? 'maxLeverage'
            : internalCheckbox['name'] || internalChangedValue['name']
        "
        :data-id="internalCheckbox['id']"
        :data-licenseId="
          internalCheckbox['licenseId']
            ? internalCheckbox['licenseId']
            : internalCheckbox['instrumentId']
            ? internalCheckbox['instrumentId']
            : null
        "
        data-type="changesValue"
        @blur="changedCheckboxData(), isFormChangedDetect(true)"
      />
    </div>
    <div class="variable_leverage" v-show="checkboxState && !showSimpleInput">
      <div class="variable_leverage__position_wrapper">
        <div
          class="variable_leverage__position"
          :key="position.id"
          v-for="position in instrument.accountTypeInstrumentPairs"
        >
          <div class="double_inputs__wrapper">
            <strong class="input__label"> Position* </strong>
            <div class="double_inputs">
              <input
                type="number"
                placeholder="Min"
                v-model="position.minPosition"
                class="checkbox__input"
                name="minPosition"
                data-type="currentValue"
                :required="
                  (($route.name !== 'brokerReview' &&
                    getSelectedTab.title !== 'logs') ||
                    ($route.name === 'brokerEdit' &&
                      getSelectedTab.title !== 'logs')) &&
                  !showSimpleInput
                "
                @blur="changedCheckboxData(), isFormChangedDetect(true)"
              />
              <input
                type="number"
                placeholder="Max"
                v-model="position.maxPosition"
                class="checkbox__input"
                name="maxPosition"
                data-type="currentValue"
                :required="
                  (($route.name !== 'brokerReview' &&
                    getSelectedTab.title !== 'logs') ||
                    ($route.name === 'brokerEdit' &&
                      getSelectedTab.title !== 'logs')) &&
                  $route.name !== 'brokerEdit' &&
                  !showSimpleInput
                "
                @blur="changedCheckboxData(), isFormChangedDetect(true)"
              />
            </div>
            <div
              class="double_inputs"
              v-if="
                ($route.name === 'brokerReview' ||
                  getSelectedTab.title === 'logs') &&
                internalChangedValue
              "
            >
              <input
                placeholder="Min"
                class="checkbox__input"
                name="minPosition"
                data-type="changedValue"
                :required="
                  (($route.name !== 'brokerReview' &&
                    getSelectedTab.title !== 'logs') ||
                    ($route.name === 'brokerEdit' &&
                      getSelectedTab.title !== 'logs')) &&
                  !showSimpleInput
                "
                :value="findMinPosition(position.id)"
                @blur="changedCheckboxData(), isFormChangedDetect(true)"
              />
              <input
                placeholder="Max"
                class="checkbox__input"
                name="maxPosition"
                data-type="changedValue"
                :required="
                  (($route.name !== 'brokerReview' &&
                    getSelectedTab.title !== 'logs') ||
                    ($route.name === 'brokerEdit' &&
                      getSelectedTab.title !== 'logs')) &&
                  !showSimpleInput
                "
                :value="findMaxPosition(position.id)"
                @blur="changedCheckboxData(), isFormChangedDetect(true)"
              />
            </div>
          </div>
          <Radio
            column
            label="Volume Type"
            v-if="checkboxState && !showSimpleInput"
            :values="[
              {
                value: `CURRENCY`,
                label: 'Currency',
              },
              {
                value: `LOT`,
                label: 'Lot',
              },
            ]"
            :name="`${
              internalCheckbox['name'] ||
              internalCheckbox['licenseName'] ||
              internalCheckbox['instrumentName']
            }-typeLeverage-${position.id}`"
            :checker="null"
            :selected="
              (altTypeLeverageModel
                ? position.typeLeverage
                : internalCheckbox.typeLeverage) || 'CURRENCY'
            "
            :changesSelected="findPositionTypeLeverage(position.id)"
            v-model="position.typeLeverage"
            @input="changedCheckboxData()"
          />

          <div class="checkbox__input_container">
            <strong class="input__label" v-if="inputType === 'number'">
              Leverage
            </strong>
            <input
              placeholder='(w/o "1:")*'
              v-model="position.leverage"
              class="checkbox__input"
              :name="
                inputType === 'number'
                  ? 'maxLeverage'
                  : internalCheckbox['name']
              "
              :data-id="internalCheckbox['id']"
              :data-licenseId="
                internalCheckbox['licenseId']
                  ? internalCheckbox['licenseId']
                  : internalCheckbox['instrumentId']
                  ? internalCheckbox['instrumentId']
                  : null
              "
              data-type="currentValue"
              :required="
                inputValue === '' &&
                (($route.name !== 'brokerReview' &&
                  getSelectedTab.title !== 'logs') ||
                  ($route.name === 'brokerEdit' &&
                    getSelectedTab.title !== 'logs'))
              "
              type="number"
              @blur="changedCheckboxData(), isFormChangedDetect(true)"
            />
            <input
              v-if="
                $route.name === 'brokerReview' ||
                getSelectedTab.title === 'logs'
              "
              placeholder='(w/o "1:")*'
              type="number"
              class="checkbox__input"
              :name="inputType === 'number' ? 'maxLeverage' : checkbox['name']"
              :data-id="internalCheckbox['id']"
              :data-licenseId="
                internalCheckbox['licenseId']
                  ? internalCheckbox['licenseId']
                  : internalCheckbox['instrumentId']
                  ? internalCheckbox['instrumentId']
                  : null
              "
              :value="findPositionLeverage(position.id)"
              data-type="changesValue"
              @blur="changedCheckboxData(), isFormChangedDetect(true)"
            />
          </div>

          <button
            type="button"
            @click.prevent="removePosition(position)"
            class="history_item__remove"
          ></button>
        </div>
      </div>

      <button
        type="button"
        class="add_history_item"
        v-if="
          checkboxState &&
          !showSimpleInput &&
          instrument.accountTypeInstrumentPairs.length < 10 &&
          getCurrentMode !== 'reviews' &&
          getSelectedTab.title !== 'logs'
        "
        @click="addNewPosition()"
      >
        <p class="img-alt">+</p>
        <span>Add position</span>
      </button>
    </div>

    <Checker
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      @set-approve="setApprove"
    />
  </fieldset>
</template>

<script>
import Checker from "@/components/formFields/Checker.vue";
import { mapMutations, mapGetters } from "vuex";
import CompairingService from "@/services/CompairingService";
import Radio from "@/components/formFields/Radio.vue";

export default {
  name: "Checkbox",
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    checkbox: Object,
    value: [String, Number],
    changedValue: [Object, String],
    checker: String,
    licenseId: [String, Number],
    id: [Number, String],
    inputType: String,
  },
  components: { Checker, Radio },
  data() {
    return {
      checkboxState: "",
      inputValue: "",
      uniqueId: 0,
      inputChangedValue: "",
      instrument: {
        id: this.id,
        instrumentId: "",
        instrumentName: "",
        kindLeverage: "",
        leverage: "",
        accountTypeInstrumentPairs: [],
      },
      instrumentPosition: {
        typeLeverage: "",
        leverage: "",
        minPosition: "",
        maxPosition: "",
      },
      CompairingService: new CompairingService(),
      changedTypicalSpread: "",
      changedLowestLeverage: "",
      changedLowestSpread: "",
      internalCheckbox: {},
      internalChangedValue: {},
    };
  },
  beforeMount() {
    let fillInternalChangedValue = true;
    if (
      this.inputType === "number" &&
      this?.checkbox?.accountTypeInstrumentPairs
    ) {
      this.instrument = this.checkbox;

      if (
        this.getCurrentMode === "review" ||
        this.getSelectedTab.title === "logs"
      ) {
        if (this?.changedValue?.accountTypeInstrumentPairs?.length) {
          for (const idx in this.changedValue.accountTypeInstrumentPairs) {
            const pair = this.changedValue.accountTypeInstrumentPairs[idx];
            if (this.isNewAccTypeInstrPair(pair)) {
              this.instrument.accountTypeInstrumentPairs.push({
                id: pair.id,
              });
            }
          }
        }

        if (this.changedValue === undefined) {
          this.internalChangedValue = this.fillEmptyValuesFromCb(
            this.instrument
          );

          fillInternalChangedValue = false;
        }
      }
    }

    if (fillInternalChangedValue) {
      this.internalChangedValue = this.changedValue;
    }

    if (
      this.inputType === "number" &&
      Boolean(this.changedValue?.accountTypeInstrumentPairs?.length) !==
        false &&
      this.checkbox?.accountTypeInstrumentPairs === undefined
    ) {
      this.internalCheckbox = this.fillEmptyValuesFromCb(this.changedValue);
      this.instrument = this.internalCheckbox;
    } else {
      this.internalCheckbox = this.checkbox;
    }
  },
  mounted() {
    this.checkboxState = this.checked;

    if (this.checkbox) {
      this.inputValue = this.checkbox?.leverage || this.checkbox?.licenseNumber;
      this.lowestLeverage = this.checkbox?.lowestLeverage;
      this.typicalSpread = this.checkbox?.typicalSpread;
      this.lowestSpread = this.checkbox?.lowestSpread;
    }
    if (this.changedValue !== undefined) {
      this.inputChangedValue =
        typeof this.changedValue === "string"
          ? this.changedValue
          : this.changedValue?.leverage || this.changedValue?.licenseNumber;
      this.changedLowestLeverage = this.changedValue?.lowestLeverage;
      this.changedTypicalSpread = this.changedValue?.typicalSpread;
      this.changedLowestSpread = this.changedValue?.lowestSpread;
    }
    if (!this.instrument?.accountTypeInstrumentPairs?.length && this.checked) {
      this.addNewPosition();
    }
    if (this.getSelectedTab.title === "logs") {
      this.setApprove("true");
    }
  },
  computed: {
    ...mapGetters(["getCurrentMode", "getSelectedTab"]),
    checkboxMain() {
      return this.checkbox || this.changedValue;
    },
    showSimpleInput() {
      if (this.inputType !== "number") {
        return true;
      } else if (
        this.getCurrentMode !== "review" &&
        this.getSelectedTab.title !== "logs" &&
        this.instrument.kindLeverage === "FIXED"
      ) {
        return true;
      } else if (
        (this.getCurrentMode === "review" ||
          this.getSelectedTab.title === "logs") &&
        Boolean(
          this.internalChangedValue?.accountTypeInstrumentPairs?.length
        ) === false
      ) {
        return true;
      } else {
        return (
          this.getCurrentMode === "review" &&
          this.getSelectedTab === "logs" &&
          this.instrument.kindLeverage === "FIXED" &&
          this.internalChangedValue?.kindLeverage === "FIXED"
        );
      }
    },
    altTypeLeverageModel() {
      return this.getSelectedTab.title === "account types";
    },
  },
  methods: {
    ...mapMutations([
      "settingDatatoReviewed",
      "isFormChangedDetect",
      "settingAccountTypesDatatoReviewed",
      "updateSingleInstrument",
      "updateSingleLicense",
    ]),
    setApprove(data) {
      let obj = {
        state: this.checkboxState,
        id: this.id,
        reviewed: data,
        type: "licenseNumber",
        fromApproveChecker: this.getCurrentMode === "review",
      };
      let source = data === "true" ? this.internalChangedValue : this.checkbox;
      if (this.inputType !== "number") {
        obj.licenseId = source?.licenseId;
        this.settingDatatoReviewed(obj);
      } else {
        Object.assign(obj, data === "true" ? this.changedValue : this.checkbox);
        this.settingAccountTypesDatatoReviewed(obj);
      }
    },
    changedCheckboxData() {
      let target =
        this.getSelectedTab.title === "logs" && this.changedValue
          ? this.changedValue
          : this.instrument;

      if (this.inputType === "number") {
        this.updateSingleInstrument({
          ...target,
          instrumentId: this.checkboxMain["instrumentId"]
            ? this.checkboxMain["instrumentId"]
            : this.checkboxMain.id,
          instrumentName:
            this.checkboxMain["name"] || this.checkboxMain["instrumentName"],
        });
      } else {
        this.updateSingleLicense({
          licenseId: this.checkboxMain["licenseId"]
            ? this.checkboxMain["licenseId"]
            : this.checkboxMain.id,
          licenseNumber: this.inputValue,
          licenseName:
            this.checkboxMain["name"] || this.checkboxMain["licenseName"],
          id: this.id ? this.id : null,
        });
      }
    },

    addNewPosition() {
      this.instrument.accountTypeInstrumentPairs.push(
        Object.assign({ id: this.uniqueId++ }, this.instrumentPosition)
      );
    },

    removePosition(obj) {
      this.instrument.accountTypeInstrumentPairs =
        this.instrument.accountTypeInstrumentPairs.filter(
          (item) => item.id !== obj.id
        );
      this.changedCheckboxData();
      this.isFormChangedDetect(true);
    },

    findMinPosition(id) {
      return this.internalChangedValue?.accountTypeInstrumentPairs?.find(
        (item) => +item.id === +id
      )?.minPosition;
    },
    findMaxPosition(id) {
      return this.internalChangedValue?.accountTypeInstrumentPairs?.find(
        (item) => +item.id === +id
      )?.maxPosition;
    },
    findPositionLeverage(id) {
      return this.internalChangedValue?.accountTypeInstrumentPairs?.find(
        (item) => +item.id === +id
      )?.leverage;
    },
    findPositionTypeLeverage(id) {
      return this.internalChangedValue?.accountTypeInstrumentPairs?.find(
        (item) => +item.id === +id
      )?.typeLeverage;
    },
    isNewAccTypeInstrPair(pair) {
      return (
        this.checkbox?.accountTypeInstrumentPairs?.find(
          (item) => +item.id === +pair.id
        ) === undefined
      );
    },
    fillEmptyValuesFromCb(cb) {
      let obj = {};
      for (const idx in cb) {
        if (Array.isArray(cb[idx])) {
          obj[idx] = [];
          for (const idx2 in cb[idx]) {
            if (typeof cb[idx][idx2] === "object") {
              obj[idx].push(this.fillEmptyValuesFromCb(cb[idx][idx2]));
            }
          }
        } else if (typeof cb[idx] === "object") {
          obj[idx] = this.fillEmptyValuesFromCb(cb[idx]);
        } else {
          if (
            idx === "id" ||
            idx === "instrumentId" ||
            idx === "instrumentName"
          ) {
            obj[idx] = cb[idx];
          } else {
            obj[idx] = null;
          }
        }
      }

      return obj;
    },
  },
  watch: {
    "instrument.kindLeverage": function () {
      if (
        this.instrument.kindLeverage === "VARIABLE" &&
        !this.instrument.accountTypeInstrumentPairs.length
      ) {
        this.addNewPosition();
      }
    },
    inputValue(val) {
      this.inputValue =
        this.inputType === "number" && val
          ? (this.instrument.leverage = +this.$options.filters.numbersOnly(val))
          : val;
    },
  },
};
</script>
