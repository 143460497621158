<template>
  <div class="wrapper">
    <Header />

    <div class="center container">
      <main class="main" id="main">
        <Breadcrumbs
          :breadCrumbs="[
            { name: 'home', text: 'Earn Forex' },
            {
              name: 'brokers',
              text: 'Brokers',
            },
          ]"
          v-if="$route.params.id"
        />

        <SubBrokersNav />

        <Tabs
          :key="'BrokerTabs' + new Date().getMilliseconds()"
          :tabsList="tablist"
        />
      </main>
    </div>

    <Footer />
    <ScrollTop v-if="showScrollTop" />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import SubBrokersNav from "@/components/SubBrokersNav.vue";
import Footer from "@/components/Footer.vue";
import Tabs from "@/components/Tabs.vue";
import Breadcrumbs from "@/components/Breadcrumbs";
import { mapGetters, mapMutations } from "vuex";
import generalReview from "@/components/graphQL/fragments/generalReview.gql";
import brokerLockStatus from "@/components/graphQL/brokerLockStatus.gql";
import { lockingBroker } from "@/components/graphQL/brokerMutations";
import ScrollTop from "@/components/ScrollTop";

export default {
  name: "Home",
  apollo: {
    generalReview: {
      query: generalReview,
      variables() {
        return {
          brokerId: this.$route.params.id,
        };
      },
      skip() {
        return this.$route.name === "brokerCreate";
      },
      result(res) {
        if (res.data) {
          this.currentAccountTypeId = this.generalReview.accountTypes.length
            ? this.generalReview.accountTypes[0].id
            : null;
        }
      },
    },
    brokerLockStatus: {
      query: brokerLockStatus,
      variables() {
        return {
          brokerId: this.$route.params.id,
        };
      },
      skip() {
        return this.$route.name !== "brokerEdit";
      },
      pollInterval: 25000,
      result(result) {
        if (result.data.brokerLockStatus.locked) {
          this.$router.push({ name: "brokers" });
          this.setNotification({
            type: "error",
            text: "Sorry, broker is locked now. Someone else working with it",
          });
        } else {
          this.lockBroker();
        }
      },
    },
  },
  components: {
    Header,
    SubBrokersNav,
    Footer,
    Tabs,
    Breadcrumbs,
    ScrollTop,
  },

  computed: {
    ...mapGetters([
      "getCurrentMode",
      "getIsChanged",
      "getSelectedTab",
      "getTabsNeedUpdates",
    ]),

    tablist: (vm) => {
      let tabs = [];
      if (vm.$route.name === "brokerCreate") {
        tabs.push({
          title: "main data",
        });
        tabs.push({
          title: "account types",
          disabled: true,
        });
      } else if (vm.$route.name === "brokerEdit") {
        tabs.push({
          title: "main data",
          star: vm.generalReview && vm.generalReview.broker !== null,
        });
        tabs.push({
          title: "account types",
          star: vm.generalReview && !!vm.generalReview.accountTypes.length,
        });
        tabs.push({ title: "logs" });
      } else if (vm.$route.name === "brokerReview") {
        vm.generalReview && vm.generalReview.broker !== null
          ? tabs.push({
              title: "main data",
              star: true,
            })
          : null;
        vm.generalReview && vm.generalReview.accountTypes.length
          ? tabs.push({
              title: "account types",
              star: true,
            })
          : null;
        tabs.push({ title: "logs" });
      }

      if (localStorage.getItem("Mode") === "client") {
        tabs = tabs.filter((item) => item.title !== "logs");
      }
      return tabs;
    },

    showScrollTop: () => {
      return document.querySelector("body").offsetHeight < window.innerHeight;
    },
  },

  watch: {
    getSelectedTab: function () {
      if (this.getTabsNeedUpdates) {
        this.$apollo.queries.generalReview.refetch();
      }
    },
  },

  methods: {
    ...mapMutations([
      "setCurrentMode",
      "resetingBroker",
      "setLinkToGo",
      "isFormChangedDetect",
      "setNotification",
    ]),

    ...mapMutations({
      setCurrentBrokerMainData: "SET_CURRENT_BROKER_MAIN_DATA",
    }),
    ...mapMutations({ setParentBrokerMainData: "SET_PARENT_BROKER_MAIN_DATA" }),
    ...mapMutations({ setRelatedBrokers: "SET_RELATED_BROKERS" }),

    lockBroker() {
      this.$apollo.mutate({
        mutation: this.getCurrentMode === "edit" ? lockingBroker : "",
        variables: {
          brokerId: parseInt(this.$route.params.id),
        },
      });
    },
  },

  beforeRouteLeave(to, from, next) {
    if (!this.getIsChanged) {
      next();
      this.setLinkToGo(null);
    } else {
      this.setLinkToGo({ link: to.name, type: "page" });
      this.isFormChangedDetect(false);
      document.querySelector("#unsavedData").classList.add("modal--open");
      next(false);
    }

    // clear vuex data
    this.setCurrentBrokerMainData(null);
    this.setRelatedBrokers([]);
    this.setParentBrokerMainData(null);
  },
};
</script>
