import BrokerHistoryItem from "@/models/brokerHistoryItem";
import BrokerDemoPlatform from "@/models/brokerDemoPlatform";
import BrokerCompany from "@/models/BrokerCompany";
import CurrencyPair from "@/models/currencyPair";

class PreparingDataServices {
  prepareHistory(languages) {
    let brokerHistory = [];
    languages.forEach((item) => {
      brokerHistory.push({
        languageSlug: item.slug,
        data: [new BrokerHistoryItem(this.generateId())],
      });
    });
    return brokerHistory;
  }

  createHistoryItem() {
    return new BrokerHistoryItem(this.generateId());
  }

  createDemoPlatform() {
    return new BrokerDemoPlatform(this.generateId());
  }

  generateId() {
    let possible = "abcdefghijklmnopqrstuvwxyz";
    let id =
      possible.charAt(Math.floor(Math.random() * possible.length)) +
      possible.charAt(Math.floor(Math.random() * possible.length)) +
      Math.round(Math.random() * 10000);
    return id;
  }

  createBrokerCompany() {
    return new BrokerCompany(this.generateId());
  }

  prepareBrokerCompanies(languages) {
    let brokerCompanies = [];
    languages.forEach(() => {
      brokerCompanies.push(new BrokerCompany(this.generateId()));
    });
    return brokerCompanies;
  }

  createCurrencyPair(currencyPair) {
    return new CurrencyPair(this.generateId(), currencyPair);
  }
  createNewLogItemCurrencyPair(currencyPair) {
    return new CurrencyPair("newLogItem", currencyPair);
  }
}

export default PreparingDataServices;
