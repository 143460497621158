var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-part form-part--account_types_main"},[(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.accountTypeData && _vm.accountTypeData.commission
              ? _vm.accountTypeData.commission
              : null,
            _vm.accountTypeChanges && _vm.accountTypeChanges.commission
              ? _vm.accountTypeChanges.commission
              : null
          )
        : true
    )?_c('Input',{attrs:{"label":"Commission per 1 std. lot","name":"commission","type":"number","checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null,"value":_vm.accountTypeData.commission,"changesValue":_vm.accountTypeChanges ? _vm.accountTypeChanges.commission : undefined}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.accountTypeData && _vm.accountTypeData.commissionCfd
              ? _vm.accountTypeData.commissionCfd
              : null,
            _vm.accountTypeChanges && _vm.accountTypeChanges.commissionCfd
              ? _vm.accountTypeChanges.commissionCfd
              : null
          )
        : true
    )?_c('Input',{attrs:{"label":"Commission for CFD per 1 std. lot:","name":"commissionCfd","checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null,"value":_vm.accountTypeData.commissionCfd,"type":"number","changesValue":_vm.accountTypeChanges ? _vm.accountTypeChanges.commissionCfd : undefined}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.accountTypeData && _vm.accountTypeData.marginCallLevel
              ? _vm.accountTypeData.marginCallLevel
              : null,
            _vm.accountTypeChanges && _vm.accountTypeChanges.marginCallLevel
              ? _vm.accountTypeChanges.marginCallLevel
              : null
          )
        : true
    )?_c('Input',{attrs:{"label":"Margin call level (in %):","name":"marginCallLevel","checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null,"value":_vm.accountTypeData.marginCallLevel,"type":"number","changesValue":_vm.accountTypeChanges ? _vm.accountTypeChanges.marginCallLevel : undefined}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.accountTypeData && _vm.accountTypeData.stopOutLevel
              ? _vm.accountTypeData.stopOutLevel
              : null,
            _vm.accountTypeChanges && _vm.accountTypeChanges.stopOutLevel
              ? _vm.accountTypeChanges.stopOutLevel
              : null
          )
        : true
    )?_c('Input',{attrs:{"label":"Stop-out level (in %):","name":"stopOutLevel","type":"number","checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null,"value":_vm.accountTypeData.stopOutLevel,"changesValue":_vm.accountTypeChanges ? _vm.accountTypeChanges.stopOutLevel : undefined}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.accountTypeData && _vm.accountTypeData.minDistanceToStopOrders
              ? _vm.accountTypeData.minDistanceToStopOrders
              : null,
            _vm.accountTypeChanges && _vm.accountTypeChanges.minDistanceToStopOrders
              ? _vm.accountTypeChanges.minDistanceToStopOrders
              : null
          )
        : true
    )?_c('Input',{attrs:{"label":"Minimum distance to stop orders","name":"minDistanceToStopOrders","checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null,"value":_vm.accountTypeData.minDistanceToStopOrders,"type":"number","changesValue":_vm.accountTypeChanges
        ? _vm.accountTypeChanges.minDistanceToStopOrders
        : undefined}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.accountTypeData && _vm.accountTypeData.minDistanceToLimitOrders
              ? _vm.accountTypeData.minDistanceToLimitOrders
              : null,
            _vm.accountTypeChanges && _vm.accountTypeChanges
              ? _vm.accountTypeChanges.minDistanceToLimitOrders
              : null
          )
        : true
    )?_c('Input',{attrs:{"label":"Minimum distance to limit orders:","name":"minDistanceToLimitOrders","checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null,"value":_vm.accountTypeData.minDistanceToLimitOrders,"type":"number","changesValue":_vm.accountTypeChanges
        ? _vm.accountTypeChanges.minDistanceToLimitOrders
        : undefined}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.accountTypeData && _vm.accountTypeData.maxPositionsSimultaneous
              ? _vm.accountTypeData.maxPositionsSimultaneous
              : null,
            _vm.accountTypeChanges && _vm.accountTypeChanges
              ? _vm.accountTypeChanges.maxPositionsSimultaneous
              : null
          )
        : true
    )?_c('Input',{attrs:{"label":"Maximum Number of Simultaneous Positions Allowed:","name":"maxPositionsSimultaneous","checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null,"value":_vm.accountTypeData.maxPositionsSimultaneous,"type":"number","changesValue":_vm.accountTypeChanges
        ? _vm.accountTypeChanges.maxPositionsSimultaneous
        : undefined}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }