<template>
  <fieldset class="currency_pair" name="currency_pair" :id="id">
    <div class="double_inputs__wrapper">
      <strong class="input__label"> Currency pair * </strong>
      <input
        placeholder="Enter currency pair"
        v-model="pairInfo.nameCurrencyPair"
        class="checkbox__input"
        name="nameCurrencyPair"
        data-type="currentValue"
        :required="true"
        @blur="updateSingleCurrencyPair(pairInfo), isFormChangedDetect(true)"
        :disabled="disabled"
      />
    </div>

    <div v-if="pairInfo.nameCurrencyPair">
      <Radio
        column
        label="Units"
        :values="[
          {
            value: `PIPS`,
            label: 'Pips ',
          },
          {
            value: `ABSOLUT`,
            label: 'Absolute',
          },
        ]"
        :name="`${uniqName}-typeSpread`"
        :checker="null"
        :selected="value ? value.typeSpread : undefined"
        :changesSelected="changedValue ? changedValue.typeSpread : undefined"
        v-model="pairInfo.typeSpread"
        @change="updateSingleCurrencyPair(pairInfo)"
      />
    </div>
    <div v-if="pairInfo.nameCurrencyPair">
      <Radio
        column
        label="Spread type"
        :values="[
          {
            value: `FIXED`,
            label: 'Fixed',
          },
          {
            value: `VARIABLE`,
            label: 'Variable',
          },
        ]"
        :name="`${uniqName}-kindSpread`"
        :changesSelected="changedValue ? changedValue.kindSpread : undefined"
        :checker="null"
        :selected="value ? value.kindSpread : undefined"
        v-model="pairInfo.kindSpread"
        @change="updateSingleCurrencyPair(pairInfo)"
      />
    </div>
    <div v-if="pairInfo.nameCurrencyPair" class="double_inputs__wrapper">
      <strong class="input__label"> Spread * </strong>
      <div class="double_inputs">
        <input
          placeholder="Lowest"
          v-model="pairInfo.lowestSpread"
          class="checkbox__input"
          name="pairLowestSpread"
          data-type="currentValue"
          :data-id="pairInfo.id"
          :required="true"
          @input="isFormChangedDetect(true)"
          v-if="
            pairInfo.kindSpread &&
            pairInfo.kindSpread.toUpperCase() === 'VARIABLE'
          "
          @blur="updateSingleCurrencyPair(pairInfo)"
        />
        <input
          :placeholder="pairInfo.kindSpread === 'VARIABLE' ? 'Typical' : ''"
          v-model="pairInfo.typicalSpread"
          class="checkbox__input"
          name="pairTypicalSpread"
          data-type="currentValue"
          :data-id="pairInfo.id"
          :required="
            $route.name !== 'brokerReview' && getSelectedTab.title !== 'logs'
          "
          @input="isFormChangedDetect(true)"
          @blur="updateSingleCurrencyPair(pairInfo)"
        />
      </div>
      <div
        class="double_inputs"
        v-if="
          ($route.name === 'brokerReview' || getSelectedTab.title === 'logs') &&
          changedValue
        "
      >
        <input
          placeholder="Lowest"
          class="checkbox__input"
          name="pairLowestSpread"
          data-type="changesValue"
          :data-id="changedPairInfo.id"
          @input="isFormChangedDetect(true)"
          v-model="changedPairInfo.lowestSpread"
          v-if="
            changedValue.kindSpread &&
            changedValue.kindSpread.toUpperCase() === 'VARIABLE'
          "
        />
        <input
          placeholder="Typical"
          v-model="changedPairInfo.typicalSpread"
          class="checkbox__input"
          name="pairTypicalSpread"
          data-type="changesValue"
          :data-id="changedPairInfo.id"
          @input="isFormChangedDetect(true)"
        />
      </div>
    </div>

    <button
      type="button"
      class="history_item__remove"
      @click.prevent="deletePair"
      v-if="!checker && !disabled"
    ></button>

    <input
      type="hidden"
      name="currency_pair_data"
      :id="value.id || changedValue.id"
      :value="JSON.stringify(pairInfo)"
    />
    <input
      type="hidden"
      name="changed_currency_pair_data"
      :id="value.id || changedValue.id"
      :value="JSON.stringify(changedPairInfo)"
      v-if="changedValue"
    />

    <Checker v-if="checker" />
  </fieldset>
</template>
<script>
import Radio from "@/components/formFields/Radio";
import { mapGetters, mapMutations } from "vuex";
import Checker from "@/components/formFields/Checker.vue";

export default {
  name: "CurrencyPairs",
  components: {
    Radio,
    Checker,
  },
  props: {
    value: { type: Object, required: true },
    changedValue: { type: Object, required: false },
    checker: String,
    id: [String, Number],
    disabled: Boolean,
  },

  data() {
    return {
      currencies: [],
      radioSelections: [],
      selectedCurrencyPair: null,
      pairInfo: {
        nameCurrencyPair: "",
        kindSpread: "",
        typeSpread: "",
        lowestSpread: "",
        typicalSpread: "",
        id: this.id,
      },
      changedPairInfo: {
        nameCurrencyPair: "",
        kindSpread: "",
        typeSpread: "",
        lowestSpread: "",
        typicalSpread: "",
        id: this.id,
      },
    };
  },
  computed: {
    ...mapGetters(["getCurrentMode", "getSelectedTab", "getAccountType"]),

    uniqName: (vm) => {
      return (
        (vm.value?.nameCurrencyPair || vm.changedValue?.nameCurrencyPair) +
        (vm.value?.id || vm.changedValue?.id)
      );
    },
  },
  methods: {
    ...mapMutations([
      "isFormChangedDetect",
      "removeCurrencyPair",
      "updateSingleCurrencyPair",
    ]),

    deletePair() {
      this.removeCurrencyPair(this.value.id);
      this.isFormChangedDetect(true);

      this.$emit("deleted-pair", true);
    },
  },
  mounted() {
    if (this.value) {
      this.pairInfo = this.value;
      if (
        isNaN(parseInt(this.value.id) && this.changedValue !== undefined) &&
        (this.$route.name === "brokerReview" ||
          this.getSelectedTab.title === "logs")
      ) {
        this.pairInfo.nameCurrencyPair = this?.changedValue?.nameCurrencyPair;
      }
    }
    if (this.changedValue) {
      this.changedPairInfo = this.changedValue;
      if (this.changedValue.id !== "newLogItem") {
        this.changedPairInfo.id = this.id;
      }
    }
  },
};
</script>
