<template>
  <div>
    <ul class="breadcrumbs">
      <li v-for="(crumb, index) in breadCrumbs" :key="index">
        <router-link :to="{ name: crumb.name }">
          {{ crumb.text }}
        </router-link>
      </li>
    </ul>

    <header class="page_title" v-if="!$apollo.loading && broker">
      <div class="page_title__info">
        <h1 class="title">
          <span v-if="parentBrokerMainData && parentBrokerMainData.id">
            <router-link :to="`/edit_broker/${parentBrokerMainData.id}`">{{
              parentBrokerMainData.name
            }}</router-link>
            -
          </span>
          <span>{{ broker.mainData[0].name }}</span>
        </h1>
        <div
          class="page_title__status"
          :class="
            toReview(broker)
              ? 'page_title__status--review'
              : 'page_title__status--updated'
          "
        >
          {{ toReview(broker) ? "need to review" : "up-to-date" }}
        </div>
      </div>

      <router-link
        v-if="!relatedBrokers.length && parentBrokerMainData"
        :to="`/broker/add_broker?broker=${$route.params.id}`"
        class="page_title__add-broker"
      >
        <img src="@/assets/svg/ico-plus.svg" alt="+" />
        <span>Add sub-broker</span>
      </router-link>
    </header>
  </div>
</template>

<script>
import { mapState } from "vuex";
import brokerDataForBreadcrumbs from "@/components/graphQL/fragments/brokerDataForBreadcrumbs.gql";

export default {
  name: "Breadcrumbs",
  props: {
    breadCrumbs: {
      type: Array,
      required: true,
    },
  },
  apollo: {
    broker: {
      query: brokerDataForBreadcrumbs,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },
  computed: {
    ...mapState({
      parentBrokerMainData: (state) => state.brokers.parentBrokerMainData,
    }),
    ...mapState({ relatedBrokers: (state) => state.brokers.relatedBrokers }),

    currentBrokerName() {
      if (this.broker && this.broker.mainData[0].name) {
        return this.broker.mainData[0].name;
      } else if (this.parentBrokerMainData && this.parentBrokerMainData.name) {
        return this.parentBrokerMainData.name;
      } else {
        return "EarnForex";
      }
    },
  },
  watch: {
    currentBrokerName(newValue) {
      if (this.parentBrokerMainData && this.parentBrokerMainData.name) {
        document.title = `Main Data | ${newValue} | ${this.parentBrokerMainData.name} | EarnForex`;
      } else {
        document.title = `Main Data | ${newValue} | EarnForex`;
      }
    },
  },
  methods: {
    toReview(item) {
      return (
        item.reviewed.toLowerCase() === "none" ||
        item.reviewed.toLowerCase() === "wait_after_update"
      );
    },
  },
};
</script>
