var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-part form-part--account_types_main"},[(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.accountTypeData && _vm.accountTypeData.name
              ? _vm.accountTypeData.name
              : null,
            _vm.accountTypeChanges && _vm.accountTypeChanges.name
              ? _vm.accountTypeChanges.name
              : null
          )
        : true
    )?_c('Input',{attrs:{"label":"Account type/name","required":"","name":"name","checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null,"value":_vm.accountTypeData.name ? _vm.accountTypeData.name : undefined,"changesValue":_vm.accountTypeChanges ? _vm.accountTypeChanges.name : undefined,"error":_vm.anchorExist ? 'This name taken, try another' : ''}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.accountTypeData && _vm.accountTypeData.minAccountSize
              ? _vm.accountTypeData.minAccountSize
              : null,
            _vm.accountTypeChanges && _vm.accountTypeChanges.minAccountSize
              ? _vm.accountTypeChanges.minAccountSize
              : null
          )
        : true
    )?_c('Input',{attrs:{"label":"Minimum account size:","name":"minAccountSize","type":"number","required":"","checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null,"value":_vm.accountTypeData.minAccountSize,"changesValue":_vm.accountTypeChanges ? _vm.accountTypeChanges.minAccountSize : undefined}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.accountTypeData && _vm.accountTypeData.maxAccountSize
              ? _vm.accountTypeData.maxAccountSize
              : null,
            _vm.accountTypeChanges && _vm.accountTypeChanges.maxAccountSize
              ? _vm.accountTypeChanges.maxAccountSize
              : null
          )
        : true
    )?_c('Input',{attrs:{"label":"Maximum account size","name":"maxAccountSize","type":"number","checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null,"value":_vm.accountTypeData.maxAccountSize,"changesValue":_vm.accountTypeChanges ? _vm.accountTypeChanges.maxAccountSize : undefined}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.accountTypeData && _vm.accountTypeData.minPositionSize
              ? _vm.accountTypeData.minPositionSize
              : null,
            _vm.accountTypeChanges && _vm.accountTypeChanges.minPositionSize
              ? _vm.accountTypeChanges.minPositionSize
              : null
          )
        : true
    )?_c('Input',{attrs:{"label":"Minimum position size (in lots, 1 lot = 100,000)","name":"minPositionSize","type":"number","checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null,"value":_vm.accountTypeData.minPositionSize,"step":"0.0001","changesValue":_vm.accountTypeChanges ? _vm.accountTypeChanges.minPositionSize : undefined}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }