var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"broker-data"},[(_vm.$route.params.id && _vm.relatedBrokers.length)?_c('div',{staticClass:"broker-data__header"},[_c('div',{staticClass:"broker-data__tabs"},[(_vm.parentBroker)?_c('router-link',{staticClass:"broker-data__tabs-item",attrs:{"id":("broker-" + (_vm.parentBroker.id)),"to":("/edit_broker/" + (_vm.parentBroker.id))}},[_c('span',{staticClass:"broker-data__tabs-item-label"},[_vm._v(_vm._s(_vm.parentBroker.name))]),_c('span',{class:[
            'broker-data__tabs-item-status',
            {
              '--review': _vm.toReview(_vm.parentBroker),
              '--updated': !_vm.toReview(_vm.parentBroker),
            } ]},[_vm._v(_vm._s(_vm.toReview(_vm.parentBroker) ? "need to review" : "up-to-date"))])]):_vm._e(),_vm._l((_vm.relatedBrokersFiltered),function(item){return _c('router-link',{key:item.id,staticClass:"broker-data__tabs-item",attrs:{"id":("broker-" + (item.id)),"to":("/edit_broker/" + (item.id))}},[_c('span',{staticClass:"broker-data__tabs-item-label"},[_vm._v(_vm._s(item.name))]),_c('span',{class:[
            'broker-data__tabs-item-status',
            {
              '--review': _vm.toReview(item),
              '--updated': !_vm.toReview(item),
            } ]},[_vm._v(_vm._s(_vm.toReview(item) ? "need to review" : "up-to-date"))])])})],2),_c('router-link',{staticClass:"broker-data__add-broker",attrs:{"to":("/broker/add_broker?broker=" + (_vm.parentBroker && _vm.parentBroker.id ? _vm.parentBroker.id : _vm.$route.params.id))}},[_c('img',{attrs:{"src":require("@/assets/svg/ico-plus.svg"),"alt":"+"}}),_c('span',[_vm._v("Add sub-broker")])])],1):_vm._e(),(_vm.$route.query.broker)?_c('div',{staticClass:"broker-data__back-to-broker"},[_c('button',{staticClass:"btn",on:{"click":_vm.goBack}},[_vm._v("Back to main broker")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }