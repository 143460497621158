var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getSelectedTab)?_c('div',{staticClass:"tabs",attrs:{"id":"tabs"}},[_c('header',{key:_vm.getSelectedTab.title || 'tab',staticClass:"tabs__header"},_vm._l((_vm.tabsList),function(tab,tabIndex){return _c('button',{key:tabIndex,staticClass:"tabs__toggler",class:[
        {
          'tabs__toggler--active':
            _vm.getSelectedTab.title.toLowerCase() === tab.title.toLowerCase(),
          'tabs__toggler--star': tab.star,
        } ],attrs:{"disabled":tab.disabled},on:{"click":function($event){return _vm.changeTab(tab)}}},[_vm._v(" "+_vm._s(tab.title)+" ")])}),0),_c('div',{staticClass:"tabs__content"},[(_vm.getSelectedTab && _vm.getSelectedTab.title === 'account types')?_c('AccountTypes',{key:'acctype' + new Date().getMilliseconds()}):_vm._e(),(
        _vm.getSelectedTab &&
        _vm.getSelectedTab.title === 'logs' &&
        !_vm.getSelectedTab.innerTab
      )?_c('Log'):_vm._e(),(
        _vm.getSelectedTab &&
        _vm.getSelectedTab.title === 'logs' &&
        _vm.getSelectedTab.innerTab === 'broker rollback'
      )?_c('BrokerRollback'):_vm._e(),(_vm.getSelectedTab && _vm.getSelectedTab.title === 'main data')?_c('BrokerForm',{key:'maindata' + new Date().getMilliseconds()}):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }