<template>
  <form
    id="account_types"
    class="account_types"
    v-if="(accountTypes || generalReview) && showForm"
  >
    <button
      type="button"
      @click.prevent="
        getCurrentMode === 'edit' &&
        (currentAccountTypeId === 'newAccType' || currentAccountTypeId === null)
          ? (checkAnchor = false)
          : checkError()
      "
      class="btn save_broker"
      :disabled="
        (getCurrentMode === 'review' && toReview(getAccountType)) ||
        (getCurrentMode !== 'review' && !getIsChanged)
      "
      v-if="
        (currentBrokerData &&
          !(
            Object.hasOwnProperty(currentBrokerData, 'deletingMode') &&
            currentBrokerData.deletingMode
          ) &&
          getSelectedTab.title !== 'logs') ||
        (getSelectedTab.title === 'logs' && this.oldData.name)
      "
    >
      Save
    </button>

    <div
      class="account_types__toggler"
      v-if="
        ((accountTypes && accountTypes.length) ||
          (generalReview && generalReview.accountTypes.length)) &&
        getSelectedTab.title !== 'logs'
      "
    >
      <button
        class="account_types__toggler-item"
        :class="
          currentAccountTypeId === accountType.id
            ? 'account_types__toggler-item--active'
            : ''
        "
        v-for="accountType in this.$route.name !== 'brokerReview'
          ? accountTypes
          : generalReview.accountTypes"
        :key="accountType.id"
        @click.prevent="changeSubTab(accountType.id, accountType.name)"
        :disabled="accountType && accountType.deletingMode"
      >
        {{ accountType.name }}
      </button>
      <button
        class="account_types__toggler-item"
        :class="
          currentAccountTypeId === 'newAccType'
            ? 'account_types__toggler-item--active'
            : ''
        "
        @click.prevent="changeSubTab('newAccType')"
        v-if="$route.name !== 'brokerReview'"
      >
        New Account
      </button>
    </div>
    <div
      class="form__content"
      style="width: 100%"
      v-if="currentBrokerData && !currentBrokerData.deletingMode"
      :key="
        currentAccountTypeId !== null && currentAccountTypeId !== 'newAccType'
          ? currentBrokerData.id
          : 'new'
      "
    >
      <AccountTypeMain
        :accountTypeData="{
          name: currentBrokerData.name,
          minAccountSize: currentBrokerData.minAccountSize,
          maxAccountSize: currentBrokerData.maxAccountSize,
          minPositionSize: currentBrokerData.minPositionSize,
        }"
        :accountTypeChanges="
          (this.getCurrentMode === 'review' ||
            getSelectedTab.title === 'logs') &&
          changedBrokerData
            ? {
                name: changedBrokerData.name,
                minAccountSize: changedBrokerData.minAccountSize,
                maxAccountSize: changedBrokerData.maxAccountSize,
                minPositionSize: changedBrokerData.minPositionSize,
              }
            : null
        "
        :anchorExist="this.anchorExist"
        :key="
          currentAccountTypeId !== null && currentAccountTypeId !== 'newAccType'
            ? currentAccountTypeId
            : 'new'
        "
      />
      <CurrencyPairsBlock
        :brokerData="currentCurrencyPairs"
        :changesBrokerData="
          (getSelectedTab.title === 'logs' || getCurrentMode === 'review') &&
          changedBrokerData
            ? changedBrokerData.accountTypeCurrencyPairs
            : undefined
        "
      />

      <AccountTypeFinancial
        :accountTypeData="{
          minDistanceToLimitOrders: currentBrokerData.minDistanceToLimitOrders,
          commission: currentBrokerData.commission,
          marginCallLevel: currentBrokerData.marginCallLevel,
          commissionCfd: currentBrokerData.commissionCfd,
          stopOutLevel: currentBrokerData.stopOutLevel,
          minDistanceToStopOrders: currentBrokerData.minDistanceToStopOrders,
          maxPositionsSimultaneous: currentBrokerData.maxPositionsSimultaneous,
        }"
        :accountTypeChanges="
          (this.getCurrentMode === 'review' ||
            getSelectedTab.title === 'logs') &&
          changedBrokerData
            ? {
                minDistanceToLimitOrders:
                  changedBrokerData.minDistanceToLimitOrders,
                commission: changedBrokerData.commission,
                marginCallLevel: changedBrokerData.marginCallLevel,
                commissionCfd: changedBrokerData.commissionCfd,
                stopOutLevel: changedBrokerData.stopOutLevel,
                minDistanceToStopOrders:
                  changedBrokerData.minDistanceToStopOrders,
                maxPositionsSimultaneous:
                  changedBrokerData.maxPositionsSimultaneous,
              }
            : null
        "
      />
      <AccountPermissions
        :accountTypeData="{
          scalpingAllowed: currentBrokerData.scalpingAllowed,
          scalpingExplanations: currentBrokerData.scalpingExplanations,
          eaAllowed: currentBrokerData.eaAllowed,
          eaExplanations: currentBrokerData.eaExplanations,
        }"
        :accountTypeChanges="
          (this.getCurrentMode === 'review' ||
            getSelectedTab.title === 'logs') &&
          changedBrokerData
            ? {
                scalpingAllowed: changedBrokerData.scalpingAllowed,
                scalpingExplanations: changedBrokerData.scalpingExplanations,
                eaAllowed: changedBrokerData.eaAllowed,
                eaExplanations: changedBrokerData.eaExplanations,
              }
            : null
        "
      />
      <SpecialConditions
        :accountTypeData="
          currentBrokerData.accountTypeSpecialConditions
            ? currentBrokerData.accountTypeSpecialConditions
            : null
        "
        :accountTypeChanges="
          (this.getCurrentMode === 'review' ||
            getSelectedTab.title === 'logs') &&
          changedBrokerData
            ? changedBrokerData.accountTypeSpecialConditions
            : null
        "
      />
      <AccountSelects
        :accountTypeData="{
          hedgingAllowed: currentBrokerData.hedgingAllowed,
          interestRate: currentBrokerData.interestRate,
          digitsAfterDot: currentBrokerData.digitsAfterDot,
          centAccount: currentBrokerData.centAccount,
          executionMethod: currentBrokerData.executionMethod,
        }"
        :accountTypeChanges="
          (this.getCurrentMode === 'review' ||
            getSelectedTab.title === 'logs') &&
          changedBrokerData
            ? {
                hedgingAllowed: changedBrokerData.hedgingAllowed,
                interestRate: changedBrokerData.interestRate,
                digitsAfterDot: changedBrokerData.digitsAfterDot,
                centAccount: changedBrokerData.centAccount,
                executionMethod: changedBrokerData.executionMethod,
              }
            : null
        "
      />
      <CheckboxSelect
        type="instruments"
        title="Instrument"
        subtitle='Max. Leverage (w/o "1:")'
        :brokerData="currentInstruments"
        :changesBrokerData="
          getSelectedTab.title === 'logs'
            ? changedBrokerData.accountTypeInstruments
            : this.getCurrentMode === 'review' &&
              accountTypeReview &&
              accountTypeReview.changesData
            ? accountTypeReview.changesData.accountTypeInstruments
            : undefined
        "
        :key="checkboxSelectKey"
      />
      <AccountTradingPlatform
        :accountTypeData="{
          tradingPlatforms: currentBrokerData.tradingPlatforms,
          currencyPairsQuantity: currentBrokerData.currencyPairsQuantity,
          exoticCurrencyPairsQuantity:
            currentBrokerData.exoticCurrencyPairsQuantity,
        }"
        :accountTypeChanges="
          (this.getCurrentMode === 'review' ||
            getSelectedTab.title === 'logs') &&
          changedBrokerData
            ? {
                tradingPlatforms: changedBrokerData.tradingPlatforms,
                currencyPairsQuantity: changedBrokerData.currencyPairsQuantity,
                exoticCurrencyPairsQuantity:
                  changedBrokerData.exoticCurrencyPairsQuantity,
              }
            : null
        "
      />
      <AccountProperties
        :accountTypeData="{
          automated: currentBrokerData.automated,
          api: currentBrokerData.api,
          web_based: currentBrokerData.web_based,
          oneClick: currentBrokerData.oneClick,
          oco: currentBrokerData.oco,
          pendingOrders: currentBrokerData.pendingOrders,
          trailingStop: currentBrokerData.trailingStop,
          operatingSystems: currentBrokerData.operatingSystems,
        }"
        :accountTypeChanges="
          (this.getCurrentMode === 'review' ||
            getSelectedTab.title === 'logs') &&
          changedBrokerData
            ? {
                automated: changedBrokerData.automated,
                api: changedBrokerData.api,
                web_based: changedBrokerData.web_based,
                oneClick: changedBrokerData.oneClick,
                oco: changedBrokerData.oco,
                pendingOrders: changedBrokerData.pendingOrders,
                trailingStop: changedBrokerData.trailingStop,
                operatingSystems: changedBrokerData.operatingSystems,
              }
            : null
        "
      />
    </div>
    <div
      class=""
      v-else-if="currentBrokerData && currentBrokerData.deletingMode"
    >
      <center style="font-size: 18px">
        User ask to delete the "{{ currentBrokerData.name }}" account. Please
        approve or cancel it.
      </center>

      <div style="display: flex; justify-content: center; align-items: center">
        <button
          class="btn"
          style="margin: 15px"
          @click.prevent="approveDeleting(true)"
        >
          Approve
        </button>
        <button
          style="margin: 15px"
          type="button"
          @click="approveDeleting(false)"
          class="btn btn--error"
        >
          Cancel
        </button>
      </div>
    </div>
    <MassToggler
      v-if="
        getCurrentMode === 'review' &&
        currentBrokerData &&
        !currentBrokerData.deletingMode
      "
    />
    <button
      type="button"
      @click.prevent="
        getCurrentMode === 'edit' &&
        (currentAccountTypeId === null || currentAccountTypeId === 'newAccType')
          ? (checkAnchor = false)
          : checkError()
      "
      class="btn btn--big"
      v-if="
        (currentBrokerData &&
          !currentBrokerData.deletingMode &&
          this.getSelectedTab.title !== 'logs') ||
        (this.getSelectedTab.title === 'logs' && this.oldData.name)
      "
      :disabled="
        (getCurrentMode === 'review' && toReview(getAccountType)) ||
        (getCurrentMode !== 'review' && !getIsChanged)
      "
    >
      {{
        this.getSelectedTab.title === "logs"
          ? "Rollback"
          : this.getCurrentMode === "review"
          ? "Review"
          : "Save"
      }}
      account
    </button>
    <button
      type="button"
      class="delete_btn"
      @click.prevent="showDeleteAccModal"
      v-if="
        currentAccountTypeId !== 'newAccType' &&
        !(
          Object.hasOwnProperty(currentBrokerData, 'deletingMode') &&
          currentBrokerData.deletingMode
        ) &&
        this.getSelectedTab.title !== 'logs'
      "
    >
      <img src="@/assets/svg/basket.svg" alt="EarnForex" />
      Delete Account
    </button>
    <Modal title="Save data?" id="unsavedData">
      <template :slot="'modal-content'">
        <center>All unsaved data will be lost!</center>
      </template>
      <template slot="modal-controls">
        <button
          class="btn"
          @click.prevent="
            isUpdateAccType ? (checkAnchor = false) : checkError(),
              !isUpdateAccType ? switchAccType() : null,
              closeModal(),
              getCurrentMode === 'edit'
                ? $apollo.queries.accountTypes.refetch()
                : null
          "
          :disabled="getCurrentMode === 'review' && toReview(getAccountType)"
        >
          Save and exit
        </button>
        <button
          type="button"
          @click="
            switchAccType(),
              changePage(),
              closeModal(),
              isFormChangedDetect(false)
          "
          class="btn btn--error"
        >
          Exit without saving
        </button>
      </template>
    </Modal>
    <Modal title="Delete Account?" id="deleteAccType">
      <template :slot="'modal-content'">
        <center>Are you sure you want to delete account?</center>
      </template>
      <template slot="modal-controls">
        <button
          class="btn btn--error"
          @click.prevent="removeAccType(), closeModal()"
          :disabled="getCurrentMode === 'review' && toReview(getAccountType)"
        >
          Delete
        </button>
      </template>
    </Modal>
  </form>
</template>

<script>
import AccountTypeMain from "@/components/formParts/AccountTypeMain";
import AccountPermissions from "@/components/formParts/AccountPermissions";
import SpecialConditions from "@/components/formParts/SpecialConditions";
import AccountSelects from "@/components/formParts/AccountSelects";
import AccountTradingPlatform from "@/components/formParts/AccountTradingPlatform";
import CheckboxSelect from "@/components/formParts/CheckboxSelect";
import MassToggler from "@/components/formParts/MassToggler";
import { mapGetters, mapMutations } from "vuex";
import accountTypes from "@/components/graphQL/fragments/brokerAccountTypes.gql";
import accountTypeData from "@/components/graphQL/fragments/brokerAccountType.gql";
import accountTypeReview from "@/components/graphQL/fragments/brokerAccountTypeReview.gql";
import generalReview from "@/components/graphQL/fragments/generalReview.gql";
import GetReviewState from "@/services/GetReviewState";
import {
  createAccountType,
  deleteAccountType,
  reviewAccountType,
  reviewDeletingAccountType,
  rollbackAccountTypeLog,
  updateAccountType,
} from "@/components/graphQL/brokerMutations";
import ParseServicesClass from "@/services/ParseServices";
import Modal from "@/components/Modal";
import { isAccountTypeBusy } from "@/components/graphQL/brokerQueries";
import AccountProperties from "@/components/formParts/AccountProperties";

let ParseServices = new ParseServicesClass();
import CurrencyPairsBlock from "@/components/formParts/CurrencyPairsBlock";
import AccountTypeFinancial from "@/components/formParts/AccountTypeFinancial.vue";

export default {
  name: "AccountTypes",
  components: {
    AccountTypeFinancial,
    CurrencyPairsBlock,
    CheckboxSelect,
    AccountTradingPlatform,
    AccountSelects,
    AccountPermissions,
    AccountTypeMain,
    SpecialConditions,
    MassToggler,
    Modal,
    AccountProperties,
  },
  apollo: {
    accountTypes: {
      query: accountTypes,
      variables() {
        return {
          brokerId: this.$route.params.id,
        };
      },
      skip() {
        return this.$route.name !== "brokerEdit";
      },
      result() {
        this.showForm = true;
        this.currentAccountTypeId = this.currentAccountTypeId
          ? this.currentAccountTypeId
          : this.accountTypes && this.accountTypes.length
          ? this.accountTypes.find((item) => !item.deletingMode).id
          : "newAccType";
      },
    },
    generalReview: {
      query: generalReview,
      variables() {
        return {
          brokerId: this.$route.params.id,
        };
      },
      skip() {
        return this.$route.name !== "brokerReview";
      },
      result() {
        this.currentAccountTypeId = this.generalReview.accountTypes.length
          ? this.generalReview.accountTypes[0].id
          : "newAccType";
        !this.generalReview.accountTypes.length ? this.changePage() : null;
      },
    },
    accountTypeReview: {
      query: accountTypeReview,
      variables() {
        return {
          id: this.currentAccountTypeId,
        };
      },
      skip() {
        return (
          this.$route.name !== "brokerReview" ||
          this.currentAccountTypeId === null ||
          this.currentAccountTypeId === "newAccType"
        );
      },
      result() {
        this.setDataToStore();
      },
    },
    accountType: {
      query: accountTypeData,
      variables() {
        return {
          id: this.currentAccountTypeId,
        };
      },
      skip() {
        return (
          this.currentAccountTypeId === null ||
          this.$route.name === "brokerReview" ||
          this.currentAccountTypeId === "newAccType"
        );
      },
      result() {
        this.showForm = true;
        this.setDataToStore();
        this.checkboxSelectKey++;
        this.$apollo.queries.accountTypes.refetch();
      },
    },
    isAccountTypeBusy: {
      query: isAccountTypeBusy,
      variables() {
        return {
          name: document?.querySelector("input[name='name']")?.value || "",
          brokerId: this.$route.params.id,
        };
      },
      skip() {
        return this.checkAnchor;
      },
      result({ data }) {
        this.checkAnchor = true;
        this.anchorExist = data?.isAccountTypeBusy;
        this.checkError();
        this.switchAccType(!this.getIsChanged);
      },
    },
  },
  props: {
    oldData: Object,
    newData: Object,
    currentData: Object,
  },
  data() {
    return {
      currentAccountTypeId: "newAccType",
      nextAccountTypeId: null,
      GetReviewState: new GetReviewState(),
      anchorExist: false,
      checkAnchor: true,
      showForm: true,
      checkboxSelectKey: 0,
    };
  },
  computed: {
    ...mapGetters([
      "getCurrentMode",
      "getAccountTypeDefault",
      "getAccountType",
      "getIsChanged",
      "getLinkToGo",
      "getSelectedTab",
    ]),
    currentBrokerData: (vm) => {
      if (vm.getSelectedTab.title === "logs") {
        return vm.oldData;
      } else {
        switch (vm.getCurrentMode) {
          case "edit":
            if (
              vm.accountType &&
              vm.currentAccountTypeId !== null &&
              vm.currentAccountTypeId !== "newAccType"
            ) {
              return vm.accountType;
            } else {
              return vm.getAccountTypeDefault;
            }
          case "review":
            if (vm.accountTypeReview) {
              return vm.accountTypeReview.currentData;
            }
            break;
          default:
            return vm.getAccountTypeDefault;
        }
      }
    },
    changedBrokerData: (vm) => {
      if (vm.newData) {
        return vm.newData;
      } else if (vm.accountTypeReview && vm.accountTypeReview.changesData) {
        return vm.accountTypeReview.changesData;
      } else {
        return undefined;
      }
    },
    currentCurrencyPairs: function () {
      if (this.getSelectedTab.title === "logs") {
        return this.currentBrokerData?.accountTypeCurrencyPairs || [];
      } else {
        return this.currentBrokerData?.accountTypeCurrencyPairs?.length
          ? this.currentBrokerData.accountTypeCurrencyPairs
          : this.getAccountType.accountTypeCurrencyPairs;
      }
    },
    currentInstruments() {
      return this.currentBrokerData?.accountTypeInstruments?.length
        ? this.currentBrokerData.accountTypeInstruments
        : this.getAccountType.accountTypeInstruments;
    },
    isUpdateAccType() {
      return (
        this.getCurrentMode === "edit" &&
        (this.currentAccountTypeId === null ||
          this.currentAccountTypeId === "newAccType")
      );
    },
  },
  methods: {
    ...mapMutations([
      "resetAccountType",
      "updateSimpleFields",
      "updateScalpingExplanations",
      "updateEaExplanations",
      "updateSpesialConditions",
      "updateAccountTypeInstruments",
      "setingOldAccountTypeData",
      "removeAccountTypeTypenames",
      "preparingAccountTypeEnum",
      "settingAccountTypesDatatoReviewed",
      "settingAccountDataReviewField",
      "preparingTradingPlaforms",
      "preparingInstruments",
      "isFormChangedDetect",
      "setSelectedTab",
      "preparingNumberInputs",
      "setLinkToGo",
      "setNotification",
      "setCurrentMode",
      "resetingAccount",
      "changeTabsNeedUpdates",
      "removeDeletingMode",
      "removeCreatedAt",
      "removeUpdatedAt",
      "preparingBooleanFields",
      "preparingOperatingSystems",
      "preparingCurrencyPairs",
      "updateCurrencyPairs",
      "deletingEmptyCurrencyPairs",
    ]),
    checkError: function () {
      this.prepareUrl();
      this.checkIntegerMaxValue();
      this.addErrorToInvalidFields();
      this.checkCurrencyPairDublicates();
      this.checkCurrencyPairFilled();
      this.checkCurrencyPairMinValues();
      this.checkInstrumentsFilled();
      let error = document.querySelector(".--error");
      if (error && error.closest(".translation_info__content")) {
        let lang = error.closest("translation_info__content").elements
          .languageSlug.value;
        this.setNotification({
          text:
            "Account not saved. An error has occurred in " + lang + " language",
          type: "error",
        });
        scrollTo({
          top:
            error.closest(".translation_info").getBoundingClientRect().top +
            window.pageYOffset -
            50,
          behavior: "smooth",
        });
      } else if (error) {
        this.setNotification({
          text: "Account not saved. An error has occurred",
          type: "error",
        });
        scrollTo({
          top: error.getBoundingClientRect().top + window.pageYOffset - 50,
          behavior: "smooth",
        });
      } else if (this.anchorExist) {
        scrollTo({
          top:
            document.querySelector("input[name='name']").getBoundingClientRect()
              .top +
            window.pageYOffset -
            50,
          behavior: "smooth",
        });
      } else if (
        document.querySelector("fieldset[name='instrumentsSelect']") &&
        this.getCurrentMode !== "review" &&
        this.getSelectedTab.title !== "logs" &&
        !this.getAccountType.accountTypeInstruments.length
      ) {
        scrollTo({
          top:
            document
              .querySelector("fieldset[name='instrumentsSelect']")
              .getBoundingClientRect().top +
            window.pageYOffset -
            50,
          behavior: "smooth",
        });
        alert("Choose at least 1 instrument");
      } else if (!this.getAccountType.tradingPlatforms) {
        scrollTo({
          top:
            document
              .querySelector("div[name='tradingPlatforms']")
              .getBoundingClientRect().top +
            window.pageYOffset -
            50,
          behavior: "smooth",
        });
        alert("Choose at least 1 trading platform");
      } else {
        this.sendForm();
      }
    },
    checkCurrencyPairDublicates() {
      let currencyPairsNames = Array.from(
        document.querySelectorAll("[name='nameCurrencyPair']")
      );
      if (currencyPairsNames.length) {
        for (let i = 0; i < currencyPairsNames.length; i++) {
          let index = currencyPairsNames.findIndex(
            (item) =>
              item.value === currencyPairsNames[i].value &&
              item.closest("fieldset").id !==
                currencyPairsNames[i].closest("fieldset").id
          );
          if (index >= 0) {
            currencyPairsNames[index]
              .closest("fieldset")
              .classList.add("--error");
            alert(
              'currency pair "' +
                currencyPairsNames[index].value +
                '" already exist. Remove it or rename'
            );
            break;
          } else {
            currencyPairsNames[i]
              .closest("fieldset")
              .classList.remove("--error");
          }
        }
      }
    },
    prepareUrl() {
      document.querySelector("#account_types").elements.forEach((input) => {
        if (
          input.type === "url" &&
          input.value !== "" &&
          (!input.value.startsWith("https://") ||
            !input.value.startsWith("https://"))
        ) {
          input.value = "https://" + input.value;
        }
      });
    },
    addErrorToInvalidFields() {
      document.querySelector("#account_types").elements.forEach((input) => {
        let isValidUrl = (urlString) => {
          let urlPattern = new RegExp(
            "^(https?:\\/\\/)?" + // validate protocol
              "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
              "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
              "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
              "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
              "(\\#[-a-z\\d_]*)?$",
            "i"
          ); // validate fragment locator
          return !!urlPattern.test(urlString);
        };
        this.getCurrentMode !== "review" && input.closest(".input__container")
          ? !input.validity.valid ||
            (input.type === "url" &&
              input.value !== "" &&
              !isValidUrl(input.value) &&
              !input.value.includes("."))
            ? input.closest(".input__container").classList.add("--error")
            : input.closest(".input__container").classList.remove("--error")
          : null;
      });
    },
    checkIntegerMaxValue() {
      document.querySelector("#account_types").elements.forEach((input) => {
        (input.type === "number" || input.type === "float") &&
        input.closest(".input__container")
          ? input.value >= 4294967295
            ? input.closest(".input__container").classList.add("--error")
            : input.closest(".input__container").classList.remove("--error")
          : null;
      });
    },
    checkCurrencyPairFilled() {
      document.querySelectorAll(".currency_pair input").forEach((input) => {
        if (!input.value && input.dataset?.id !== "newLogItem") {
          input.classList.add("--error");
        } else {
          input.classList.remove("--error");
        }
      });
    },
    checkInstrumentsFilled() {
      if (
        (this.$route.name !== "brokerReview" &&
          this.getSelectedTab.title !== "logs") ||
        (this.$route.name === "brokerEdit" &&
          this.getSelectedTab.title !== "logs")
      ) {
        document
          .querySelectorAll(".checkbox--checked input")
          .forEach((input) => {
            let parent =
              input.closest(".variable_leverage") ||
              input.closest(".checkbox__input_container");
            if (parent?.style.display !== "none") {
              if (!input.value && input.required) {
                input.classList.add("--error");
              } else {
                input.classList.remove("--error");
              }
            }
          });
      }
    },
    checkCurrencyPairMinValues() {
      document.querySelectorAll(".currency_pair input").forEach((input) => {
        if (
          input.name === "pairLowestSpread" ||
          input.name === "pairTypicalSpread"
        ) {
          if (input.value < 0.01 && input.dataset?.id !== "newLogItem") {
            input.classList.add("--error");
          } else {
            input.classList.remove("--error");
          }
        }
      });
    },
    sendForm() {
      this.showForm = false;
      if (this.getSelectedTab.title === "logs") {
        ParseServices.parseRollbackAccountTypes().forEach((item) => {
          this.settingAccountTypesDatatoReviewed(item);
        });
      } else if (this.getCurrentMode === "review") {
        ParseServices.parseReviewedAccountTypes().forEach((item) => {
          this.settingAccountTypesDatatoReviewed(item);
        });
      } else {
        ParseServices.parseSimpleInputs().forEach((item) => {
          this.updateSimpleFields(item);
        });
        ParseServices.parseScalpingExplanations().forEach((item) => {
          this.updateScalpingExplanations(item);
        });
        ParseServices.parseEaExplanations().forEach((item) => {
          this.updateEaExplanations(item);
        });
        ParseServices.parseSpecialConditions().forEach((item) => {
          this.updateSpesialConditions(item);
        });
        // this.updateAccountTypeInstruments(ParseServices.parseInstruments());
        ParseServices.parseCurrencyPairs().forEach((item) => {
          this.updateCurrencyPairs(item);
        });
      }
      this.settingAccountDataReviewField(
        this.getCurrentMode !== "review"
          ? "NONE"
          : this.GetReviewState.reviewedState()
      );
      this.preparingCurrencyPairs();
      this.removeAccountTypeTypenames();
      this.preparingAccountTypeEnum();
      this.preparingTradingPlaforms();
      this.preparingInstruments();
      this.preparingNumberInputs();
      this.preparingBooleanFields();
      this.preparingOperatingSystems();
      this.removeDeletingMode();
      this.deletingEmptyCurrencyPairs();
      if (this.getSelectedTab.title === "logs") {
        this.removeCreatedAt();
        this.removeUpdatedAt();
      }
      this.$apollo
        .mutate({
          mutation:
            this.getSelectedTab.title === "logs"
              ? rollbackAccountTypeLog
              : this.getCurrentMode === "review"
              ? reviewAccountType
              : this.currentAccountTypeId === "newAccType"
              ? createAccountType
              : updateAccountType,
          variables: {
            inputData: this.getAccountType,
            brokerId: this.$route.params.id,
            accountTypeId:
              this.currentAccountTypeId !== "newAccType"
                ? this.currentAccountTypeId
                : null,
            brokerLogId: this.getSelectedTab.id,
          },
        })
        .then((data) => {
          this.setNotification({ text: "Account saved.", type: "success" });
          this.isFormChangedDetect(false);
          this.changePage();
          this.resetingAccount();
          this.getAccountType;
          this.$apollo.queries.accountTypes.refetch();

          if (data.data.createAccountType) {
            this.currentAccountTypeId = data.data.createAccountType.id;
          }
        })
        .catch((error) => {
          console.error(error);
          this.setNotification({
            text: "Broker not saved. An error has occurred",
            type: "error",
          });
          this.resetingAccount();
        });
    },
    resetAccountType() {
      this.currentAccountTypeId = "newAccType";
    },
    setDataToStore() {
      if (this.currentData) {
        this.setingOldAccountTypeData(this.currentData);
      } else if (
        this.accountType &&
        this.currentAccountTypeId !== null &&
        this.currentAccountTypeId !== "newAccType" &&
        this.getCurrentMode !== "review"
      ) {
        this.setingOldAccountTypeData(this.accountType);
      } else if (
        this.accountTypeReview &&
        this.currentAccountTypeId !== null &&
        this.currentAccountTypeId !== "newAccType"
      ) {
        this.setingOldAccountTypeData(this.accountTypeReview.currentData);
      } else {
        this.resetingAccount();
      }
    },
    changePage() {
      let vm = this;
      if (this.getLinkToGo) {
        this.getLinkToGo.type === "subTab"
          ? ((this.currentAccountTypeId = this.getLinkToGo.id),
            this.getLinkToGo.id && this.getLinkToGo.id !== "newAccType"
              ? this.getCurrentMode === "review"
                ? this.$apollo.queries.accountTypeReview.refetch()
                : this.$apollo.queries.accountType.refetch()
              : (this.resetAccountType(), (this.showForm = true)))
          : this.getLinkToGo.type === "tab"
          ? this.setSelectedTab(this.getLinkToGo.link)
          : this.getLinkToGo.type === "page"
          ? this.$router.push({ name: this.getLinkToGo.link })
          : null;
      } else {
        if (this.getCurrentMode === "review") {
          this.generalReview.accountTypes.length
            ? this.$apollo.queries.generalReview.refetch().then(() => {
                this.$apollo.queries.accountTypeReview.refetch();
                vm.showForm = true;
              })
            : this.generalReview.broker !== null
            ? (this.setSelectedTab({ title: "main data" }),
              this.changeTabsNeedUpdates(true))
            : this.$router.push({ name: "brokers" });
        } else if (this.getSelectedTab.title === "logs") {
          this.setSelectedTab({
            title: "logs",
          });
        } else {
          this.$apollo.queries.accountTypes.refetch().then(() => {
            this.$apollo.queries.accountType.refetch().then(({ data }) => {
              vm.showForm = true;

              // set page title
              if (data.accountType && data.accountType.name) {
                let title = document.title.split("|");
                if (title[0]) title[0] = data.accountType.name + " ";
                document.title = title.join("|");
              }
            });
          });
        }
      }
      this.resetingAccount();
      this.setLinkToGo(null);
    },
    switchAccType(resetting = true) {
      if (resetting) {
        this.resetingAccount();
      }

      this.setLinkToGo({
        id: this.nextAccountTypeId,
        type: "subTab",
      });
    },
    changeSubTab(subTabToGo, tabName) {
      this.nextAccountTypeId = subTabToGo;
      if (this.getIsChanged) {
        document.querySelector("#unsavedData").classList.add("modal--open");
      } else {
        this.switchAccType();
        this.changePage();
      }

      // set page title
      let title = document.title.split("|");
      if (title[0]) {
        if (subTabToGo === "newAccType") {
          title[0] = " New Account ";
        } else {
          title[0] = tabName + " ";
        }
      }
      document.title = title.join("|");
    },
    closeModal() {
      document.querySelector("#unsavedData").classList.remove("modal--open");
    },
    removeAccType() {
      this.$apollo
        .mutate({
          mutation: deleteAccountType,
          variables: {
            accountTypeId: this.currentAccountTypeId,
          },
        })
        .then(() => {
          this.setNotification({
            text:
              localStorage.getItem("Mode") === "manager"
                ? "Account deleted."
                : "Delete sent for moderation.",
            type: "success",
          });
          this.isFormChangedDetect(false);
          this.resetAccountType();
          this.changePage();
          this.resetingAccount();
          this.getAccountType;
          window.scrollTo(0, 0);
          document
            .querySelector("#deleteAccType")
            .classList.remove("modal--open");

          // set page title
          let title = document.title.split("|");
          if (title[0]) {
            title[0] = " New Account ";
          }
          document.title = title.join("|");
        })
        .catch(() => {
          this.setNotification({
            text: "Account Type not deleted. An error has occurred",
            type: "error",
          });
          this.resetingAccount();
        });
    },
    approveDeleting(bool) {
      this.$apollo
        .mutate({
          mutation: reviewDeletingAccountType,

          variables: {
            accountTypeId: this.currentAccountTypeId,
            toDelete: bool,
          },
        })
        .then(() => {
          this.changePage();
        });
    },
    showDeleteAccModal() {
      document.querySelector("#deleteAccType").classList.add("modal--open");
    },
    toReview(item) {
      return (
        item.reviewed.toLowerCase() === "none" ||
        item.reviewed.toLowerCase() === "wait_after_update"
      );
    },
  },
  mounted() {
    this.resetingAccount();
    this.preparingOperatingSystems();
    this.setCurrentMode(
      this.$route.name === "brokerCreate"
        ? "create"
        : this.$route.name === "brokerEdit"
        ? "edit"
        : this.$route.name === "brokerReview"
        ? "review"
        : null
    );
    this.changeTabsNeedUpdates(false);
    if (this.getSelectedTab.title === "logs") {
      this.currentAccountTypeId =
        this.currentBrokerData?.id || this.changedBrokerData?.id;
    }
  },

  watch: {
    // currentBrokerData: function() {
    //   if (this?.currentBrokerData?.accountTypeCurrencyPairs) {
    //     this.currentBrokerData.accountTypeCurrencyPairs.forEach((item) => {
    //       this.updateCurrencyPairs(item);
    //     });
    //   }
    // }
  },
};
</script>
