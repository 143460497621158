var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account_properties form-part"},[(_vm.showOption('operatingSystems'))?_c('Select',{attrs:{"optionalLabel":function (option) { return option.name; },"options":_vm.operatingSystems,"label":"Operating system (OS)","optionalValue":"id","multiple":"","inline":"","name":"operatingSystems","selected":_vm.accountTypeData ? _vm.accountTypeData.operatingSystems : '',"changesSelected":_vm.accountTypeChanges ? _vm.accountTypeChanges.operatingSystems : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),(_vm.showOption('automated'))?_c('Radio',{attrs:{"halfWidth":true,"label":"Automated","values":_vm.noYesOptions,"name":"automated","selected":_vm.accountTypeData && _vm.accountTypeData.automated !== undefined
        ? _vm.accountTypeData.automated
        : _vm.noYesOptions[0].val,"changesSelected":_vm.accountTypeChanges ? _vm.accountTypeChanges.automated : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),(_vm.showOption('oneClick'))?_c('Radio',{attrs:{"halfWidth":true,"label":"One click trading","values":_vm.noYesOptions,"name":"oneClick","selected":_vm.accountTypeData && _vm.accountTypeData.oneClick !== undefined
        ? _vm.accountTypeData.oneClick
        : _vm.noYesOptions[0].val,"changesSelected":_vm.accountTypeChanges ? _vm.accountTypeChanges.oneClick : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),(_vm.showOption('oco'))?_c('Radio',{attrs:{"halfWidth":true,"label":"OCO","values":_vm.noYesOptions,"name":"oco","selected":_vm.accountTypeData && _vm.accountTypeData.oco !== undefined
        ? _vm.accountTypeData.oco
        : _vm.noYesOptions[0].val,"changesSelected":_vm.accountTypeChanges ? _vm.accountTypeChanges.oco : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),(_vm.showOption('pendingOrders'))?_c('Radio',{attrs:{"halfWidth":true,"label":"Pending orders","values":_vm.noYesOptions,"name":"pendingOrders","selected":_vm.accountTypeData && _vm.accountTypeData.pendingOrders !== undefined
        ? _vm.accountTypeData.pendingOrders
        : _vm.noYesOptions[0].val,"changesSelected":_vm.accountTypeChanges ? _vm.accountTypeChanges.pendingOrders : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),(_vm.showOption('api'))?_c('Radio',{attrs:{"halfWidth":true,"label":"API","values":_vm.noYesOptions,"name":"api","selected":_vm.accountTypeData && _vm.accountTypeData.api !== undefined
        ? _vm.accountTypeData.api
        : _vm.noYesOptions[0].val,"changesSelected":_vm.accountTypeChanges ? _vm.accountTypeChanges.api : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),(_vm.showOption('web_based'))?_c('Radio',{attrs:{"halfWidth":true,"label":"Web based","values":_vm.noYesOptions,"name":"web_based","selected":_vm.accountTypeData && _vm.accountTypeData.web_based !== undefined
        ? _vm.accountTypeData.web_based
        : _vm.noYesOptions[0].val,"changesSelected":_vm.accountTypeChanges ? _vm.accountTypeChanges.web_based : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }