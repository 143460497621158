var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.actualData && _vm.actualData.length > 0)?_c('fieldset',{staticClass:"checkbox_select",class:_vm.showAll ? '--full_list' : '',style:(_vm.getCurrentMode === 'edit' ? 'max-height:' + _vm.minHeight + 'px' : null),attrs:{"name":_vm.type === 'licenses'
      ? 'licensesSelect'
      : _vm.type === 'instruments'
      ? 'instrumentsSelect'
      : ''}},[(
      this.getCurrentMode !== 'review' && this.getSelectedTab.title !== 'logs'
    )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],staticClass:"input__field",attrs:{"type":"search","placeholder":"Fast search"},domProps:{"value":(_vm.query)},on:{"input":function($event){if($event.target.composing){ return; }_vm.query=$event.target.value}}}):_vm._e(),_c('span',{staticClass:"checkbox_select__label"},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.type === 'instruments')?_c('span',{staticClass:"input__required"},[_vm._v("*")]):_vm._e()]),_c('button',{staticClass:"btn",attrs:{"type":"button"},on:{"click":function($event){_vm.showAll = !_vm.showAll}}},[_vm._v(" "+_vm._s(_vm.showAll ? "Hide all" : "Show All")+" ")]),(typeof _vm.actualData !== 'string')?_c('div',{staticClass:"checkbox_select__wrap"},_vm._l((_vm.actualData),function(checkbox,index){return _c('Checkbox',{key:index +
        '-' +
        (checkbox.name || checkbox.licenseName || checkbox.instrumentName),attrs:{"checkbox":_vm.brokerData.find(function (item) { return checkbox.id === item.id; })
          ? _vm.brokerData.find(function (item) { return checkbox.id === item.id; })
          : _vm.type === 'instruments'
          ? _vm.instruments.find(
              function (item) { return checkbox.id === item.id || checkbox.instrumentId === item.id; }
            )
          : _vm.licenses.find(function (item) { return checkbox.id === item.id; }),"checked":checkbox && (checkbox.instrumentId || checkbox.licenseId)
          ? true
          : false,"inputType":_vm.type === 'instruments' ? 'number' : null,"value":!_vm.$apollo.loading && checkbox[_vm.checkId] ? _vm.actualValue(checkbox) : null,"changedValue":_vm.changedData(checkbox),"id":checkbox.id,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
          ? 'unset'
          : null}})}),1):_vm._e(),_c('p',{directives:[{name:"show",rawName:"v-show",value:(typeof _vm.actualData === 'string'),expression:"typeof actualData === 'string'"}],staticClass:"checkbox_select__no_results"},[_vm._v(" No result for query: \""+_vm._s(_vm.query)+"\" ")])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }