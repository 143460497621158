<template>
  <div
    class="form-part"
    v-if="
      ($route.name === 'brokerReview' &&
        !changesSelected &&
        selected !== null) ||
      (!!changesSelected && selected !== changesSelected) ||
      $route.name !== 'brokerReview'
    "
  >
    <div class="relations__header">
      <div class="relations__label" v-if="label">
        {{ label }}
      </div>
      <span class="relations__desc" v-if="desc">
        {{ desc }}
      </span>
    </div>
    <div class="broker_relations__container">
      <label
        :name="name"
        data-type="currentValue"
        class="broker_relations__block"
      >
        <v-select
          :options="baseBrokerInfoList"
          v-model="selectValue"
          placeholder="None / Enter below"
          :getOptionLabel="(baseBrokerInfoList) => baseBrokerInfoList.name"
          @input="setSelectedData"
          data-type="currentValue"
          style="width: 100%; margin-bottom: 20px"
          v-if="inputValue === ''"
        ></v-select>
        <input
          type="text"
          class="input__field"
          @input="setSelectedData"
          :name="name"
          style="width: 50%"
          v-model="inputValue"
          v-if="selectValue === '' || selectValue === null"
          placeholder="Name"
        />
      </label>
      <Checker
        v-if="$route.name === 'brokerReview' || getSelectedTab.title === 'logs'"
        @set-approve="setApprove"
      />
      <label
        data-type="changesValue"
        class="broker_relations__block"
        v-if="changesSelected !== undefined"
      >
        <v-select
          :options="baseBrokerInfoList"
          v-model="changesSelectedItem"
          placeholder="None / Enter below"
          :getOptionLabel="(baseBrokerInfoList) => baseBrokerInfoList.name"
          :name="name"
          @input="setSelectedData"
          data-type="currentValue"
          style="width: 100%; margin-bottom: 20px"
          v-if="changesInput === ''"
        ></v-select>
        <input
          type="text"
          class="input__field"
          @input="setSelectedData"
          :name="name"
          style="width: 50%"
          v-model="changesInput"
          v-if="changesSelectedItem === '' || changesSelectedItem === null"
        />
      </label>
    </div>
  </div>
</template>

<script>
import { baseBrokerInfoList } from "@/components/graphQL/brokerQueries";
import { mapGetters, mapMutations } from "vuex";
import Checker from "@/components/formFields/Checker.vue";

export default {
  name: "BrokerRalations",
  components: { Checker },

  props: {
    name: {
      type: String,
      required: true,
    },
    label: String,
    desc: String,
    selected: String,
    changesSelected: String,
  },
  apollo: {
    baseBrokerInfoList: baseBrokerInfoList,
  },
  data() {
    return {
      inputValue: "",
      selectValue: "",
      changesInput: "",
      changesSelectedItem: "",

      updatedOld: false,
      updatedNew: false,
      mountedCreating: false,
    };
  },
  computed: {
    ...mapGetters(["getBrokerData", "getCurrentMode", "getSelectedTab"]),
  },
  methods: {
    ...mapMutations([
      "updateBrokerInfo",
      "settingDatatoReviewed",
      "isFormChangedDetect",
    ]),
    setApprove(data) {
      let obj = {
        name: this.name,
        value: [],
      };
      if (data === "false" && !this.changesSelected) {
        obj.value = "";
      } else if (data === "false" && this.changesSelected !== undefined) {
        obj.value = this.selected;
      } else if (data === "true" && this.changesSelected !== undefined) {
        obj.value = this.changesSelected;
      } else if (data === "true" && this.changesSelected === undefined) {
        obj.value = this.selected;
      }
      this.settingDatatoReviewed(obj);
    },
    setSelectedData() {
      let obj = {
        propName: this.name,
        data: this.selectValue
          ? this.selectValue.anchor
          : this.inputValue !== ""
          ? this.inputValue
          : "",
      };
      this.updateBrokerInfo(obj);
      this.isFormChangedDetect(true);
    },
  },
  mounted() {
    if (this.getCurrentMode === "create") {
      this.mountedCreating = true;
    }
  },
  updated() {
    this.$nextTick(function () {
      if (
        (this.selected || this.selected === "") &&
        this.baseBrokerInfoList &&
        !this.updatedOld
      ) {
        let selectedBroker = this.baseBrokerInfoList.find(
          (broker) => broker.anchor === this.selected
        );
        selectedBroker
          ? (this.selectValue = selectedBroker)
          : (this.inputValue = this.selected);
        this.updatedOld = true;
      }
      if (
        (this.changesSelected || this.changesSelected === "") &&
        this.baseBrokerInfoList &&
        !this.updatedNew
      ) {
        let chagesSelectedBroker = this.baseBrokerInfoList.find(
          (broker) => broker.anchor === this.changesSelected
        );
        chagesSelectedBroker
          ? (this.changesSelectedItem = chagesSelectedBroker)
          : (this.changesInput = this.changesSelected);
        this.updatedNew = true;
      }
    });
  },
};
</script>
