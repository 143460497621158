<template>
  <div class="select" :class="halfWidth ? 'width-50' : ''">
    <div class="select__main" :class="inline ? 'select__main--inline' : ''">
      <div class="select__label" v-if="label">
        {{ label }} <span class="input__required" v-if="required">*</span>
      </div>
      <span class="select__desc" v-if="desc">
        {{ desc }}
      </span>
      <div class="select__container">
        <div class="select__inner">
          <v-select
            :options="options"
            v-model="selectedItem"
            :value="selectedItem"
            :multiple="multiple"
            :placeholder="placeholder ? placeholder : 'None / Enter below'"
            :getOptionLabel="optionalLabel"
            :name="name"
            :closeOnSelect="!multiple"
            @input="
              [isFormChangedDetect(true), sortSelected(), setSelectedData()]
            "

            data-type="currentValue"
            :disabled="disabled"
          ></v-select>
        </div>

        <div class="select__inner" v-if="changesSelected !== undefined">
          <v-select
            :options="options"
            v-model="selectedChangesItem"
            :value="selectedChangesItem"
            :multiple="multiple"
            :placeholder="placeholder ? placeholder : 'None / Enter below'"
            :getOptionLabel="optionalLabel"
            :name="name"
            @input="setSelectedData"
            data-type="changesValue"
          ></v-select>
        </div>
      </div>
    </div>
    <Checker v-if="checker" @set-approve="setApprove" />
  </div>
</template>

<script>
import Checker from "@/components/formFields/Checker.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Select",
  props: {
    label: String,
    options: Array,
    checker: {
      type: String,
    },
    halfWidth: {
      type: Boolean,
      default: false,
    },
    selected: [Array, String, Number, Boolean],
    changesSelected: [Array, String, Number, Boolean],
    desc: String,
    inline: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    value: [Array, String, Number, Object],
    optionalLabel: Function,
    name: {
      type: String,
      required: true,
    },
    optionalValue: String,
    placeholder: String,
    required: Boolean,
    disabled: Boolean,
  },
  components: {
    Checker,
  },
  computed: {
    ...mapGetters(["getSelectedTab"]),
  },
  data() {
    return {
      approved: "",
      selectedItem: "",
      selectedChangesItem: "",
      formType: "",
    };
  },
  methods: {
    ...mapMutations([
      "updateBrokerInfo",
      "settingDatatoReviewed",
      "updateSimpleFields",
      "settingAccountTypesDatatoReviewed",
      "isFormChangedDetect",
    ]),
    setApprove(data) {
      let obj = {
        name: this.name,
        value: [],
        reviewed: data,
      };
      if (
        (data === "false" ||
          (data === "unset" && this.getSelectedTab.title === "logs")) &&
        this.changesSelected !== undefined
      ) {
        if (this.multiple && this.selectedItem.length) {
          Array.from(this.selectedItem).forEach((item) => {
            obj.value.push(parseInt(item.id));
          });
        } else {
          obj.value =
            this.selectedItem[this.optionalValue] || this.selectedItem || "";
        }
      } else if (data === "true" && this.changesSelected !== undefined) {
        if (this.multiple && this.selectedChangesItem.length > 0) {
          Array.from(this.selectedChangesItem).forEach((item) => {
            obj.value.push(parseInt(item.id));
          });
        } else {
          obj.value = this.optionalValue
            ? this.selectedChangesItem[this.optionalValue]
            : this.selectedChangesItem;
        }
      } else if (data === "true" && this.changesSelected === undefined) {
        if (this.multiple && this.selectedItem.length) {
          Array.from(this.selectedItem).forEach((item) => {
            obj.value.push(parseInt(item.id));
          });
        } else {
          obj.value =
            this.selectedItem[this.optionalValue] || this.selectedItem || "";
        }
      } else {
        obj.value = this.multiple ? [] : "";
      }
      this.$emit("approved", data);
      this.fromType === "main_data"
        ? this.settingDatatoReviewed(obj)
        : this.fromType === "account_types"
        ? this.settingAccountTypesDatatoReviewed(obj)
        : null;
    },
    setSelectedData() {
      let obj = {};
      let value;
      if (this.selectedItem !== null) {
        if (!this.multiple) {
          value = this.optionalValue
            ? this.selectedItem[this.optionalValue]
            : this.selectedItem;
        } else if (this.multiple && this.selectedItem.length) {
          value = [];
          this.selectedItem.forEach((item) => {
            if (
              this.optionalValue &&
              this.optionalValue.toLowerCase() == "id"
            ) {
              value.push(parseInt(item[this.optionalValue]));
            } else if (this.optionalValue) {
              value.push(item[this.optionalValue]);
            } else {
              value.push(item);
            }
          });
        }
        obj = {
          propName: this.name,
          data:
            this.optionalValue === "id" && !this.multiple
              ? parseInt(value)
              : value,
          multiple: this.multiple,
        };
      } else {
        obj = {
          propName: this.name,
          data: this.multiple ? [] : "",
          multiple: this.multiple,
        };
      }

      this.fromType === "main_data"
        ? this.updateBrokerInfo(obj)
        : this.fromType === "account_types"
        ? this.updateSimpleFields(obj)
        : null;
    },
    removeItem(id) {
      this.selectedItem.splice(id, 1);
    },
    sortSelected() {
      if (this.multiple) {
        this.selectedItem = this.selectedItem.sort(function (a, b) {
          if (a["name"].toLowerCase() > b["name"].toLowerCase()) {
            return 1;
          }
          if (a["name"].toLowerCase() < b["name"].toLowerCase()) {
            return -1;
          }
          return 0;
        });
      }
    },
  },
  mounted() {
    if (this.selected) {
      if (typeof this.selected == "object") {
        this.selectedItem = this.selected;
      } else if (
        typeof this.selected == "string" &&
        this.optionalValue != undefined
      ) {
        let selectedValue = this.options.filter(
          (item) =>
            item[this.optionalValue].toLowerCase() ==
            this.selected.toLowerCase()
        );
        if (selectedValue == "") {
          this.selectedItem == "";
        }

        this.options.forEach((item) => {
          if (
            item[this.optionalValue] &&
            item[this.optionalValue].toLowerCase() ==
              this.selected.toLowerCase()
          ) {
            this.selectedItem = item;
          }
        });
      } else if (
        typeof this.selected == "string" &&
        this.optionalValue == undefined
      ) {
        this.selectedItem = this.selected;
      }
    }
    if (this.changesSelected !== undefined) {
      if (typeof this.changesSelected == "object") {
        this.selectedChangesItem = this.changesSelected;
      } else if (
        typeof this.changesSelected == "string" &&
        this.optionalValue != undefined
      ) {
        let selectedValue = this.options.filter(
          (item) =>
            item[this.optionalValue].toLowerCase() ==
            this.changesSelected.toLowerCase()
        );
        if (selectedValue == "") {
          this.selectedChangesItem == "";
        }

        this.options.forEach((item) => {
          if (
            item[this.optionalValue] &&
            this.changesSelected !== undefined &&
            item[this.optionalValue].toLowerCase() ==
              this.changesSelected.toLowerCase()
          ) {
            this.selectedChangesItem = item;
          }
        });
      } else if (
        this.changesSelected !== undefined &&
        typeof this.changesSelected == "string" &&
        this.optionalValue == undefined
      ) {
        this.selectedChangesItem = this.changesSelected;
      }
    }

    this.fromType = this.$el.closest("form").getAttribute("id");

    this.getSelectedTab.title !== "logs" ? this.setSelectedData() : null;
  },
  watch: {
    selected: function () {
      if (typeof this.selected == "object") {
        this.selectedItem = this.selected;
      } else if (
        typeof this.selected == "string" &&
        this.optionalValue !== undefined
      ) {
        let selectedValue = this.options.filter(
          (item) =>
            item[this.optionalValue].toLowerCase() ===
            this.selected.toLowerCase()
        );
        if (selectedValue === "") {
          this.selectedItem === "";
        }

        this.options.forEach((item) => {
          if (
            item[this.optionalValue] &&
            item[this.optionalValue].toLowerCase() ===
              this.selected.toLowerCase()
          ) {
            this.selectedItem = item;
          }
        });
      } else if (
        typeof this.selected == "string" &&
        this.optionalValue === undefined
      ) {
        this.selectedItem = this.selected;
      }
    },
    changesSelected: function () {
      if (typeof this.changesSelected == "object") {
        this.selectedChangesItem = this.changesSelected;
      } else if (
        typeof this.changesSelected == "string" &&
        this.optionalValue != undefined
      ) {
        let selectedValue = this.options.filter(
          (item) =>
            item[this.optionalValue].toLowerCase() ==
            this.changesSelected.toLowerCase()
        );
        if (selectedValue == "") {
          this.selectedChangesItem == "";
        }

        this.options.forEach((item) => {
          if (
            item[this.optionalValue] &&
            item[this.optionalValue].toLowerCase() ==
              this.changesSelected.toLowerCase()
          ) {
            this.selectedChangesItem = item;
          }
        });
      } else if (
        typeof this.changesSelected == "string" &&
        this.optionalValue == undefined
      ) {
        this.selectedChangesItem = this.changesSelected;
      }
    },
    selectedItem: function () {
      this.$emit("input", this.selectedItem, this.checker);
    },
  },
};
</script>
