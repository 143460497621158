var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-part"},[(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.anchor,
            _vm.brokerChanges ? _vm.brokerChanges.anchor : null
          )
        : true
    )?_c('Input',{attrs:{"required":"","label":"Anchor","showLinkToContentSite":"","name":"anchor","value":_vm.brokerData.anchor || _vm.anchorExist,"changesValue":_vm.brokerChanges ? _vm.brokerChanges.anchor : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null,"id":"anchor","disabled":_vm.$route.name !== 'brokerCreate' && !_vm.isAdmin,"error":_vm.anchorExist ? 'this anchor already exist, input another' : null}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.foundedYear,
            _vm.brokerChanges ? _vm.brokerChanges.foundedYear : null
          )
        : true
    )?_c('Input',{attrs:{"label":"Founded in","halfWidth":"","name":'foundedYear',"type":"number","value":_vm.brokerData.foundedYear,"changesValue":_vm.brokerChanges ? _vm.brokerChanges.foundedYear : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null,"maxLength":"4","error":"max length of year is 4"}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.onlineSinceYear,
            _vm.brokerChanges ? _vm.brokerChanges.onlineSinceYear : null
          )
        : true
    )?_c('Input',{attrs:{"label":"Online since","halfWidth":"","name":'onlineSinceYear',"type":"number","value":_vm.brokerData.onlineSinceYear,"changesValue":_vm.brokerChanges ? _vm.brokerChanges.onlineSinceYear : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null,"maxLength":"4","error":"max length of year is 4"}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.contactsForAdmin,
            _vm.brokerChanges ? _vm.brokerChanges.contactsForAdmin : null
          )
        : true
    )?_c('Textarea',{attrs:{"label":"Broker's contact details for Admins","inline":"","name":'contactsForAdmin',"value":_vm.brokerData.contactsForAdmin,"changesValue":_vm.brokerChanges ? _vm.brokerChanges.contactsForAdmin : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }