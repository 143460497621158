<template>
  <fieldset name="historyItem" :id="id" class="history_item">
    <div class="history_item__container">
      <div class="history_item__wrap" data-type="currentValue" :id="id">
        <v-date-picker
          v-model="fullDate"
          :masks="{ input: 'YYYY-MM-DD' }"
          :model-config="modelConfig"
          is-required
          :popover="{ placement: 'bottom', visibility: 'click' }"
        >
          >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="history_item__date"
              v-on="inputEvents"
              mode="date"
              :value="inputValue"
              placeholder="Date"
              name="date"
              @input="setItem(), isFormChangedDetect(true)"
              @keydown="preventEnterPress($event)"
            />
          </template>
        </v-date-picker>
        <input
          type="text"
          class="history_item__text"
          v-model="historyItem.text"
          name="text"
          @input="setItem(), isFormChangedDetect(true)"
        />
      </div>
      <div
        class="history_item__wrap"
        data-type="changesValue"
        v-if="changesData !== undefined"
      >
        <v-date-picker
          v-model="changesFullDate"
          :masks="{ input: 'YYYY-MM-DD' }"
          :model-config="modelConfig"
          :popover="{ placement: 'bottom', visibility: 'click' }"
        >
          >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="history_item__date"
              v-on="inputEvents"
              mode="date"
              :value="inputValue"
              placeholder="Date"
              name="date"
            />
          </template>
        </v-date-picker>
        <input
          type="text"
          class="history_item__text"
          v-model="changesHistoryItem.text"
          name="text"
        />
      </div>
    </div>

    <button
      type="button"
      class="history_item__remove"
      @click.prevent="removeHistoryItem(historyItem), isFormChangedDetect(true)"
      v-if="!checker"
    ></button>
    <Checker v-if="checker" />
  </fieldset>
</template>

<script>
import { mapMutations } from "vuex";
import Checker from "@/components/formFields/Checker.vue";

export default {
  name: "HistoryItem",
  props: {
    item: Object,
    changesItem: Object,
    lang: String,
    data: Object,
    changesData: Object,
    parentId: Number,
    id: String,
    checker: String,
  },
  components: {
    Checker,
  },
  data() {
    return {
      fullDate: "",
      changesFullDate: "",
      historyItem: {
        date: "",
        text: "",
        id: this.id,
        lang: this.lang,
      },
      changesHistoryItem: {
        date: "",
        text: "",
        id: this.id,
        lang: this.lang,
      },
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
    };
  },
  methods: {
    ...mapMutations([
      "removeHistoryItem",
      "settingDatatoReviewed",
      "setHistoryItem",
      "isFormChangedDetect",
    ]),
    setItem() {
      if (this.historyItem.data !== "" && this.historyItem.date !== "") {
        this.setHistoryItem(this.historyItem);
        this.isFormChangedDetect(true);
      }
    },
    preventEnterPress(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    setApprove(data) {
      let obj =
        data === "false"
          ? this.historyItem
          : data === "true"
          ? this.changesHistoryItem
          : "";
      obj.type = "brokerHistory";
      this.settingDatatoReviewed(obj);
    },
  },
  watch: {
    fullDate: function () {
      this.historyItem.date = this.fullDate;
    },
    changesFullDate: function () {
      this.changesHistoryItem.date = this.changesFullDate;
    },
  },
  created() {
    if (this.data) {
      this.historyItem.date = this.fullDate = this.data.date;
      this.historyItem.text = this.data.text;
    }

    if (this.changesData !== undefined) {
      this.changesHistoryItem.date = this.changesFullDate =
        this.changesData.date;
      this.changesHistoryItem.text = this.changesData.text;
    }
  },
};
</script>
