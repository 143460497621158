<template>
  <fieldset name="demoPlatform" class="demo_platform__container" :id="id">
    <div class="demo_platform__wrap" data-type="currentValue" :id="id">
      <div class="demo_platform__main">
        <v-select
          :options="tradingPlatforms"
          v-model="selectedPlatform"
          placeholder="Select the trading platform"
          :getOptionLabel="(option) => option.name"
          style="width: 100%"
          :clearable="false"
          @input="isFormChangedDetect(true)"
        ></v-select>
        <input
          type="hidden"
          name="selectedPlatform"
          :value="selectedPlatform ? selectedPlatform.id : ''"
        />
        <button
          v-if="canBeDeleted"
          type="button"
          class="demo_platform__remove"
          @click.prevent="removeDemoPlatform(id), isFormChangedDetect(true)"
        ></button>
      </div>
      <v-select
        :options="conditionOptions"
        v-model="selectedCondition"
        :reduce="(conditionOptions) => conditionOptions.val"
        placeholder="None / Enter below"
        :getOptionLabel="(option) => option.name"
        optionalValue="val"
        :clearable="false"
        @input="isFormChangedDetect(true)"
        v-if="selectedPlatform"
      ></v-select>
      <input
        type="hidden"
        name="selectedCondition"
        :value="selectedCondition"
      />
      <input
        v-model="demoDays"
        class="input__field"
        v-if="
          selectedCondition === 'LIMITED_DAYS' ||
          selectedCondition === 'LIMITED_DAYS_INACTIVITY' ||
          (platformData !== undefined &&
            platformData.demoTradingPlatformConditionDays)
        "
        maxLength="10"
        type="number"
        :class="
          demoDays !== null &&
          (demoDays.length > 10 || demoDays.length <= 0) &&
          $route.name !== 'brokerReview'
            ? '--error'
            : ''
        "
        @input="isFormChangedDetect(true)"
      />
      <input
        type="hidden"
        name="demoTradingPlatformConditionDays"
        :value="demoDays"
      />
    </div>
    <Checker v-if="checker" />
    <div
      class="demo_platform__wrap"
      data-type="changesValue"
      v-if="changesData !== undefined"
    >
      <div class="demo_platform__main">
        <v-select
          :options="tradingPlatforms"
          v-model="changesSelectedPlatform"
          :placeholder="'Select your option'"
          :getOptionLabel="(option) => option.name"
          style="width: 100%"
          :clearable="false"
        ></v-select>
        <input
          type="hidden"
          name="selectedPlatform"
          :value="changesSelectedPlatform ? changesSelectedPlatform.id : ''"
        />
      </div>
      <v-select
        :options="conditionOptions"
        v-model="changesSelectedCondition"
        :reduce="(conditionOptions) => conditionOptions.val"
        placeholder="None / Enter below"
        :getOptionLabel="(option) => option.name"
        optionalValue="val"
        :clearable="false"
      ></v-select>
      <input
        type="hidden"
        name="selectedCondition"
        :value="changesSelectedCondition"
      />
      <input
        name="demoTradingPlatformConditionDays"
        v-model="changesDemoDays"
        class="input__field"
        v-if="
          changesSelectedCondition === 'LIMITED_DAYS' ||
          changesSelectedCondition === 'LIMITED_DAYS_INACTIVITY' ||
          (changesData !== undefined &&
            changesData.demoTradingPlatformConditionDays)
        "
        maxLength="10"
        type="number"
        :class="
          changesDemoDays &&
          changesDemoDays.length > 10 &&
          $route.name !== 'brokerReview'
            ? '--error'
            : ''
        "
      />
    </div>
  </fieldset>
</template>

<script>
import Checker from "@/components/formFields/Checker.vue";
import { tradingPlatforms } from "@/components/graphQL/brokerQueries";
import { mapMutations } from "vuex";

export default {
  name: "DemoPlatformContainer",
  components: { Checker },
  props: {
    id: [String, Number],
    platformData: Object,
    changesData: Object,
    checker: String,
    canBeDeleted: Boolean,
  },
  data() {
    return {
      selectedPlatform: null,
      selectedCondition: "YES",
      demoDays: null,
      updatedDays: false,
      conditionOptions: [
        {
          val: "YES",
          name: "Unlimited",
        },
        {
          val: "COMING_SOON",
          name: "Coming soon",
        },
        {
          val: "LIMITED_DAYS_INACTIVITY",
          name: "Limited for N days of inactivity",
        },
        {
          val: "LIMITED_DAYS",
          name: "Limited for N days",
        },
        {
          val: "FOR_REAL_HOLDERS",
          name: "Only for real account holders",
        },
      ],
      changesSelectedPlatform: null,
      changesSelectedCondition: "YES",
      changesDemoDays: null,
      updated: false,
    };
  },
  apollo: {
    tradingPlatforms: tradingPlatforms,
  },
  methods: {
    ...mapMutations(["removeDemoPlaform", "isFormChangedDetect"]),
    removeDemoPlatform(id) {
      this.removeDemoPlaform(id);
      this.$emit("demoPlatformRemoved");
    },
  },
  updated() {
    this.$nextTick(function () {
      if (
        !this.selectedPlatform &&
        this.tradingPlatforms &&
        this.platformData !== undefined
      ) {
        this.selectedPlatform = this.tradingPlatforms.find(
          (item) =>
            parseInt(item.id) ===
            parseInt(this.platformData.demoTradingPlatformId)
        );
      }
      if (
        !this.selectedCondition ||
        (!this.updated && this.platformData !== undefined)
      ) {
        this.selectedCondition = this.conditionOptions.find(
          (item) =>
            item.val ===
            this.platformData.demoTradingPlatformCondition.toUpperCase()
        ).val;
        this.updated = true;
      }
      if (
        !this.demoDays &&
        this.platformData !== undefined &&
        !this.updatedDays
      ) {
        this.demoDays =
          this.platformData.demoTradingPlatformConditionDays || null;
        this.updatedDays = true;
      }

      if (
        !this.changesSelectedPlatform &&
        this.tradingPlatforms &&
        this.changesData !== undefined
      ) {
        this.changesSelectedPlatform = this.tradingPlatforms.find(
          (item) =>
            parseInt(item.id) ===
            parseInt(this.changesData.demoTradingPlatformId)
        );
      }
      if (
        !this.changesSelectedCondition ||
        (this.changesSelectedCondition === "YES" &&
          this.changesData !== undefined)
      ) {
        this.changesSelectedCondition = this.conditionOptions.find(
          (item) =>
            item.val ===
            this.changesData.demoTradingPlatformCondition.toUpperCase()
        ).val;
      }
      if (!this.changesDemoDays && this.changesData !== undefined) {
        this.changesDemoDays =
          this.changesData.demoTradingPlatformConditionDays || null;
      }
    });
  },
};
</script>
