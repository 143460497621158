<template>
  <div class="form-part">
    <Radio
      label="Autochartist"
      :values="radioSelections"
      :name="'autochartist'"
      :selected="brokerData ? brokerData.autochartist : ''"
      :changesSelected="brokerChanges ? brokerChanges.autochartist : undefined"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.autochartist,
              brokerChanges ? brokerChanges.autochartist : null
            )
          : true
      "
    />
    <Radio
      label="Bully"
      :values="radioSelections"
      :name="'bully'"
      :selected="brokerData ? brokerData.bully : ''"
      :changesSelected="brokerChanges ? brokerChanges.bully : undefined"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.bully,
              brokerChanges ? brokerChanges.bully : null
            ) && isAdmin
          : isAdmin && $route.name !== 'brokerReview'
      "
    />
    <Radio
      label="Scam"
      :values="radioSelections"
      :name="'scam'"
      :selected="brokerData ? brokerData.scam : ''"
      :changesSelected="brokerChanges ? brokerChanges.scam : undefined"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.scam,
              brokerChanges ? brokerChanges.scam : null
            ) && isAdmin
          : isAdmin && $route.name !== 'brokerReview'
      "
    />
  </div>
</template>

<script>
import Radio from "@/components/formFields/Radio";
import CompairingService from "@/services/CompairingService";
import { mapGetters } from "vuex";

export default {
  name: "BroekrFlags",
  components: {
    Radio
  },
  props: {
    brokerData: Object,
    brokerChanges: Object,
    isAdmin: { type: Boolean, default: false }
  },
  data() {
    return {
      radioSelections: [
        { label: "No", value: false },
        { label: "Yes", value: true }
      ],
      CompairingService: new CompairingService()
    };
  },

  computed: {
    ...mapGetters(["getSelectedTab"])
  }
};
</script>
