class OpenMultipleLinks {
  showAll(array) {
    array.forEach((item) => {
      if (item.value !== "") {
        window.open(
          item.value.startsWith("https://") ||
            item.value.startsWith("http://") ||
            item.value.startsWith("www.")
            ? item.value
            : "https://" + item.value,
          "_blank"
        );
      }
    });
  }
}

export default OpenMultipleLinks;
