<template>
  <div class="add-favicon">
    <div class="add-favicon__label">Broker`s website favicon</div>

    <form
      @submit.prevent="uploadFavicon"
      method="POST"
      enctype="multipart/form-data"
    >
      <label :class="['btn', { '--disabled': loading }]">
        <input
          accept="image/png, image/jpeg, image/jpg"
          type="file"
          name="file"
          @change="uploadFavicon"
        />
        <span>Upload favicon</span>
      </label>
    </form>

    <div v-if="fileName" class="site-preview__created">
      File name: {{ fileName }}
    </div>
    <div v-if="errorMsg" class="site-preview__error">{{ errorMsg }}</div>
    <div v-if="loading" class="site-preview__loader">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>

    <div v-if="imageUrl" class="site-favicon">
      <img :src="imageUrl" alt="" width="32" height="32" />
      <button
        v-if="
          !imageUrl.startsWith('https://www.google.com/s2/favicons?domain=')
        "
        @click="removeFavicon"
        class="site-preview__remove-image"
      ></button>
    </div>
  </div>
</template>

<script>
import { updateFavicon } from "@/components/graphQL/brokerMutations";

export default {
  name: "SiteFavicon",
  props: {
    iconUrl: String,
  },

  data() {
    return {
      loading: false,
      errorMsg: null,
      fileName: null,
      imageUrl: null,
    };
  },
  created() {
    if (this.iconUrl) this.imageUrl = this.iconUrl;
  },
  methods: {
    uploadFavicon(event) {
      const fileData = event.target.files[0];
      if (!fileData) return false;
      this.errorMsg = null;
      this.fileName = null;
      this.imageUrl = null;
      this.loading = true;

      try {
        this.$apollo
          .mutate({
            mutation: updateFavicon,
            variables: {
              iconFile: fileData,
              broker_id: this.$route.params.id,
            },
            context: {
              hasUpload: true,
            },
          })
          .then(({ data }) => {
            if (
              data.updateFavicon.message === "Icon file uploaded successfully."
            ) {
              this.imageUrl = data.updateFavicon.url;
              this.fileName = fileData ? fileData.name : null;
            } else {
              this.errorMsg = `Error: ${data.updateFavicon.message}!`;
            }
          })
          .catch((error) => {
            console.error(error);
            this.errorMsg = "Something went wrong while uploading the file!";
          })
          .finally(() => {
            this.loading = false;
          });
      } catch (e) {
        this.loading = false;
        this.errorMsg = `Server error: ${this.parseDate()}`;
        console.log(e);
      }
    },
    removeFavicon() {
      this.loading = true;
      try {
        this.$apollo
          .mutate({
            mutation: updateFavicon,
            variables: {
              delete: true,
              broker_id: this.$route.params.id,
            },
            context: {
              hasUpload: true,
            },
          })
          .then(({ data }) => {
            if (data.updateFavicon.message === "Deleted.") {
              this.imageUrl = data.updateFavicon.url;
            } else {
              this.errorMsg = `Error: ${data.updateFavicon.message}!`;
            }
          })
          .catch((error) => {
            console.error(error);
            this.errorMsg = "Something went wrong while deleting the file!";
          })
          .finally(() => {
            this.loading = false;
          });
      } catch (e) {
        this.loading = false;
        this.errorMsg = `Server error`;
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss">
.add-favicon {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 35px;
  padding-bottom: 35px;
  border-bottom: 1px solid var(--success);

  &__label {
    width: 100%;
    max-width: 155px;
  }

  input[type="file"] {
    display: none;
  }
}

.site-favicon {
  width: 32px;
  height: 32px;
  position: relative;
}
</style>
