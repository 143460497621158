<template>
  <div class="form-part">
    <Input
      required
      label="Anchor"
      showLinkToContentSite
      name="anchor"
      :value="brokerData.anchor || anchorExist"
      :changesValue="brokerChanges ? brokerChanges.anchor : undefined"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.anchor,
              brokerChanges ? brokerChanges.anchor : null
            )
          : true
      "
      id="anchor"
      :disabled="$route.name !== 'brokerCreate' && !isAdmin"
      :error="anchorExist ? 'this anchor already exist, input another' : null"
    />
    <Input
      label="Founded in"
      halfWidth
      :name="'foundedYear'"
      type="number"
      :value="brokerData.foundedYear"
      :changesValue="brokerChanges ? brokerChanges.foundedYear : undefined"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.foundedYear,
              brokerChanges ? brokerChanges.foundedYear : null
            )
          : true
      "
      maxLength="4"
      error="max length of year is 4"
    />
    <Input
      label="Online since"
      halfWidth
      :name="'onlineSinceYear'"
      type="number"
      :value="brokerData.onlineSinceYear"
      :changesValue="brokerChanges ? brokerChanges.onlineSinceYear : undefined"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.onlineSinceYear,
              brokerChanges ? brokerChanges.onlineSinceYear : null
            )
          : true
      "
      maxLength="4"
      error="max length of year is 4"
    />
    <Textarea
      label="Broker's contact details for Admins"
      inline
      :name="'contactsForAdmin'"
      :value="brokerData.contactsForAdmin"
      :changesValue="brokerChanges ? brokerChanges.contactsForAdmin : undefined"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.contactsForAdmin,
              brokerChanges ? brokerChanges.contactsForAdmin : null
            )
          : true
      "
    />
  </div>
</template>

<script>
import Input from "@/components/formFields/Input";
import Textarea from "@/components/formFields/Textarea";
import CompairingService from "@/services/CompairingService";
import { mapGetters } from "vuex";
export default {
  name: "aboutBroker",
  components: {
    Input,
    Textarea,
  },
  props: {
    brokerData: Object,
    brokerChanges: Object,
    anchorExist: String,
  },
  data() {
    return {
      CompairingService: new CompairingService(),
      isAdmin: false,
    };
  },
  computed: {
    ...mapGetters(["getSelectedTab"]),
  },
  mounted() {
    this.isAdmin = localStorage.getItem("Mode") === "manager";
  },
};
</script>
