<template>
  <div class="form-part account_trading_platform">
    <Select
      :optionalLabel="(option) => option.name"
      :options="tradingPlatforms"
      label="Trading platforms"
      optionalValue="id"
      name="tradingPlatforms"
      :selected="accountTypeData ? accountTypeData.tradingPlatforms : ''"
      :changesSelected="
        accountTypeChanges ? accountTypeChanges.tradingPlatforms : undefined
      "
      multiple
      required
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              accountTypeData.tradingPlatforms
                ? accountTypeData.tradingPlatforms
                : [],
              accountTypeChanges ? accountTypeChanges.tradingPlatforms : null
            )
          : true
      "
    />
    <Input
      label="How many currency pairs?"
      column
      name="currencyPairsQuantity"
      type="number"
      :value="accountTypeData.currencyPairsQuantity"
      :changesValue="
        accountTypeChanges
          ? accountTypeChanges.currencyPairsQuantity
          : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              accountTypeData.currencyPairsQuantity
                ? accountTypeData.currencyPairsQuantity
                : null,
              accountTypeChanges
                ? accountTypeChanges.currencyPairsQuantity
                : null
            )
          : true
      "
    />
    <Input
      label="Of which exotic:"
      column
      name="exoticCurrencyPairsQuantity"
      type="number"
      :value="accountTypeData.exoticCurrencyPairsQuantity"
      :changesValue="
        accountTypeChanges
          ? accountTypeChanges.exoticCurrencyPairsQuantity
          : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              accountTypeData.exoticCurrencyPairsQuantity
                ? accountTypeData.exoticCurrencyPairsQuantity
                : null,
              accountTypeChanges
                ? accountTypeChanges.exoticCurrencyPairsQuantity
                : null
            )
          : true
      "
    />
  </div>
</template>
<script>
import Select from "@/components/formFields/Select";
import Input from "@/components/formFields/Input";
import { tradingPlatforms } from "@/components/graphQL/brokerQueries";

import CompairingService from "@/services/CompairingService";
import { mapGetters } from "vuex";

export default {
  name: "AccountTradingPlatform",
  components: {
    Select,
    Input,
  },
  apollo: {
    tradingPlatforms: tradingPlatforms,
  },
  props: {
    accountTypeData: Object,
    accountTypeChanges: Object,
  },
  data() {
    return {
      CompairingService: new CompairingService(),
    };
  },
  computed: {
    ...mapGetters(["getSelectedTab"]),
  },
};
</script>
