<template>
  <div class="form-part form-part--account_types_main">
    <Input
      label="Commission per 1 std. lot"
      name="commission"
      type="number"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      :value="accountTypeData.commission"
      :changesValue="
        accountTypeChanges ? accountTypeChanges.commission : undefined
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              accountTypeData && accountTypeData.commission
                ? accountTypeData.commission
                : null,
              accountTypeChanges && accountTypeChanges.commission
                ? accountTypeChanges.commission
                : null
            )
          : true
      "
    />
    <Input
      label="Commission for CFD per 1 std. lot:"
      name="commissionCfd"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      :value="accountTypeData.commissionCfd"
      type="number"
      :changesValue="
        accountTypeChanges ? accountTypeChanges.commissionCfd : undefined
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              accountTypeData && accountTypeData.commissionCfd
                ? accountTypeData.commissionCfd
                : null,
              accountTypeChanges && accountTypeChanges.commissionCfd
                ? accountTypeChanges.commissionCfd
                : null
            )
          : true
      "
    />
    <Input
      label="Margin call level (in %):"
      name="marginCallLevel"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      :value="accountTypeData.marginCallLevel"
      type="number"
      :changesValue="
        accountTypeChanges ? accountTypeChanges.marginCallLevel : undefined
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              accountTypeData && accountTypeData.marginCallLevel
                ? accountTypeData.marginCallLevel
                : null,
              accountTypeChanges && accountTypeChanges.marginCallLevel
                ? accountTypeChanges.marginCallLevel
                : null
            )
          : true
      "
    />
    <Input
      label="Stop-out level (in %):"
      name="stopOutLevel"
      type="number"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      :value="accountTypeData.stopOutLevel"
      :changesValue="
        accountTypeChanges ? accountTypeChanges.stopOutLevel : undefined
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              accountTypeData && accountTypeData.stopOutLevel
                ? accountTypeData.stopOutLevel
                : null,
              accountTypeChanges && accountTypeChanges.stopOutLevel
                ? accountTypeChanges.stopOutLevel
                : null
            )
          : true
      "
    />
    <Input
      label="Minimum distance to stop orders"
      name="minDistanceToStopOrders"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      :value="accountTypeData.minDistanceToStopOrders"
      type="number"
      :changesValue="
        accountTypeChanges
          ? accountTypeChanges.minDistanceToStopOrders
          : undefined
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              accountTypeData && accountTypeData.minDistanceToStopOrders
                ? accountTypeData.minDistanceToStopOrders
                : null,
              accountTypeChanges && accountTypeChanges.minDistanceToStopOrders
                ? accountTypeChanges.minDistanceToStopOrders
                : null
            )
          : true
      "
    />
    <Input
      label="Minimum distance to limit orders:"
      name="minDistanceToLimitOrders"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      :value="accountTypeData.minDistanceToLimitOrders"
      type="number"
      :changesValue="
        accountTypeChanges
          ? accountTypeChanges.minDistanceToLimitOrders
          : undefined
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              accountTypeData && accountTypeData.minDistanceToLimitOrders
                ? accountTypeData.minDistanceToLimitOrders
                : null,
              accountTypeChanges && accountTypeChanges
                ? accountTypeChanges.minDistanceToLimitOrders
                : null
            )
          : true
      "
    />
    <Input
      label="Maximum Number of Simultaneous Positions Allowed:"
      name="maxPositionsSimultaneous"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      :value="accountTypeData.maxPositionsSimultaneous"
      type="number"
      :changesValue="
        accountTypeChanges
          ? accountTypeChanges.maxPositionsSimultaneous
          : undefined
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              accountTypeData && accountTypeData.maxPositionsSimultaneous
                ? accountTypeData.maxPositionsSimultaneous
                : null,
              accountTypeChanges && accountTypeChanges
                ? accountTypeChanges.maxPositionsSimultaneous
                : null
            )
          : true
      "
    />
  </div>
</template>
<script>
import Input from "@/components/formFields/Input";
import CompairingService from "@/services/CompairingService";
import { mapGetters } from "vuex";

export default {
  name: "AccountTypeFinancial",
  components: { Input },
  props: {
    accountTypeData: Object,
    accountTypeChanges: Object,
    anchorExist: Boolean,
  },
  data() {
    return {
      CompairingService: new CompairingService(),
    };
  },
  computed: {
    ...mapGetters(["getSelectedTab"]),
  },
};
</script>
