var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fieldset',{staticClass:"currency_pair",attrs:{"name":"currency_pair","id":_vm.id}},[_c('div',{staticClass:"double_inputs__wrapper"},[_c('strong',{staticClass:"input__label"},[_vm._v(" Currency pair * ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pairInfo.nameCurrencyPair),expression:"pairInfo.nameCurrencyPair"}],staticClass:"checkbox__input",attrs:{"placeholder":"Enter currency pair","name":"nameCurrencyPair","data-type":"currentValue","required":true,"disabled":_vm.disabled},domProps:{"value":(_vm.pairInfo.nameCurrencyPair)},on:{"blur":function($event){_vm.updateSingleCurrencyPair(_vm.pairInfo), _vm.isFormChangedDetect(true)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.pairInfo, "nameCurrencyPair", $event.target.value)}}})]),(_vm.pairInfo.nameCurrencyPair)?_c('div',[_c('Radio',{attrs:{"column":"","label":"Units","values":[
        {
          value: "PIPS",
          label: 'Pips ',
        },
        {
          value: "ABSOLUT",
          label: 'Absolute',
        } ],"name":(_vm.uniqName + "-typeSpread"),"checker":null,"selected":_vm.value ? _vm.value.typeSpread : undefined,"changesSelected":_vm.changedValue ? _vm.changedValue.typeSpread : undefined},on:{"change":function($event){return _vm.updateSingleCurrencyPair(_vm.pairInfo)}},model:{value:(_vm.pairInfo.typeSpread),callback:function ($$v) {_vm.$set(_vm.pairInfo, "typeSpread", $$v)},expression:"pairInfo.typeSpread"}})],1):_vm._e(),(_vm.pairInfo.nameCurrencyPair)?_c('div',[_c('Radio',{attrs:{"column":"","label":"Spread type","values":[
        {
          value: "FIXED",
          label: 'Fixed',
        },
        {
          value: "VARIABLE",
          label: 'Variable',
        } ],"name":(_vm.uniqName + "-kindSpread"),"changesSelected":_vm.changedValue ? _vm.changedValue.kindSpread : undefined,"checker":null,"selected":_vm.value ? _vm.value.kindSpread : undefined},on:{"change":function($event){return _vm.updateSingleCurrencyPair(_vm.pairInfo)}},model:{value:(_vm.pairInfo.kindSpread),callback:function ($$v) {_vm.$set(_vm.pairInfo, "kindSpread", $$v)},expression:"pairInfo.kindSpread"}})],1):_vm._e(),(_vm.pairInfo.nameCurrencyPair)?_c('div',{staticClass:"double_inputs__wrapper"},[_c('strong',{staticClass:"input__label"},[_vm._v(" Spread * ")]),_c('div',{staticClass:"double_inputs"},[(
          _vm.pairInfo.kindSpread &&
          _vm.pairInfo.kindSpread.toUpperCase() === 'VARIABLE'
        )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pairInfo.lowestSpread),expression:"pairInfo.lowestSpread"}],staticClass:"checkbox__input",attrs:{"placeholder":"Lowest","name":"pairLowestSpread","data-type":"currentValue","data-id":_vm.pairInfo.id,"required":true},domProps:{"value":(_vm.pairInfo.lowestSpread)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.pairInfo, "lowestSpread", $event.target.value)},function($event){return _vm.isFormChangedDetect(true)}],"blur":function($event){return _vm.updateSingleCurrencyPair(_vm.pairInfo)}}}):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pairInfo.typicalSpread),expression:"pairInfo.typicalSpread"}],staticClass:"checkbox__input",attrs:{"placeholder":_vm.pairInfo.kindSpread === 'VARIABLE' ? 'Typical' : '',"name":"pairTypicalSpread","data-type":"currentValue","data-id":_vm.pairInfo.id,"required":_vm.$route.name !== 'brokerReview' && _vm.getSelectedTab.title !== 'logs'},domProps:{"value":(_vm.pairInfo.typicalSpread)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.pairInfo, "typicalSpread", $event.target.value)},function($event){return _vm.isFormChangedDetect(true)}],"blur":function($event){return _vm.updateSingleCurrencyPair(_vm.pairInfo)}}})]),(
        (_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs') &&
        _vm.changedValue
      )?_c('div',{staticClass:"double_inputs"},[(
          _vm.changedValue.kindSpread &&
          _vm.changedValue.kindSpread.toUpperCase() === 'VARIABLE'
        )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.changedPairInfo.lowestSpread),expression:"changedPairInfo.lowestSpread"}],staticClass:"checkbox__input",attrs:{"placeholder":"Lowest","name":"pairLowestSpread","data-type":"changesValue","data-id":_vm.changedPairInfo.id},domProps:{"value":(_vm.changedPairInfo.lowestSpread)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.changedPairInfo, "lowestSpread", $event.target.value)},function($event){return _vm.isFormChangedDetect(true)}]}}):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.changedPairInfo.typicalSpread),expression:"changedPairInfo.typicalSpread"}],staticClass:"checkbox__input",attrs:{"placeholder":"Typical","name":"pairTypicalSpread","data-type":"changesValue","data-id":_vm.changedPairInfo.id},domProps:{"value":(_vm.changedPairInfo.typicalSpread)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.changedPairInfo, "typicalSpread", $event.target.value)},function($event){return _vm.isFormChangedDetect(true)}]}})]):_vm._e()]):_vm._e(),(!_vm.checker && !_vm.disabled)?_c('button',{staticClass:"history_item__remove",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.deletePair.apply(null, arguments)}}}):_vm._e(),_c('input',{attrs:{"type":"hidden","name":"currency_pair_data","id":_vm.value.id || _vm.changedValue.id},domProps:{"value":JSON.stringify(_vm.pairInfo)}}),(_vm.changedValue)?_c('input',{attrs:{"type":"hidden","name":"changed_currency_pair_data","id":_vm.value.id || _vm.changedValue.id},domProps:{"value":JSON.stringify(_vm.changedPairInfo)}}):_vm._e(),(_vm.checker)?_c('Checker'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }