<template>
  <fieldset name="company_item" class="company_block" :id="source.id">
    <input type="hidden" name="languageSlug" :value="language" />

    <div
      data-type="currentValue"
      class="company_block__content"
      :id="source.id"
    >
      <Input label="Company" name="name" :value="source.name || ''" />
      <Input label="Ticker symbol" name="ticker" :value="source.ticker" />
      <Input
        label="Ticker link"
        name="linkTicker"
        type="url"
        :value="source.linkTicker"
        showLink
      />
      <input type="hidden" :value="source.id" name="id" />
    </div>
    <div
      data-type="changesValue"
      class="company_block__content"
      v-if="changedSource !== undefined"
      :id="changedSource.id"
    >
      <Input label="Company" :name="'name'" :value="changedSource.name" />
      <Input
        label="Ticker symbol"
        name="ticker"
        :value="changedSource.ticker"
      />
      <Input
        label="Ticker link"
        name="linkTicker"
        type="url"
        :value="changedSource.linkTicker"
        showLink
      />
      <input type="hidden" :value="changedSource.id" name="id" />
    </div>

    <button
      type="button"
      class="history_item__remove"
      @click.prevent="removeCompanyBlock(source), isFormChangedDetect(true)"
      v-if="!checker"
    ></button>
    <Checker v-if="checker" />
  </fieldset>
</template>
<script>
import Input from "@/components/formFields/Input";
import Checker from "@/components/formFields/Checker";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "CompanyBlock",
  components: { Input, Checker },
  props: {
    source: {
      type: Object,
    },
    changedSource: Object,
    checker: String,
    language: String,
  },
  data() {
    return {
      array: [],
    };
  },
  methods: {
    ...mapMutations([
      "createNewCompanyBlock",
      "removeCompanyBlock",
      "isFormChangedDetect",
    ]),
  },
  computed: {
    ...mapGetters(["getBrokerData"]),
  },
  created() {},
};
</script>
