var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    (_vm.brokerData !== null && _vm.currentDemoPlatforms.length > 0) ||
    (_vm.$route.name !== 'brokerReview' && _vm.getSelectedTab.title !== 'logs') ||
    (_vm.$route.name === 'brokerEdit' && _vm.getSelectedTab.title !== 'logs')
  )?_c('div',{staticClass:"demo_platform_wrap form-part"},[_c('h3',{staticClass:"fieldset__title"},[_vm._v("Demo account platforms")]),_vm._l((_vm.currentDemoPlatforms),function(demoPlatform,index){return _c('DemoPlatformContainer',{key:demoPlatform.id,attrs:{"id":demoPlatform.id,"canBeDeleted":_vm.canBeDeleted,"platformData":_vm.brokerData !== undefined &&
      (_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title !== 'logs') &&
      _vm.brokerData.find(function (item) { return item.id === demoPlatform.id; })
        ? _vm.brokerData.find(function (item) { return item.id === demoPlatform.id; })
        : _vm.brokerData !== undefined &&
          (_vm.$route.name === 'brokerReview' ||
            _vm.getSelectedTab.title !== 'logs') &&
          _vm.brokerData.find(function (item) { return item.id === demoPlatform.id; }) === -1
        ? demoPlatform
        : _vm.brokerData !== undefined &&
          _vm.$route.name === 'brokerEdit' &&
          _vm.brokerData.find(function (item) { return item.id === demoPlatform.id; })
        ? _vm.brokerData.find(function (item) { return item.id === demoPlatform.id; })
        : _vm.preparingDataServices.createDemoPlatform(),"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null,"changesData":_vm.changesBrokerData !== undefined
        ? _vm.changesBrokerData.find(function (item) { return item.id === demoPlatform.id; })
          ? _vm.brokerData.length > 0
            ? _vm.changesBrokerData.find(function (item) { return item.id === demoPlatform.id; })
            : demoPlatform
          : _vm.preparingDataServices.createDemoPlatform()
        : !_vm.changesBrokerData
        ? undefined
        : !_vm.getBrokerData.length && _vm.changesBrokerData[index]
        ? _vm.changesBrokerData[index]
        : undefined},on:{"demoPlatformRemoved":_vm.checkDemoPlatformsLength}})}),(
      (_vm.$route.name !== 'brokerReview' && _vm.getSelectedTab.title !== 'logs') ||
      (_vm.$route.name === 'brokerEdit' && _vm.getSelectedTab.title !== 'logs')
    )?_c('button',{staticClass:"add_history_item",attrs:{"type":"button"},on:{"click":function($event){_vm.addDemoPlatformItem(_vm.preparingDataServices.createDemoPlatform())}}},[_c('img',{attrs:{"src":require("../../assets/svg/ico-plus.svg"),"alt":"+"}}),_c('span',[_vm._v(" Add another demo account platform ")])]):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }