<template>
  <div class="lang_tabs" :id="name">
    <div class="lang_tabs__header">
      <button
        class="lang_tabs__toggler"
        v-for="(lang, index) in languages"
        :key="index"
        @click="currentTab = lang.name"
        :class="currentTab === lang.name ? 'lang_tabs__toggler--active' : ''"
        type="button"
      >
        {{ lang.name }}
      </button>
    </div>
    <div class="translation_info">
      <fieldset
        class="translation_info__content"
        v-for="(lang, index) in languages"
        :key="index"
        v-show="currentTab === lang.name"
        :name="name"
      >
        <div v-if="name === 'mainData'">
          <p class="translation_info__text">
            If any non-English value is left empty, English will be used
            instead.
          </p>
          <Input
            label="Broker Name"
            :required="lang.name.toLowerCase() === 'english'"
            name="name"
            :error="
              engNameInvalid && lang.name.toLowerCase() === 'english'
                ? 'Поле заполнено с ошибкой'
                : ''
            "
            :value="
              brokerData && brokerData.length
                ? brokerData.find((item) => item.languageSlug === lang.slug)
                    .name
                : ''
            "
            :changesValue="
              changesData
                ? changesData.find((data) => data.languageSlug === lang.slug)
                    .name
                : undefined
            "
            :checker="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? 'unset'
                : null
            "
            v-if="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? CompairingService.showField(
                    brokerData && brokerData.length
                      ? brokerData.find(
                          (item) => item.languageSlug === lang.slug
                        ).name
                      : null,
                    changesData
                      ? changesData.find(
                          (item) => item.languageSlug === lang.slug
                        ).name
                      : null
                  )
                : true
            "
          />

          <Input
            label="Link"
            type="url"
            showLink
            :name="'link'"
            :value="
              brokerData && brokerData.length
                ? brokerData.find((item) => item.languageSlug === lang.slug)
                    .link
                : ''
            "
            :changesValue="
              changesData
                ? changesData.find((data) => data.languageSlug === lang.slug)
                    .link
                : undefined
            "
            :checker="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? 'unset'
                : null
            "
            v-if="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? CompairingService.showField(
                    brokerData && brokerData.length
                      ? brokerData.find(
                          (item) => item.languageSlug === lang.slug
                        ).link
                      : null,
                    changesData
                      ? changesData.find(
                          (item) => item.languageSlug === lang.slug
                        ).link
                      : null
                  )
                : true
            "
          />

          <!--          <Input-->
          <!--            label="Further link"-->
          <!--            type="url"-->
          <!--            showLink-->
          <!--            name="externalLink"-->
          <!--            :value="-->
          <!--              brokerData && brokerData.length-->
          <!--                ? brokerData.find((item) => item.languageSlug === lang.slug)-->
          <!--                    .externalLink-->
          <!--                : ''-->
          <!--            "-->
          <!--            :changesValue="-->
          <!--              changesData-->
          <!--                ? changesData.find((data) => data.languageSlug === lang.slug)-->
          <!--                    .externalLink-->
          <!--                : undefined-->
          <!--            "-->
          <!--            :checker="-->
          <!--              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'-->
          <!--                ? 'unset'-->
          <!--                : null-->
          <!--            "-->
          <!--            v-if="-->
          <!--              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'-->
          <!--                ? CompairingService.showField(-->
          <!--                    brokerData && brokerData.length-->
          <!--                      ? brokerData.find(-->
          <!--                          (item) => item.languageSlug === lang.slug-->
          <!--                        ).externalLink-->
          <!--                      : null,-->
          <!--                    changesData-->
          <!--                      ? changesData.find(-->
          <!--                          (item) => item.languageSlug === lang.slug-->
          <!--                        ).externalLink-->
          <!--                      : null-->
          <!--                  )-->
          <!--                : true-->
          <!--            "-->
          <!--          />-->

          <Radio
            label="Broker is disabled"
            :values="[
              { value: false, label: 'No' },
              { value: true, label: 'Yes' },
            ]"
            :key="index"
            :name="'disabled' + lang.name"
            :selected="
              brokerData && brokerData.length
                ? brokerData.find((item) => item.languageSlug === lang.slug)
                    .disabled
                : 'NO'
            "
            :changesSelected="
              changesData
                ? changesData.find((data) => data.languageSlug === lang.slug)
                    .disabled
                : undefined
            "
            :checker="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? 'unset'
                : null
            "
            v-if="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? CompairingService.showField(
                    brokerData && brokerData.length
                      ? brokerData.find(
                          (item) => item.languageSlug === lang.slug
                        ).disabled
                      : null,
                    changesData
                      ? changesData.find(
                          (item) => item.languageSlug === lang.slug
                        ).disabled
                      : null
                  )
                : true
            "
            @input.capture="setDisableAll(lang.slug, $event)"
          />
          <input
            type="hidden"
            name="id"
            :value="
              brokerData && brokerData.length
                ? brokerData.find((item) => item.languageSlug === lang.slug).id
                : ''
            "
          />
        </div>
        <div v-if="name === 'vipCondition'">
          <Textarea
            label="Conditions for VIP traders"
            :name="'description'"
            :value="
              brokerData && brokerData.length
                ? brokerData.find((item) => item.languageSlug === lang.slug)
                    .description
                : ''
            "
            :changesValue="
              changesData
                ? changesData.find((data) => data.languageSlug === lang.slug)
                    .description
                : undefined
            "
            :checker="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? 'unset'
                : null
            "
            v-if="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? CompairingService.showField(
                    brokerData && brokerData.length
                      ? brokerData.find(
                          (item) => item.languageSlug === lang.slug
                        ).description
                      : null,
                    changesData
                      ? changesData.find(
                          (item) => item.languageSlug === lang.slug
                        ).description
                      : null
                  )
                : true
            "
          />
          <p
            class="fieldset__title"
            v-if="
              ($route.name !== 'brokerReview' &&
                getSelectedTab.title !== 'logs') ||
              ($route.name === 'brokerEdit' && getSelectedTab.title !== 'logs')
            "
          >
            Links to inner page (if any non-English value is left empty, English
            will be used instead.)
          </p>
          <button
            type="button"
            class="btn btn-open-links"
            @click.prevent="
              OpenMultipleLinks.showAll(
                $event.target
                  .closest('div')
                  .querySelector('.links_wrap')
                  .querySelectorAll('input')
              )
            "
            name="button"
            v-if="
              ($route.name !== 'brokerReview' &&
                getSelectedTab.title !== 'logs') ||
              ($route.name === 'brokerEdit' && getSelectedTab.title !== 'logs')
            "
          >
            Open all
          </button>
          <div class="links_wrap">
            <Input
              label="Demo account"
              showLink
              type="url"
              :name="'demoAccountLink'"
              :value="
                brokerData && brokerData.length
                  ? brokerData.find((item) => item.languageSlug === lang.slug)
                      .demoAccountLink
                  : ''
              "
              :changesValue="
                changesData
                  ? changesData.find((data) => data.languageSlug === lang.slug)
                      .demoAccountLink
                  : undefined
              "
              :checker="
                $route.name === 'brokerReview' ||
                getSelectedTab.title === 'logs'
                  ? 'unset'
                  : null
              "
              v-if="
                $route.name === 'brokerReview' ||
                getSelectedTab.title === 'logs'
                  ? CompairingService.showField(
                      brokerData && brokerData.length
                        ? brokerData.find(
                            (item) => item.languageSlug === lang.slug
                          ).demoAccountLink
                        : null,
                      changesData
                        ? changesData.find(
                            (item) => item.languageSlug === lang.slug
                          ).demoAccountLink
                        : null
                    )
                  : true
              "
            />
            <Input
              label="Real account"
              showLink
              type="url"
              :name="'realAccountLink'"
              :value="
                brokerData && brokerData.length
                  ? brokerData.find((item) => item.languageSlug === lang.slug)
                      .realAccountLink
                  : ''
              "
              :changesValue="
                changesData
                  ? changesData.find((data) => data.languageSlug === lang.slug)
                      .realAccountLink
                  : undefined
              "
              :checker="
                $route.name === 'brokerReview' ||
                getSelectedTab.title === 'logs'
                  ? 'unset'
                  : null
              "
              v-if="
                $route.name === 'brokerReview' ||
                getSelectedTab.title === 'logs'
                  ? CompairingService.showField(
                      brokerData && brokerData.length
                        ? brokerData.find(
                            (item) => item.languageSlug === lang.slug
                          ).realAccountLink
                        : null,
                      changesData
                        ? changesData.find(
                            (item) => item.languageSlug === lang.slug
                          ).realAccountLink
                        : null
                    )
                  : true
              "
            />
            <Input
              label="Contract Specifications"
              showLink
              type="url"
              :name="'contractSpecsLink'"
              :value="
                brokerData && brokerData.length
                  ? brokerData.find((item) => item.languageSlug === lang.slug)
                      .contractSpecsLink
                  : ''
              "
              :changesValue="
                changesData
                  ? changesData.find((data) => data.languageSlug === lang.slug)
                      .contractSpecsLink
                  : undefined
              "
              :checker="
                $route.name === 'brokerReview' ||
                getSelectedTab.title === 'logs'
                  ? 'unset'
                  : null
              "
              v-if="
                $route.name === 'brokerReview' ||
                getSelectedTab.title === 'logs'
                  ? CompairingService.showField(
                      brokerData && brokerData.length
                        ? brokerData.find(
                            (item) => item.languageSlug === lang.slug
                          ).contractSpecsLink
                        : null,
                      changesData
                        ? changesData.find(
                            (item) => item.languageSlug === lang.slug
                          ).contractSpecsLink
                        : null
                    )
                  : true
              "
            />
          </div>

          <Textarea
            label="Special conditions/offers"
            :name="'specialConditions'"
            :value="
              brokerData && brokerData.length
                ? brokerData.find((item) => item.languageSlug === lang.slug)
                    .specialConditions
                : ''
            "
            :changesValue="
              changesData
                ? changesData.find((data) => data.languageSlug === lang.slug)
                    .specialConditions
                : undefined
            "
            :checker="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? 'unset'
                : null
            "
            v-if="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? CompairingService.showField(
                    brokerData && brokerData.length
                      ? brokerData.find(
                          (item) => item.languageSlug === lang.slug
                        ).specialConditions
                      : null,
                    changesData
                      ? changesData.find(
                          (item) => item.languageSlug === lang.slug
                        ).specialConditions
                      : null
                  )
                : true
            "
          />
          <input
            type="hidden"
            name="id"
            :value="
              brokerData && brokerData.length
                ? brokerData.find((item) => item.languageSlug === lang.slug).id
                : ''
            "
          />
        </div>
        <div v-if="name === 'scalpingLimits'">
          <Textarea
            label="Scalping Explanation"
            name="description"
            :value="
              brokerData && brokerData.length
                ? brokerData.find((item) => item.languageSlug === lang.slug)
                    .description
                : ''
            "
            :changesValue="
              changesData
                ? changesData.find((data) => data.languageSlug === lang.slug)
                    .description
                : undefined
            "
            :checker="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? 'unset'
                : null
            "
            v-if="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? CompairingService.showField(
                    brokerData && brokerData.length
                      ? brokerData.find(
                          (item) => item.languageSlug === lang.slug
                        ).description
                      : null,
                    changesData
                      ? changesData.find(
                          (item) => item.languageSlug === lang.slug
                        ).description
                      : null
                  )
                : true
            "
          />
          <input
            type="hidden"
            name="id"
            :value="
              brokerData && brokerData.length
                ? brokerData.find((item) => item.languageSlug === lang.slug).id
                : ''
            "
          />
        </div>
        <div
          v-if="
            name === 'history' &&
            ((getBrokerData.brokerHistory &&
              getBrokerData.brokerHistory.length &&
              currentHistoryData &&
              currentHistoryData.length) ||
              (getSelectedTab.title === 'logs' &&
                currentHistoryData &&
                currentHistoryData.length))
          "
        >
          <p class="fieldset__title">History</p>
          <HistoryItem
            v-for="historyItem in currentHistoryData.find(
              (item) => item.languageSlug === lang.slug
            ).data"
            :key="historyItem.id"
            :id="historyItem.id"
            :lang="lang.slug"
            :data="
              getSelectedTab.title === 'logs'
                ? brokerData && brokerData.length
                  ? brokerData.find((item) => item.languageSlug === lang.slug)
                      .data.length
                    ? brokerData
                        .find((item) => item.languageSlug === lang.slug)
                        .data.find((item) => item.id === historyItem.id)
                    : null
                  : null
                : brokerData && brokerData.length
                ? brokerData
                    .find((item) => item.languageSlug === lang.slug)
                    .data.find(
                      (changesHistoryItem) =>
                        changesHistoryItem.id === historyItem.id
                    )
                : null
            "
            :changesData="
              changesData !== undefined
                ? changesData
                    .find((item) => item.languageSlug === lang.slug)
                    .data.find(
                      (changesHistoryItem) =>
                        changesHistoryItem.id === historyItem.id
                    ) || {}
                : undefined
            "
            :checker="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? 'unset'
                : null
            "
          />
          <input type="hidden" name="languageSlug" :value="lang.slug" />
          <button
            type="button"
            class="add_history_item"
            @click="
              createNewHistoryItem({
                lang: lang.slug,
                data: preparingDataServices.createHistoryItem(),
              })
            "
          >
            <img src="../../assets/svg/ico-plus.svg" alt="+" />
            <span> Add </span>
          </button>
        </div>
        <div v-if="name === 'eaLimits'">
          <Textarea
            label="EAs Explanation"
            name="description"
            :value="
              brokerData && brokerData.length
                ? brokerData.find((item) => item.languageSlug === lang.slug)
                    .description
                : ''
            "
            :changesValue="
              changesData
                ? changesData.find((data) => data.languageSlug === lang.slug)
                    .description
                : undefined
            "
            :checker="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? 'unset'
                : null
            "
            v-if="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? CompairingService.showField(
                    brokerData && brokerData.length
                      ? brokerData.find(
                          (item) => item.languageSlug === lang.slug
                        ).description
                      : null,
                    changesData
                      ? changesData.find(
                          (item) => item.languageSlug === lang.slug
                        ).description
                      : null
                  )
                : true
            "
          />
          <input
            type="hidden"
            name="id"
            :value="
              brokerData && brokerData.length
                ? brokerData.find((item) => item.languageSlug === lang.slug).id
                : ''
            "
          />
        </div>
        <div v-if="name === 'specialConditions'">
          <Textarea
            label="Special conditions"
            name="description"
            :value="
              brokerData && brokerData.length
                ? brokerData.find((item) => item.languageSlug === lang.slug)
                    .description
                : ''
            "
            :changesValue="
              changesData
                ? changesData.find((data) => data.languageSlug === lang.slug)
                    .description
                : undefined
            "
            :checker="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? 'unset'
                : null
            "
            v-if="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? CompairingService.showField(
                    brokerData && brokerData.length
                      ? brokerData.find(
                          (item) => item.languageSlug === lang.slug
                        ).description
                      : null,
                    changesData
                      ? changesData.find(
                          (item) => item.languageSlug === lang.slug
                        ).description
                      : null
                  )
                : true
            "
          />

          <input
            type="hidden"
            name="id"
            :value="
              brokerData && brokerData.length
                ? brokerData.find((item) => item.languageSlug === lang.slug).id
                : ''
            "
          />
        </div>
        <div v-if="name === 'brokerNotes'">
          <Textarea
            label="Admin`s notes"
            name="description"
            :value="
              brokerData && brokerData.length
                ? brokerData.find((item) => item.languageSlug === lang.slug)
                    .description
                : ''
            "
            :changesValue="
              changesData
                ? changesData.find((data) => data.languageSlug === lang.slug)
                    .description
                : undefined
            "
            :checker="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? 'unset'
                : null
            "
            v-if="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? CompairingService.showField(
                    brokerData && brokerData.length
                      ? brokerData.find(
                          (item) => item.languageSlug === lang.slug
                        ).description
                      : null,
                    changesData
                      ? changesData.find(
                          (item) => item.languageSlug === lang.slug
                        ).description
                      : null
                  )
                : true
            "
          />
          <input
            type="hidden"
            name="id"
            :value="
              brokerData && brokerData.length
                ? brokerData.find((item) => item.languageSlug === lang.slug).id
                : ''
            "
          />
        </div>
        <div v-if="name === 'brokerWarningSings'">
          <Textarea
            label="Warning signs"
            name="description"
            :value="
              brokerData && brokerData.length
                ? brokerData.find((item) => item.languageSlug === lang.slug)
                    .description
                : ''
            "
            :changesValue="
              changesData
                ? changesData.find((data) => data.languageSlug === lang.slug)
                    .description
                : undefined
            "
            :checker="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? 'unset'
                : null
            "
            v-if="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? CompairingService.showField(
                    brokerData && brokerData.length
                      ? brokerData.find(
                          (item) => item.languageSlug === lang.slug
                        ).description
                      : null,
                    changesData
                      ? changesData.find(
                          (item) => item.languageSlug === lang.slug
                        ).description
                      : null
                  )
                : true
            "
          />
          <input
            type="hidden"
            name="id"
            :value="
              brokerData && brokerData.length
                ? brokerData.find((item) => item.languageSlug === lang.slug).id
                : ''
            "
          />
        </div>

        <div
          v-if="
            name === 'companyBlock' &&
            ((getBrokerData.brokerCompanies &&
              getBrokerData.brokerCompanies.length &&
              currentBrokerCompanies &&
              currentBrokerCompanies.length) ||
              (getSelectedTab.title === 'logs' &&
                currentBrokerCompanies &&
                currentBrokerCompanies.length))
          "
        >
          <p class="fieldset__title">Companies</p>
          <CompanyBlock
            v-for="source in currentBrokerCompanies.filter(
              (item) => item.languageSlug === lang.slug
            )"
            :id="source.id"
            :key="source.id"
            :source="
              getSelectedTab.title === 'logs'
                ? brokerData && brokerData.length
                  ? brokerData.find((item) => item.id === source.id) || {}
                  : {}
                : brokerData && brokerData.length
                ? brokerData.find((item) => item.id === source.id) || {}
                : {}
            "
            :changedSource="
              changesData !== undefined
                ? changesData.find((item) => item.id === source.id) || {}
                : undefined
            "
            :checker="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? 'unset'
                : null
            "
            :language="lang.slug"
          />
          <input type="hidden" name="languageSlug" :value="lang.slug" />
          <button
            type="button"
            class="add_company_block"
            v-if="
              ($route.name === 'brokerEdit' ||
                $route.name === 'brokerCreate') &&
              currentBrokerCompanies.filter(
                (item) => item.languageSlug === lang.slug
              ).length < 10
            "
            @click="
              createNewCompanyBlock({
                languageSlug: lang.slug,
                ...preparingDataServices.createBrokerCompany(),
              })
            "
          >
            <img src="../../assets/svg/ico-plus.svg" alt="+" />
            <span>Add company</span>
          </button>
        </div>

        <div v-if="name === 'brokerFlags'">
          <Textarea
            label="Custom flags"
            desc="Each flag on a new line"
            name="flags"
            :value="
              brokerData && brokerData.length
                ? brokerData.find((item) => item.languageSlug === lang.slug)
                    .flags
                : ''
            "
            :changesValue="
              changesData
                ? changesData.find((data) => data.languageSlug === lang.slug)
                    .flags
                : undefined
            "
            :checker="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? 'unset'
                : null
            "
            v-if="
              $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
                ? CompairingService.showField(
                    brokerData && brokerData.length
                      ? brokerData.find(
                          (item) => item.languageSlug === lang.slug
                        ).flags
                      : null,
                    changesData
                      ? changesData.find(
                          (item) => item.languageSlug === lang.slug
                        ).flags
                      : null
                  )
                : true
            "
          />
          <input
            type="hidden"
            name="id"
            :value="
              brokerData && brokerData.length
                ? brokerData.find((item) => item.languageSlug === lang.slug).id
                : ''
            "
          />
        </div>
        <input type="hidden" name="languageSlug" :value="lang.slug" />
      </fieldset>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import Input from "@/components/formFields/Input";
import Radio from "@/components/formFields/Radio";
import Textarea from "@/components/formFields/Textarea";
import HistoryItem from "@/components/formFields/HistoryItem";
import PreparingDataServices from "@/services/PreparingDataServices";
import CompairingService from "@/services/CompairingService";
import OpenMultipleLinks from "@/services/OpenMultipleLinks";
import CompanyBlock from "@/components/formFields/CompanyBlock";
// import _ from "lodash";
export default {
  name: "LangTabs",
  components: {
    CompanyBlock,
    Input,
    Radio,
    Textarea,
    HistoryItem,
  },
  props: {
    languages: [Array, Set],
    name: String,
    brokerData: Array,
    changesData: Array,
  },
  data() {
    return {
      currentTab: "",
      engNameInvalid: false,
      preparingDataServices: new PreparingDataServices(),
      historyUpdated: false,
      CompairingService: new CompairingService(),
      OpenMultipleLinks: new OpenMultipleLinks(),
      created: false,
      triggerChangeInData: {},
    };
  },
  created() {
    if (this.currentTab === "" && this.languages) {
      this.currentTab = this.languages[0] ? this.languages[0].name : null;
    }
    for (const idx in this.languages) {
      this.triggerChangeInData[this.languages[idx].slug] = false;
    }
  },
  updated() {
    this.$nextTick(function () {
      if (
        (this.currentTab === "" || this.currentTab === null) &&
        this.languages[0]
      ) {
        this.currentTab = this.languages[0].name;
      }
      if (
        this.name === "history" &&
        this.getCurrentMode !== "review" &&
        this.getBrokerData.brokerHistory &&
        !this.getBrokerData.brokerHistory.length
      ) {
        this.addHistoryItem(
          this.preparingDataServices.prepareHistory(this.languages)
        );
      }
      if (
        this.name === "history" &&
        (this.getCurrentMode !== "review" ||
          (this.$route.name === "brokerEdit" &&
            this.getSelectedTab.title !== "logs")) &&
        this.getBrokerData &&
        this.getBrokerData.brokerHistory &&
        this.getBrokerData.brokerHistory.length &&
        !this.created
      ) {
        this.getBrokerData.brokerHistory.forEach((historyLang) => {
          if (!historyLang.data) {
            this.createNewHistoryItem({
              lang: historyLang.languageSlug,
              data: this.preparingDataServices.createHistoryItem(),
            });
          }
        });
        this.created = true;
      }
      if (
        this.name === "companyBlock" &&
        this.getBrokerData &&
        this.getBrokerData.brokerCompanies &&
        !this.created
      ) {
        this.languages.forEach((lang) => {
          if (
            !this.getBrokerData.brokerCompanies.find(
              (item) => item.languageSlug === lang.slug
            )
          ) {
            this.createNewCompanyBlock({
              languageSlug: lang.slug,
              ...this.preparingDataServices.createBrokerCompany(),
            });
          }
        });
        this.created = true;
      }
    });
  },
  mounted() {
    if (
      this.name === "companyBlock" &&
      this.getBrokerData &&
      this.getBrokerData.brokerCompanies &&
      !this.created &&
      this.languages
    ) {
      this.languages.forEach((lang) => {
        if (
          !this.getBrokerData.brokerCompanies.find(
            (item) => item.languageSlug === lang.slug
          )
        ) {
          this.createNewCompanyBlock({
            languageSlug: lang.slug,
            ...this.preparingDataServices.createBrokerCompany(),
          });
        }
      });
      this.created = true;
    }
  },
  beforeDestroy() {
    this.currentTab = "";
  },
  computed: {
    ...mapGetters([
      "getBrokerData",
      "getCurrentMode",
      "getDisabledLanguages",
      "getSelectedTab",
    ]),

    currentHistoryData: (vm) => {
      if (
        vm.brokerData &&
        vm.changesData !== undefined &&
        vm.name === "history" &&
        vm.currentTab
      ) {
        let history = [];
        if (vm.brokerData.length) {
          vm.brokerData.forEach((historyLang) => {
            history.push({
              languageSlug: historyLang.languageSlug,
              id: historyLang.id,
              data: vm.CompairingService.getComparedInputData(
                historyLang.data,
                vm.changesData.find(
                  (item) => item.languageSlug === historyLang.languageSlug
                ).data,
                "brokerHistory"
              ),
            });
          });
        } else {
          vm.changesData.forEach((historyLang) => {
            history.push({
              languageSlug: historyLang.languageSlug,
              id: historyLang.id,
              data: vm.changesData.find(
                (item) => item.languageSlug === historyLang.languageSlug
              ).data,
            });
          });
        }
        return history;
      } else if (vm.name === "history" && vm.getBrokerData && vm.currentTab) {
        if (vm.getSelectedTab.title === "logs") {
          return vm.changesData;
        } else {
          return vm.getBrokerData.brokerHistory;
        }
      }
    },
    currentBrokerCompanies: (vm) => {
      if (
        vm.brokerData &&
        vm.changesData !== undefined &&
        vm.name === "companyBlock" &&
        vm.currentTab
      ) {
        let companies = [];
        if (vm.brokerData.length) {
          companies = vm.CompairingService.getComparedInputData(
            vm.brokerData,
            vm.changesData,
            "brokerCompanies"
          );
        } else if (vm.changesData) {
          companies = [...vm.changesData];
        }
        return companies;
      } else if (
        vm.name === "companyBlock" &&
        vm.getBrokerData &&
        vm.currentTab
      ) {
        if (vm.getSelectedTab.title === "logs") {
          return vm.changesData;
        } else {
          return vm.getBrokerData.brokerCompanies;
        }
      }
    },
  },
  methods: {
    ...mapMutations([
      "updateBrokerInfo",
      "addHistoryItem",
      "createNewHistoryItem",
      "createNewCompanyBlock",
      "setLangTabClickState",
    ]),
    setDisableAll(lang) {
      if (!this.triggerChangeInData[lang]) {
        this.triggerChangeInData[lang] = true;
        return;
      }

      let checks = [];
      let tabsState = {};
      this.$parent.$refs.mainData
        .querySelectorAll("input[name^=disabled][value=true]")
        .forEach((el) => {
          checks.push(el.checked);
          tabsState[el.name] = el.checked;
        });

      let disableAll = true;
      for (const check of checks) {
        if (!check) {
          disableAll = false;
          break;
        }
      }

      this.setLangTabClickState(tabsState);

      this.$parent.$refs.mainData
        .querySelector("input[name=disableAll][value=" + disableAll + "]")
        .click();
    },
  },
};
</script>
