var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"site-preview"},[(_vm.$route.name !== 'brokerReview' && _vm.getSelectedTab.title !== 'logs')?_c('div',{staticClass:"site-preview__label"},[_vm._v(" Broker website screenshot ")]):_vm._e(),(_vm.$route.name !== 'brokerReview' && _vm.getSelectedTab.title !== 'logs')?_c('div',{staticClass:"site-preview__content"},[_c('div',{staticClass:"site-preview__controls"},[_c('div',{staticClass:"site-preview__btns"},[_c('button',{class:['btn', { '--disabled': _vm.loading }],on:{"click":_vm.takeAScreenshot}},[_vm._v(" Take a screenshot ")]),_c('span',{staticClass:"site-preview__btn-divider"},[_vm._v("or")]),_c('form',{attrs:{"method":"POST","enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return _vm.uploadScreenshot.apply(null, arguments)}}},[_c('label',{class:['btn', { '--disabled': _vm.loading }]},[_c('input',{attrs:{"accept":"image/png, image/jpeg, image/jpg","type":"file","name":"file"},on:{"change":_vm.uploadScreenshot}}),_c('span',[_vm._v("Upload screenshot")])])])]),(_vm.fileName)?_c('div',{staticClass:"site-preview__created"},[_vm._v(" File name: "+_vm._s(_vm.fileName)+" ")]):_vm._e(),(_vm.created)?_c('div',{staticClass:"site-preview__created"},[_vm._v(_vm._s(_vm.created))]):_vm._e(),(_vm.errorMsg)?_c('div',{staticClass:"site-preview__error"},[_vm._v(_vm._s(_vm.errorMsg))]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"site-preview__loader"},[_c('span'),_c('span'),_c('span'),_c('span'),_c('span')]):_vm._e()]),(_vm.imageUrl)?_c('div',{staticClass:"site-preview__image"},[_c('img',{attrs:{"src":_vm.imageUrl,"alt":""},on:{"click":_vm.showBrokerPreview}}),_c('button',{staticClass:"site-preview__remove-image",on:{"click":_vm.removeScreenshot}})]):_vm._e()]):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.noCreateScreenshot,
            _vm.changedNoCreateScreenshot
          )
        : true
    )?_c('Radio',{attrs:{"label":"Not change screenshot on update","values":[
      { label: 'No', value: 'false' },
      { label: 'Yes', value: 'true' } ],"name":"noCreateScreenshot","selected":_vm.noCreateScreenshot !== undefined
        ? _vm.noCreateScreenshot.toString()
        : 'false',"changesSelected":_vm.changedNoCreateScreenshot !== undefined
        ? _vm.changedNoCreateScreenshot
        : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),_c('transition',{attrs:{"name":"fade-modal"}},[(_vm.modalVisibility)?_c('div',{staticClass:"site-preview__modal"},[_c('div',{staticClass:"site-preview__modal-hider",on:{"click":_vm.hideBrokerPreview}}),_c('div',{staticClass:"site-preview__modal-content"},[_c('span',{staticClass:"site-preview__modal-close",on:{"click":_vm.hideBrokerPreview}},[_vm._v("×")]),_c('img',{attrs:{"src":_vm.imageUrl,"alt":""}})])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }