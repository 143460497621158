var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fieldset',{staticClass:"demo_platform__container",attrs:{"name":"demoPlatform","id":_vm.id}},[_c('div',{staticClass:"demo_platform__wrap",attrs:{"data-type":"currentValue","id":_vm.id}},[_c('div',{staticClass:"demo_platform__main"},[_c('v-select',{staticStyle:{"width":"100%"},attrs:{"options":_vm.tradingPlatforms,"placeholder":"Select the trading platform","getOptionLabel":function (option) { return option.name; },"clearable":false},on:{"input":function($event){return _vm.isFormChangedDetect(true)}},model:{value:(_vm.selectedPlatform),callback:function ($$v) {_vm.selectedPlatform=$$v},expression:"selectedPlatform"}}),_c('input',{attrs:{"type":"hidden","name":"selectedPlatform"},domProps:{"value":_vm.selectedPlatform ? _vm.selectedPlatform.id : ''}}),(_vm.canBeDeleted)?_c('button',{staticClass:"demo_platform__remove",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();_vm.removeDemoPlatform(_vm.id), _vm.isFormChangedDetect(true)}}}):_vm._e()],1),(_vm.selectedPlatform)?_c('v-select',{attrs:{"options":_vm.conditionOptions,"reduce":function (conditionOptions) { return conditionOptions.val; },"placeholder":"None / Enter below","getOptionLabel":function (option) { return option.name; },"optionalValue":"val","clearable":false},on:{"input":function($event){return _vm.isFormChangedDetect(true)}},model:{value:(_vm.selectedCondition),callback:function ($$v) {_vm.selectedCondition=$$v},expression:"selectedCondition"}}):_vm._e(),_c('input',{attrs:{"type":"hidden","name":"selectedCondition"},domProps:{"value":_vm.selectedCondition}}),(
        _vm.selectedCondition === 'LIMITED_DAYS' ||
        _vm.selectedCondition === 'LIMITED_DAYS_INACTIVITY' ||
        (_vm.platformData !== undefined &&
          _vm.platformData.demoTradingPlatformConditionDays)
      )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.demoDays),expression:"demoDays"}],staticClass:"input__field",class:_vm.demoDays !== null &&
        (_vm.demoDays.length > 10 || _vm.demoDays.length <= 0) &&
        _vm.$route.name !== 'brokerReview'
          ? '--error'
          : '',attrs:{"maxLength":"10","type":"number"},domProps:{"value":(_vm.demoDays)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.demoDays=$event.target.value},function($event){return _vm.isFormChangedDetect(true)}]}}):_vm._e(),_c('input',{attrs:{"type":"hidden","name":"demoTradingPlatformConditionDays"},domProps:{"value":_vm.demoDays}})],1),(_vm.checker)?_c('Checker'):_vm._e(),(_vm.changesData !== undefined)?_c('div',{staticClass:"demo_platform__wrap",attrs:{"data-type":"changesValue"}},[_c('div',{staticClass:"demo_platform__main"},[_c('v-select',{staticStyle:{"width":"100%"},attrs:{"options":_vm.tradingPlatforms,"placeholder":'Select your option',"getOptionLabel":function (option) { return option.name; },"clearable":false},model:{value:(_vm.changesSelectedPlatform),callback:function ($$v) {_vm.changesSelectedPlatform=$$v},expression:"changesSelectedPlatform"}}),_c('input',{attrs:{"type":"hidden","name":"selectedPlatform"},domProps:{"value":_vm.changesSelectedPlatform ? _vm.changesSelectedPlatform.id : ''}})],1),_c('v-select',{attrs:{"options":_vm.conditionOptions,"reduce":function (conditionOptions) { return conditionOptions.val; },"placeholder":"None / Enter below","getOptionLabel":function (option) { return option.name; },"optionalValue":"val","clearable":false},model:{value:(_vm.changesSelectedCondition),callback:function ($$v) {_vm.changesSelectedCondition=$$v},expression:"changesSelectedCondition"}}),_c('input',{attrs:{"type":"hidden","name":"selectedCondition"},domProps:{"value":_vm.changesSelectedCondition}}),(
        _vm.changesSelectedCondition === 'LIMITED_DAYS' ||
        _vm.changesSelectedCondition === 'LIMITED_DAYS_INACTIVITY' ||
        (_vm.changesData !== undefined &&
          _vm.changesData.demoTradingPlatformConditionDays)
      )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.changesDemoDays),expression:"changesDemoDays"}],staticClass:"input__field",class:_vm.changesDemoDays &&
        _vm.changesDemoDays.length > 10 &&
        _vm.$route.name !== 'brokerReview'
          ? '--error'
          : '',attrs:{"name":"demoTradingPlatformConditionDays","maxLength":"10","type":"number"},domProps:{"value":(_vm.changesDemoDays)},on:{"input":function($event){if($event.target.composing){ return; }_vm.changesDemoDays=$event.target.value}}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }