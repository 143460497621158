<template>
  <div>
    <div class="rollback_hint">
      You’re comparing two versions of
      {{ type === "MAIN_DATA" ? "Main Data" : "Account Type" }}: the old one (
      <strong>{{ dateTime }}</strong> ) is on the top and the
      <strong>current one</strong> is on the bottom.
    </div>
  </div>
</template>
<script>
export default {
  name: "RollbackHint",
  props: {
    type: String,
    dateTime: String,
  },
  data() {
    return {};
  },
};
</script>
