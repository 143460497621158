import _ from "lodash";
import brokerDemoPlatform from "@/models/brokerDemoPlatform";
import brokerHistoryItem from "@/models/brokerHistoryItem";

class CompairingService {
  getComparedInputData(currentData, changesData, typeOfData) {
    let filterUniqueDataItems = (searchable, haystack) => {
      return searchable.filter(
        (searchableItem) =>
          !haystack.find(
            (haystackItem) => haystackItem.id === searchableItem.id
          )
      );
    };

    let filterChangedDataItems = (currentData, changesData, uniqueItems) => {
      return {
        oldData: currentData.filter((item) => !uniqueItems.includes(item)),
        newData: changesData.filter((item) => !uniqueItems.includes(item)),
      };
    };

    let removedItems = filterUniqueDataItems(currentData, changesData);
    let addedItems = filterUniqueDataItems(changesData, currentData);
    let changedItems = filterChangedDataItems(
      currentData,
      changesData,
      removedItems.concat(addedItems, typeOfData)
    );
    return addedItems.concat(
      this.resetRemovedItem(removedItems, typeOfData),
      this.compareChanges(changedItems, typeOfData).newData
    );
  }

  compareChanges(changedItems, typeOfData) {
    switch (typeOfData) {
      case "brokerDemoPlatforms":
        return this.filterItemsWithCompairing(changedItems);
      case "brokerHistory":
        return this.filterItemsWithCompairing(changedItems);
      case "checkboxes":
        return this.filterItemsWithCompairing(changedItems);
      case "brokerCompanies":
        return this.filterItemsWithCompairing(changedItems);
      default:
        return changedItems;
    }
  }

  filterItemsWithCompairing({ oldData, newData }) {
    let oldDataFiltered = oldData.filter(
      (oldDataItem) =>
        !_.isEqual(
          oldDataItem,
          newData.find((newDataItem) => newDataItem.id === oldDataItem.id)
        )
    );
    return {
      oldData: oldDataFiltered,
      newData: newData.filter((newDataItem) =>
        oldDataFiltered.find(
          (oldDataFilteredItem) => oldDataFilteredItem.id === newDataItem.id
        )
      ),
    };
  }

  resetRemovedItem(removedItems, typeOfData) {
    removedItems.forEach((item, index) => {
      switch (typeOfData) {
        case "brokerDemoPlatforms":
          removedItems.push(new brokerDemoPlatform(item.id));
          removedItems.splice(index, 1);
          break;
        case "brokerHistory":
          removedItems.push(new brokerHistoryItem(item.id));
          removedItems.splice(index, 1);
          break;
      }
    });
    return removedItems;
  }

  compareLangData(oldData, newData, typeOfData) {
    let dataToShow = [];
    if (newData && !oldData) {
      newData.forEach((newItem) => {
        if (typeOfData === "brokerHistory") {
          if (newItem.data.length > 0) {
            dataToShow.push({
              slug: newItem.languageSlug,
              name:
                newItem.languageSlug[0].toUpperCase() +
                newItem.languageSlug.slice(1),
            });
          }
        } else if (typeOfData === "brokerCompanies") {
          newData.forEach((newItem) => {
            if (
              !dataToShow.find((item) => item.slug === newItem.languageSlug)
            ) {
              dataToShow.push({
                slug: newItem.languageSlug,
                name:
                  newItem.languageSlug[0].toUpperCase() +
                  newItem.languageSlug.slice(1),
              });
            }
          });
        } else {
          let fieldsToCheck = Object.keys(newItem).filter(
            (key) =>
              key !== "id" && key !== "languageSlug" && key !== "__typename"
          );
          if (
            fieldsToCheck.findIndex(
              (item) =>
                newItem[item] !== "" &&
                newItem[item] !== "false" &&
                newItem[item] !== null
            ) > -1
          ) {
            dataToShow.push({
              slug: newItem.languageSlug,
              name:
                newItem.languageSlug[0].toUpperCase() +
                newItem.languageSlug.slice(1),
            });
          }
        }
      });
    } else if (newData) {
      if (typeOfData === "brokerHistory") {
        newData.forEach((newDataItem) => {
          oldData.forEach((oldDataItem) => {
            if (newDataItem.languageSlug === oldDataItem.languageSlug) {
              if (newDataItem.data.length > oldDataItem.data.length) {
                dataToShow.push({
                  slug: newDataItem.languageSlug,
                  name:
                    newDataItem.languageSlug[0].toUpperCase() +
                    newDataItem.languageSlug.slice(1),
                });
              } else if (newDataItem.data.length < oldDataItem.data.length) {
                dataToShow.push({
                  slug: oldDataItem.languageSlug,
                  name:
                    oldDataItem.languageSlug[0].toUpperCase() +
                    oldDataItem.languageSlug.slice(1),
                });
              } else if (newDataItem.data.length === oldDataItem.data.length) {
                if (newDataItem.data.length !== 0) {
                  if (!this.isArrayEqual(newDataItem.data, oldDataItem.data)) {
                    !dataToShow.find(
                      (item) => item.slug === newDataItem.languageSlug
                    )
                      ? dataToShow.push({
                          slug: newDataItem.languageSlug,
                          name:
                            newDataItem.languageSlug[0].toUpperCase() +
                            newDataItem.languageSlug.slice(1),
                        })
                      : null;
                  }
                }
              }
            }
          });
        });
      } else if (typeOfData === "brokerCompanies") {
        if (newData.length > oldData.length) {
          new Set(newData.map((item) => item.languageSlug)).forEach((item) => {
            dataToShow.push({
              slug: item,
              name: item[0].toUpperCase() + item.slice(1),
            });
          });
        } else if (newData.length < oldData.length) {
          new Set(oldData.map((item) => item.languageSlug)).forEach((item) => {
            dataToShow.push({
              slug: item,
              name: item[0].toUpperCase() + item.slice(1),
            });
          });
        } else {
          let data = [];

          if (!this.isArrayEqual(newData, oldData)) {
            Object.keys(newData[0]).forEach((key) => {
              data.push(_.uniqBy([...newData, ...oldData], key));
            });
            new Set(data.flat().map((item) => item.languageSlug)).forEach(
              (item) => {
                dataToShow.push({
                  slug: item,
                  name: item[0].toUpperCase() + item.slice(1),
                });
              }
            );
          }
        }
      } else {
        oldData.forEach((old) => {
          let updatedItem = newData.find(
            (update) => update.languageSlug === old.languageSlug
          );

          if (old.id === updatedItem?.id) {
            let obj = {};
            Object.keys(old).forEach((key) => {
              obj[key] = old[key] !== updatedItem[key] ? updatedItem[key] : "";
            });

            if (Object.keys(obj).find((key) => obj[key] !== "")) {
              dataToShow.push({
                slug: old.languageSlug,
                name:
                  old.languageSlug[0].toUpperCase() + old.languageSlug.slice(1),
              });
            }
          }
        });
      }
    } else {
      if (typeOfData === "brokerHistory") {
        oldData?.forEach((old) => {
          if (old.data && old.data.length > 0) {
            dataToShow.push({
              slug: old.languageSlug,
              name:
                old.languageSlug[0].toUpperCase() + old.languageSlug.slice(1),
            });
          }
        });
      } else if (typeOfData === "brokerCompanies") {
        oldData?.forEach((old) => {
          if (!dataToShow.find((item) => item.slug === old.languageSlug)) {
            dataToShow.push({
              slug: old.languageSlug,
              name:
                old.languageSlug[0].toUpperCase() + old.languageSlug.slice(1),
            });
          }
        });
      } else {
        if (oldData) {
          oldData?.forEach((old) => {
            let fieldsToCheck = Object.keys(old).filter(
              (key) =>
                key !== "id" && key !== "languageSlug" && key !== "__typename"
            );
            if (
              fieldsToCheck.findIndex(
                (item) =>
                  old[item] !== "" &&
                  old[item] !== "false" &&
                  old[item] !== null
              ) > -1
            ) {
              dataToShow.push({
                slug: old.languageSlug,
                name:
                  old.languageSlug[0].toUpperCase() + old.languageSlug.slice(1),
              });
            }
          });
        }
      }
    }
    return dataToShow;
  }

  isArrayEqual(x, y) {
    return _(x).differenceWith(y, _.isEqual).isEmpty();
  }

  compareRadioOptions(oldData, newData) {
    let dataToShow = [];
    if (oldData && newData) {
      oldData.forEach((old) => {
        if (
          newData.find(
            (update) =>
              update.optionId === old.optionId &&
              update.optionState !== old.optionState
          )
        ) {
          dataToShow.push(old);
        }
      });
    } else if (oldData && !newData) {
      dataToShow = oldData;
    } else if (!oldData && newData) {
      dataToShow = newData.filter(
        (item) => item.optionState.toLowerCase() !== "no"
      );
    }
    return dataToShow;
  }

  showField(oldData, newData) {
    if (oldData === null && newData === null) {
      return false;
    } else {
      let typeOfData = oldData === null ? "string" : typeof oldData;
      if (newData === null) {
        switch (typeOfData) {
          case "string":
            return oldData !== "";
          case "number":
            return oldData !== newData;
          case "boolean":
            return oldData !== newData;
          case "object":
            return oldData.length > 0;
          default:
            break;
        }
      } else {
        switch (typeOfData) {
          case "string":
            return oldData !== newData;
          case "number":
            return oldData !== newData;
          case "boolean":
            return oldData !== newData;
          case "object":
            return (
              _.differenceBy(newData, oldData, "id").length > 0 ||
              _.differenceBy(oldData, newData, "id").length > 0
            );
          default:
            break;
        }
      }
    }
  }

  getHistoryChangesLanguages(data) {
    let langs = [];
    if (data.length) {
      data.forEach((item) => {
        langs.push({
          slug: item.languageSlug,
          name: item.languageSlug[0].toUpperCase() + item.languageSlug.slice(1),
        });
      });
    }
    return langs;
  }
}

export default CompairingService;
