var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-part"},[(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.websiteLanguages ? _vm.brokerData.websiteLanguages : [],
            _vm.brokerChanges && _vm.brokerChanges.websiteLanguages
              ? _vm.brokerChanges.websiteLanguages
              : []
          )
        : true
    )?_c('Select',{attrs:{"label":"Website Languages","options":_vm.websiteLanguages,"optionalLabel":function (option) { return option.name; },"optionalValue":"id","inline":"","halfWidth":"","name":'websiteLanguages',"multiple":"","selected":_vm.brokerData ? _vm.brokerData.websiteLanguages : '',"changesSelected":_vm.brokerChanges ? _vm.brokerChanges.websiteLanguages : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.supportLanguages ? _vm.brokerData.supportLanguages : [],
            _vm.brokerChanges && _vm.brokerChanges.supportLanguages
              ? _vm.brokerChanges.supportLanguages
              : []
          )
        : true
    )?_c('Select',{attrs:{"label":"Support Languages","options":_vm.supportLanguages,"optionalLabel":function (option) { return option.name
          ? option.name[0].toUpperCase() + option.name.slice(1)
          : option; },"optionalValue":"id","inline":"","halfWidth":"","name":'supportLanguages',"multiple":"","selected":_vm.brokerData ? _vm.brokerData.supportLanguages : '',"changesSelected":_vm.brokerChanges ? _vm.brokerChanges.supportLanguages : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.freeEducation,
            _vm.brokerChanges ? _vm.brokerChanges.freeEducation : null
          )
        : true
    )?_c('Radio',{attrs:{"halfWidth":true,"label":"Free Education","values":_vm.radioSelections,"name":'freeEducation',"selected":_vm.brokerData ? _vm.brokerData.freeEducation : '',"changesSelected":_vm.brokerChanges ? _vm.brokerChanges.freeEducation : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.freeEducation,
            _vm.brokerChanges ? _vm.brokerChanges.freeEducation : null
          )
        : true
    )?_c('div',{staticStyle:{"width":"50%"}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData &&
              _vm.brokerData.vpsState &&
              _vm.brokerData.vpsState.toLowerCase() !== 'no'
              ? _vm.brokerData.vpsState.toLowerCase()
              : 'no',
            _vm.brokerChanges && _vm.brokerChanges.vpsState !== 'no'
              ? _vm.brokerChanges.vpsState
              : 'no'
          )
        : true
    )?_c('Select',{attrs:{"label":"VPS for Real Traders","options":[
      {
        val: 'NO',
        name: 'No',
      },
      {
        val: 'FREE',
        name: 'Free',
      },
      {
        val: 'COMING_SOON',
        name: 'Coming soon',
      },
      {
        val: 'PRICE',
        name: 'Price, $:',
      },
      {
        val: 'DISCOUNT',
        name: 'Discount, %:',
      },
      {
        val: 'FREE_IF_DEPOSIT',
        name: 'Free if deposit >=',
      },
      {
        val: 'FREE_IF_LOTS',
        name: 'Free if lots >=',
      } ],"inline":"","halfWidth":"","optionalLabel":function (option) { return option.name; },"optionalValue":"val","name":"vpsState","selected":_vm.brokerData ? _vm.brokerData.vpsState : 'no',"changesSelected":_vm.brokerChanges ? _vm.brokerChanges.vpsState : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData &&
              _vm.brokerData.vpsState &&
              _vm.brokerData.vpsState.toLowerCase() !== 'no'
              ? _vm.brokerData.vpsState.toLowerCase()
              : 'no',
            _vm.brokerChanges && _vm.brokerChanges.vpsState !== 'no'
              ? _vm.brokerChanges.vpsState
              : 'no'
          )
        : true
    )?_c('div',{staticStyle:{"width":"50%"}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.vpsValue,
            _vm.brokerChanges ? _vm.brokerChanges.vpsValue : null
          )
        : (_vm.$route.name === 'brokerCreate' || _vm.$route.name === 'brokerEdit') &&
          (_vm.getBrokerData.vpsState.toLowerCase() === 'price' ||
            _vm.getBrokerData.vpsState.toLowerCase() === 'free_if_deposit' ||
            _vm.getBrokerData.vpsState.toLowerCase() === 'free_if_lots' ||
            _vm.getBrokerData.vpsState.toLowerCase() === 'discount')
        ? true
        : false
    )?_c('Input',{attrs:{"halfWidth":true,"name":'vpsValue',"label":" ","type":"number","value":_vm.brokerData ? +_vm.brokerData.vpsValue : '',"changesValue":_vm.brokerChanges ? +_vm.brokerChanges.vpsValue : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.vpsValue,
            _vm.brokerChanges ? _vm.brokerChanges.vpsValue : null
          )
        : (_vm.$route.name === 'brokerCreate' || _vm.$route.name === 'brokerEdit') &&
          (_vm.getBrokerData.vpsState.toLowerCase() === 'price' ||
            _vm.getBrokerData.vpsState.toLowerCase() === 'free_if_deposit' ||
            _vm.getBrokerData.vpsState.toLowerCase() === 'free_if_lots' ||
            _vm.getBrokerData.vpsState.toLowerCase() === 'discount')
        ? true
        : false
    )?_c('div',{staticStyle:{"width":"48%"},attrs:{"checker":_vm.$route.name === 'brokerReview' ? 'unset' : null}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.serverTimezone,
            _vm.brokerChanges ? _vm.brokerChanges.serverTimezone : null
          )
        : true
    )?_c('Select',{attrs:{"label":"Server Timezone","options":_vm.timezones,"inline":"","halfWidth":"","name":'serverTimezone',"selected":_vm.brokerData && _vm.brokerData.serverTimezone
        ? _vm.brokerData.serverTimezone
        : _vm.timezones[0],"changesSelected":_vm.brokerChanges ? _vm.brokerChanges.serverTimezone : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.serverTimezone,
            _vm.brokerChanges ? _vm.brokerChanges.serverTimezone : null
          )
        : true
    )?_c('div',{staticStyle:{"width":"50%"}}):_vm._e(),(
      (_vm.$route.name !== 'brokerReview' && _vm.getSelectedTab.title !== 'logs') ||
      (_vm.$route.name === 'brokerEdit' && _vm.getSelectedTab.title !== 'logs')
    )?_c('p',{staticClass:"fieldset__title width-50"},[_vm._v(" All rebates are given in base currency per 100,000 lot: ")]):_vm._e(),(
      (_vm.$route.name !== 'brokerReview' && _vm.getSelectedTab.title !== 'logs') ||
      (_vm.$route.name === 'brokerEdit' && _vm.getSelectedTab.title !== 'logs')
    )?_c('div',{staticStyle:{"width":"50%"}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.stopOrdersRebates !== ''
              ? _vm.brokerData.stopOrdersRebates
              : '0',
            _vm.brokerChanges ? _vm.brokerChanges.stopOrdersRebates : null
          )
        : true
    )?_c('Input',{attrs:{"half-width":"","label":"Rebates for Stop Orders:","name":'stopOrdersRebates',"value":_vm.brokerData && _vm.brokerData.stopOrdersRebates !== ''
        ? _vm.brokerData.stopOrdersRebates
        : '0',"changesValue":_vm.brokerChanges ? _vm.brokerChanges.stopOrdersRebates : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.stopOrdersRebates !== ''
              ? _vm.brokerData.stopOrdersRebates
              : '0',
            _vm.brokerChanges ? _vm.brokerChanges.stopOrdersRebates : null
          )
        : true
    )?_c('div',{staticStyle:{"width":"50%"}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.limitOrdersRebates !== ''
              ? _vm.brokerData.limitOrdersRebates
              : '0',
            _vm.brokerChanges ? _vm.brokerChanges.limitOrdersRebates : null
          )
        : true
    )?_c('Input',{attrs:{"half-width":"","label":"Rebates for Limit Orders:","name":'limitOrdersRebates',"value":_vm.brokerData && _vm.brokerData.limitOrdersRebates !== ''
        ? _vm.brokerData.limitOrdersRebates
        : '0',"changesValue":_vm.brokerChanges ? _vm.brokerChanges.limitOrdersRebates : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.limitOrdersRebates !== ''
              ? _vm.brokerData.limitOrdersRebates
              : '0',
            _vm.brokerChanges ? _vm.brokerChanges.limitOrdersRebates : null
          )
        : true
    )?_c('div',{staticStyle:{"width":"50%"}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.allOrdersRebates !== ''
              ? _vm.brokerData.allOrdersRebates
              : '0',
            _vm.brokerChanges ? _vm.brokerChanges.allOrdersRebates : null
          )
        : true
    )?_c('Input',{attrs:{"half-width":"","label":"Rebates for All Orders:","name":'allOrdersRebates',"value":_vm.brokerData && _vm.brokerData.allOrdersRebates !== ''
        ? _vm.brokerData.allOrdersRebates
        : '0',"changesValue":_vm.brokerChanges ? _vm.brokerChanges.allOrdersRebates : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.allOrdersRebates !== ''
              ? _vm.brokerData.allOrdersRebates
              : '0',
            _vm.brokerChanges ? _vm.brokerChanges.allOrdersRebates : null
          )
        : true
    )?_c('div',{staticStyle:{"width":"50%"}}):_vm._e(),(
      _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? _vm.CompairingService.showField(
            _vm.brokerData.supportMethods ? _vm.brokerData.supportMethods : [],
            _vm.brokerChanges && _vm.brokerChanges.supportMethods
              ? _vm.brokerChanges.supportMethods
              : []
          )
        : true
    )?_c('Select',{attrs:{"label":"Support Methods","multiple":"","options":_vm.supportMethods,"halfWidth":"","optionalLabel":function (option) { return option.name
          ? option.name[0].toUpperCase() + option.name.slice(1)
          : option; },"name":'supportMethods',"optionalValue":"id","selected":_vm.brokerData ? _vm.brokerData.supportMethods : '',"changesSelected":_vm.brokerChanges ? _vm.brokerChanges.supportMethods : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
        ? 'unset'
        : null}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }