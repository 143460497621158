<template>
  <div class="radio" :class="halfWidth ? 'width-50' : ''">
    <span class="radio__label" :class="column ? 'radio__label--column' : ''">
      {{ label }}
    </span>
    <div class="radio__content">
      <fieldset
        class="radio__container"
        data-type="currentValue"
        :value="this.selected"
        :id="id"
        :name="name"
        :data-name="dataName"
        :data-optionId="currentOptionId"
      >
        <label
          class="radio__wrap"
          v-for="(radioButton, index) in values"
          :key="index"
        >
          <input
            type="radio"
            :value="radioButton.value"
            v-model="radio"
            :name="name"
            :id="id"
            :data-name="dataName"
            :data-optionId="currentOptionId"
            @change="checkDisabled"
          />
          <span> {{ radioButton.label }} </span>
        </label>
      </fieldset>
      <fieldset
        class="radio__container"
        data-type="changesValue"
        v-if="changesSelected !== undefined"
        :name="`changes-${name}`"
        :id="id"
        :data-name="dataName"
        :data-optionId="currentOptionId"
        :value="changesSelected"
      >
        <label
          class="radio__wrap"
          v-for="(radioButton, index) in values"
          :key="'changes-' + index"
        >
          <input
            type="radio"
            :value="radioButton.value"
            v-model="changesRadio"
            :name="`changes-${name}`"
            :id="id"
            :data-name="dataName"
            :data-optionId="currentOptionId"
            checked
          />
          <span> {{ radioButton.label }} </span>
        </label>
      </fieldset>
    </div>

    <Checker v-if="checker !== null" set-approve="setApprove" />

    <div v-if="tip" class="radio__tip">{{ tip }}</div>
  </div>
</template>

<script>
import Checker from "@/components/formFields/Checker.vue";
import { mapMutations } from "vuex";

export default {
  components: { Checker },
  name: "Radio",
  model: {
    prop: "modelValue",
    event: "input",
  },
  props: {
    label: String,
    values: Array,
    selected: [String, Boolean],
    changesSelected: [String, Boolean],
    checker: {
      type: String,
    },
    halfWidth: {
      type: Boolean,
      default: false,
    },
    column: {
      type: Boolean,
      default: false,
    },
    name: String,
    id: Number,
    dataName: {
      type: String,
    },
    optionId: [Number, String],
    tip: String,
    modelValue: {
      default: "",
    },
  },
  data() {
    return {
      approved: "",
      radio: "",
      changesRadio: "",
      updated: false,
    };
  },
  methods: {
    ...mapMutations(["setDisabledLanguage", "isFormChangedDetect"]),
    setSelected() {
      if (this.selected !== null && this.selected !== undefined) {
        let selectedIndex;
        if (typeof this.values[0].value === "string") {
          selectedIndex = this.values.findIndex(
            (item) => item.value.toLowerCase() === this.selected.toLowerCase()
          );
        } else {
          selectedIndex = this.values.findIndex(
            (item) =>
              item.value.toString().toLowerCase() ===
              this.selected.toString().toLowerCase()
          );
        }
        selectedIndex = selectedIndex === -1 ? 0 : selectedIndex;
        this.radio = this.values[selectedIndex].value;
      } else {
        this.radio = this.values[0].value;
      }
      if (this.changesSelected) {
        let selectedIndex;
        if (typeof this.values[0].value === String) {
          selectedIndex = this.values.findIndex(
            (item) =>
              item.value.toLowerCase() === this.changesSelected.toLowerCase()
          );
        } else {
          selectedIndex = this.values.findIndex(
            (item) =>
              item.value.toString().toLowerCase() ===
              this.changesSelected.toString().toLowerCase()
          );
        }
        selectedIndex = selectedIndex === -1 ? 0 : selectedIndex;
        this.changesRadio = this.values[selectedIndex].value;
      } else {
        this.changesRadio = this.values[0].value;
      }
    },
    checkDisabled() {
      if (this.name.startsWith("disabled")) {
        this.setDisabledLanguage({
          disabled: this.radio,
          languageSlug: Array.from(
            this.$el.closest(".translation_info__content").elements
          ).find((item) => item.name === "languageSlug").value,
        });
      }
      this.isFormChangedDetect(true);
    },
    setValue() {
      console.log("test");
    },
  },
  mounted() {
    this.setSelected();
  },
  computed: {
    currentValues: function () {
      let valuesToShow;

      if (this.checker) {
        if (typeof this.selected === "string") {
          valuesToShow = this.values.filter(
            (item) => item.value.toLowerCase() === this.selected.toLowerCase()
          );
        } else {
          valuesToShow =
            this.selected !== undefined && this.selected !== null
              ? this.values.filter(
                  (item) =>
                    item.value.toString().toLowerCase() ===
                    this.selected.toString().toLowerCase()
                )
              : this.values[0];
        }

        return valuesToShow;
      } else {
        return this.values;
      }
    },
    changesValues: function () {
      let valuesToShow;
      if (this.checker) {
        if (
          this.changesSelected !== undefined &&
          typeof this.changesSelected === "string"
        ) {
          valuesToShow = this.values.filter(
            (item) =>
              item.value.toLowerCase() === this.changesSelected.toLowerCase()
          );
        } else {
          valuesToShow = this.values.filter(
            (item) =>
              item.value.toString().toLowerCase() ===
              this.changesSelected.toString().toLowerCase()
          );
        }
        return valuesToShow;
      } else {
        return this.values;
      }
    },
    currentOptionId: function () {
      if (this.optionId !== undefined) {
        return this.optionId;
      } else {
        let id = Math.round(Math.random() * 10000);
        return id;
      }
    },
  },
  updated: function () {
    this.$nextTick(function () {
      if ((this.selected || this.selected === false) && !this.updated) {
        this.setSelected();

        this.updated = true;
      }
    });
  },
  watch: {
    "$route.name": function () {
      this.setSelected(this.selected);
      this.setSelected(this.changesSelected);
    },
    radio() {
      this.$emit("input", this.radio);
    },
    selected() {
      this.setSelected();
    },
  },
};
</script>
