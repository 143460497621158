<template>
  <div class="account_properties form-part">
    <!--    <Input-->
    <!--      half-width-->
    <!--      label="API url"-->
    <!--      name="api"-->
    <!--      type="url"-->
    <!--      :value="accountTypeData.api"-->
    <!--      :changesValue="accountTypeChanges ? accountTypeChanges.api : undefined"-->
    <!--      :checker="-->
    <!--        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'-->
    <!--          ? 'unset'-->
    <!--          : null-->
    <!--      "-->
    <!--      v-if="-->
    <!--        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'-->
    <!--          ? CompairingService.showField(-->
    <!--              accountTypeData.api ? accountTypeData.api : null,-->
    <!--              accountTypeChanges ? accountTypeChanges.api : null-->
    <!--            )-->
    <!--          : true-->
    <!--      "-->
    <!--    />-->

    <!--    <Input-->
    <!--      half-width-->
    <!--      label="Web based url"-->
    <!--      column-->
    <!--      name="web_based"-->
    <!--      type="url"-->
    <!--      :value="accountTypeData.web_based"-->
    <!--      :changesValue="-->
    <!--        accountTypeChanges ? accountTypeChanges.web_based : undefined-->
    <!--      "-->
    <!--      :checker="-->
    <!--        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'-->
    <!--          ? 'unset'-->
    <!--          : null-->
    <!--      "-->
    <!--      v-if="-->
    <!--        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'-->
    <!--          ? CompairingService.showField(-->
    <!--              accountTypeData.web_based ? accountTypeData.web_based : null,-->
    <!--              accountTypeChanges ? accountTypeChanges.web_based : null-->
    <!--            )-->
    <!--          : true-->
    <!--      "-->
    <!--    />-->
    <Select
      :optionalLabel="(option) => option.name"
      :options="operatingSystems"
      label="Operating system (OS)"
      optionalValue="id"
      multiple
      inline
      name="operatingSystems"
      :selected="accountTypeData ? accountTypeData.operatingSystems : ''"
      :changesSelected="
        accountTypeChanges ? accountTypeChanges.operatingSystems : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="showOption('operatingSystems')"
    />
    <Radio
      :halfWidth="true"
      label="Automated"
      :values="noYesOptions"
      name="automated"
      :selected="
        accountTypeData && accountTypeData.automated !== undefined
          ? accountTypeData.automated
          : noYesOptions[0].val
      "
      :changesSelected="
        accountTypeChanges ? accountTypeChanges.automated : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="showOption('automated')"
    />
    <Radio
      :halfWidth="true"
      label="One click trading"
      :values="noYesOptions"
      name="oneClick"
      :selected="
        accountTypeData && accountTypeData.oneClick !== undefined
          ? accountTypeData.oneClick
          : noYesOptions[0].val
      "
      :changesSelected="
        accountTypeChanges ? accountTypeChanges.oneClick : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="showOption('oneClick')"
    />
    <Radio
      :halfWidth="true"
      label="OCO"
      :values="noYesOptions"
      name="oco"
      :selected="
        accountTypeData && accountTypeData.oco !== undefined
          ? accountTypeData.oco
          : noYesOptions[0].val
      "
      :changesSelected="accountTypeChanges ? accountTypeChanges.oco : undefined"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="showOption('oco')"
    />
    <Radio
      :halfWidth="true"
      label="Pending orders"
      :values="noYesOptions"
      name="pendingOrders"
      :selected="
        accountTypeData && accountTypeData.pendingOrders !== undefined
          ? accountTypeData.pendingOrders
          : noYesOptions[0].val
      "
      :changesSelected="
        accountTypeChanges ? accountTypeChanges.pendingOrders : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="showOption('pendingOrders')"
    />
    <Radio
      :halfWidth="true"
      label="API"
      :values="noYesOptions"
      name="api"
      :selected="
        accountTypeData && accountTypeData.api !== undefined
          ? accountTypeData.api
          : noYesOptions[0].val
      "
      :changesSelected="accountTypeChanges ? accountTypeChanges.api : undefined"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="showOption('api')"
    />
    <Radio
      :halfWidth="true"
      label="Web based"
      :values="noYesOptions"
      name="web_based"
      :selected="
        accountTypeData && accountTypeData.web_based !== undefined
          ? accountTypeData.web_based
          : noYesOptions[0].val
      "
      :changesSelected="
        accountTypeChanges ? accountTypeChanges.web_based : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="showOption('web_based')"
    />
  </div>
</template>

<script>
// import Input from "@/components/formFields/Input";
import Select from "@/components/formFields/Select";
import Radio from "@/components/formFields/Radio.vue";
import { mapGetters } from "vuex";
import CompairingService from "@/services/CompairingService";
import { operatingSystems } from "@/components/graphQL/brokerQueries";

export default {
  name: "AccountProperties",
  components: {
    // Input,
    Select,
    Radio,
  },
  apollo: {
    operatingSystems: operatingSystems,
  },
  props: {
    accountTypeData: {
      type: Object,
      required: true,
    },
    accountTypeChanges: {
      type: Object,
    },
  },
  data() {
    return {
      CompairingService: new CompairingService(),
      noYesOptions: [
        {
          label: "No",
          value: false,
        },
        {
          label: "Yes",
          value: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getSelectedTab"]),
  },
  methods: {
    showOption(optionName) {
      if (
        !(
          this.$route.name === "brokerReview" ||
          this.getSelectedTab.title === "logs"
        )
      ) {
        return true;
      } else {
        return this.CompairingService.showField(
          this.accountTypeData[optionName] ||
            this.accountTypeData[optionName] === false
            ? this.accountTypeData[optionName]
            : "no",
          this.accountTypeChanges ? this.accountTypeChanges[optionName] : null
        );
      }
    },
  },
};
</script>
