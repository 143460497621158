<template>
  <div class="broker-status">
    <div class="form-part">
      <Radio
        label="Disable all"
        :values="radioSelections"
        :name="'disableAll'"
        :selected="brokerData ? brokerData.disableAll : ''"
        :changesSelected="undefined"
        tip="(disable broker display for all language versions)"
        :checker="null"
        v-if="
          !($route.name === 'brokerReview' || getSelectedTab.title === 'logs')
        "
        @input.capture="setMainDataDisabled"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Radio from "@/components/formFields/Radio";

export default {
  name: "DisableBroker",
  props: {
    brokerData: Object,
  },
  components: {
    Radio,
  },
  data() {
    return {
      radioSelections: [
        { label: "No", value: false },
        { label: "Yes", value: true },
      ],
      triggerChangeInData: false,
    };
  },
  computed: {
    ...mapGetters(["getSelectedTab", "getLangTabClickState"]),
  },
  methods: {
    setMainDataDisabled(value) {
      if (!this.triggerChangeInData) {
        this.triggerChangeInData = true;
        return;
      }

      let allTheSame = true;
      for (const check of Object.values(this.getLangTabClickState)) {
        if (!check) {
          allTheSame = false;
          break;
        }
      }

      if (allTheSame || value) {
        this.$parent.$refs.mainData
          .querySelectorAll(`input[name^=disabled][value=${value}]`)
          .forEach((el) => el.click());
      }
    },
  },
};
</script>

<style lang="scss">
.broker-status {
  margin-bottom: 35px;
  .radio {
    justify-content: flex-start;
    margin: 0;
    &__label {
      width: 157px;
      text-decoration: underline;
      margin-right: 0;
    }
    &__content {
      width: 100px;
    }
    &__tip {
      color: #01c211;
      font-style: italic;
      margin-left: 10px;
    }
  }
}
</style>
