<template>
  <div class="form-part">
    <Select
      label="Scalping allowed?"
      :options="permissionsOptions"
      :optionalLabel="(option) => option.name"
      optionalValue="val"
      halfWidth
      :selected="
        accountTypeData && accountTypeData.scalpingAllowed
          ? accountTypeData.scalpingAllowed
          : permissionsOptions[0].val
      "
      :changesSelected="
        accountTypeChanges ? accountTypeChanges.scalpingAllowed : undefined
      "
      name="scalpingAllowed"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              accountTypeData && accountTypeData.scalpingAllowed
                ? accountTypeData.scalpingAllowed.toLowerCase()
                : 'unknown',
              accountTypeChanges && accountTypeChanges.scalpingAllowed
                ? accountTypeChanges.scalpingAllowed.toLowerCase()
                : null
            )
          : true
      "
    />
    <LangTabs
      name="scalpingLimits"
      :languages="
        getCurrentMode === 'review' || getSelectedTab.title === 'logs'
          ? CompairingService.compareLangData(
              accountTypeData &&
                accountTypeData.scalpingExplanations &&
                accountTypeData.scalpingExplanations.length
                ? accountTypeData.scalpingExplanations
                : null,
              accountTypeChanges && accountTypeChanges.scalpingExplanations
                ? accountTypeChanges.scalpingExplanations
                : null
            )
          : !accountTypeChanges && getCurrentMode === 'review'
          ? CompairingService.compareLangData(
              accountTypeData.scalpingExplanations
            )
          : languages
      "
      :brokerData="accountTypeData.scalpingExplanations"
      :changesData="
        (getCurrentMode === 'review' || getSelectedTab.title === 'logs') &&
        accountTypeChanges &&
        accountTypeChanges.scalpingExplanations
          ? accountTypeChanges.scalpingExplanations
          : undefined
      "
      v-if="
        (accountTypeData &&
          accountTypeData.scalpingAllowed &&
          accountTypeData.scalpingAllowed.toUpperCase() ===
            'YES_WITH_LIMITS') ||
        (getAccountType &&
          getAccountType.scalpingAllowed &&
          getAccountType.scalpingAllowed.toUpperCase() === 'YES_WITH_LIMITS') ||
        ((getCurrentMode === 'review' || getSelectedTab.title === 'logs') &&
          ((getAccountType &&
            getAccountType.scalpingAllowed &&
            getAccountType.scalpingAllowed.toUpperCase() ===
              'YES_WITH_LIMITS') ||
            (accountTypeChanges &&
              accountTypeChanges.scalpingAllowed &&
              accountTypeChanges.scalpingAllowed.toUpperCase() ===
                'YES_WITH_LIMITS')))
      "
    />
    <Select
      label="EAs allowed?"
      :options="permissionsOptions"
      :optionalLabel="(option) => option.name"
      optionalValue="val"
      halfWidth
      :selected="
        accountTypeData && accountTypeData.eaAllowed
          ? accountTypeData.eaAllowed
          : permissionsOptions[0].val
      "
      :changesSelected="
        accountTypeChanges ? accountTypeChanges.eaAllowed : undefined
      "
      name="eaAllowed"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              accountTypeData && accountTypeData.eaAllowed
                ? accountTypeData.eaAllowed.toLowerCase()
                : 'unknown',
              accountTypeChanges && accountTypeChanges
                ? accountTypeChanges.eaAllowed.toLowerCase()
                : null
            )
          : true
      "
    />
    <LangTabs
      name="eaLimits"
      :languages="
        getCurrentMode === 'review' || getSelectedTab.title === 'logs'
          ? CompairingService.compareLangData(
              accountTypeData &&
                accountTypeData.eaExplanations &&
                accountTypeData.eaExplanations.length
                ? accountTypeData.eaExplanations
                : null,
              accountTypeChanges &&
                accountTypeChanges.eaExplanations &&
                accountTypeChanges.eaExplanations.length
                ? accountTypeChanges.eaExplanations
                : null
            )
          : !accountTypeChanges && getCurrentMode === 'review'
          ? CompairingService.compareLangData(accountTypeData.eaExplanations)
          : languages
      "
      :brokerData="accountTypeData.eaExplanations"
      :changesData="
        (getCurrentMode === 'review' || getSelectedTab.title === 'logs') &&
        accountTypeChanges &&
        accountTypeChanges.eaExplanations
          ? accountTypeChanges.eaExplanations
          : undefined
      "
      v-if="
        (accountTypeData.eaAllowed &&
          accountTypeData.eaAllowed.toUpperCase() === 'YES_WITH_LIMITS') ||
        (getAccountType.eaAllowed &&
          getAccountType.eaAllowed.toUpperCase() === 'YES_WITH_LIMITS') ||
        ((getCurrentMode === 'review' || getSelectedTab.title === 'logs') &&
          ((getAccountType &&
            getAccountType.eaAllowed &&
            getAccountType.eaAllowed.toUpperCase() === 'YES_WITH_LIMITS') ||
            (accountTypeChanges &&
              accountTypeChanges.eaAllowed &&
              accountTypeChanges.eaAllowed.toUpperCase() ===
                'YES_WITH_LIMITS')))
      "
    />
  </div>
</template>

<script>
import LangTabs from "@/components/formParts/LangTabs";
import Select from "@/components/formFields/Select";
import { languages } from "@/components/graphQL/brokerQueries";
import CompairingService from "@/services/CompairingService";
import { mapGetters } from "vuex";
export default {
  name: "AccountPermissions",
  components: { LangTabs, Select },
  apollo: {
    languages: languages,
  },
  props: {
    accountTypeData: Object,
    accountTypeChanges: Object,
  },
  data() {
    return {
      permissionsOptions: [
        {
          name: "Unknown",
          val: "UNKNOWN",
        },
        {
          name: "No",
          val: "NO",
        },
        {
          name: "Yes",
          val: "YES",
        },
        {
          name: "Encouraged",
          val: "ENCOURAGED",
        },
        {
          name: "Yes, but limits apply",
          val: "YES_WITH_LIMITS",
        },
      ],
      CompairingService: new CompairingService(),
    };
  },
  computed: {
    ...mapGetters(["getCurrentMode", "getAccountType", "getSelectedTab"]),
  },
};
</script>
