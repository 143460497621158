var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lang_tabs",attrs:{"id":_vm.name}},[_c('div',{staticClass:"lang_tabs__header"},_vm._l((_vm.languages),function(lang,index){return _c('button',{key:index,staticClass:"lang_tabs__toggler",class:_vm.currentTab === lang.name ? 'lang_tabs__toggler--active' : '',attrs:{"type":"button"},on:{"click":function($event){_vm.currentTab = lang.name}}},[_vm._v(" "+_vm._s(lang.name)+" ")])}),0),_c('div',{staticClass:"translation_info"},_vm._l((_vm.languages),function(lang,index){return _c('fieldset',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === lang.name),expression:"currentTab === lang.name"}],key:index,staticClass:"translation_info__content",attrs:{"name":_vm.name}},[(_vm.name === 'mainData')?_c('div',[_c('p',{staticClass:"translation_info__text"},[_vm._v(" If any non-English value is left empty, English will be used instead. ")]),(
            _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? _vm.CompairingService.showField(
                  _vm.brokerData && _vm.brokerData.length
                    ? _vm.brokerData.find(
                        function (item) { return item.languageSlug === lang.slug; }
                      ).name
                    : null,
                  _vm.changesData
                    ? _vm.changesData.find(
                        function (item) { return item.languageSlug === lang.slug; }
                      ).name
                    : null
                )
              : true
          )?_c('Input',{attrs:{"label":"Broker Name","required":lang.name.toLowerCase() === 'english',"name":"name","error":_vm.engNameInvalid && lang.name.toLowerCase() === 'english'
              ? 'Поле заполнено с ошибкой'
              : '',"value":_vm.brokerData && _vm.brokerData.length
              ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; })
                  .name
              : '',"changesValue":_vm.changesData
              ? _vm.changesData.find(function (data) { return data.languageSlug === lang.slug; })
                  .name
              : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? 'unset'
              : null}}):_vm._e(),(
            _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? _vm.CompairingService.showField(
                  _vm.brokerData && _vm.brokerData.length
                    ? _vm.brokerData.find(
                        function (item) { return item.languageSlug === lang.slug; }
                      ).link
                    : null,
                  _vm.changesData
                    ? _vm.changesData.find(
                        function (item) { return item.languageSlug === lang.slug; }
                      ).link
                    : null
                )
              : true
          )?_c('Input',{attrs:{"label":"Link","type":"url","showLink":"","name":'link',"value":_vm.brokerData && _vm.brokerData.length
              ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; })
                  .link
              : '',"changesValue":_vm.changesData
              ? _vm.changesData.find(function (data) { return data.languageSlug === lang.slug; })
                  .link
              : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? 'unset'
              : null}}):_vm._e(),(
            _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? _vm.CompairingService.showField(
                  _vm.brokerData && _vm.brokerData.length
                    ? _vm.brokerData.find(
                        function (item) { return item.languageSlug === lang.slug; }
                      ).disabled
                    : null,
                  _vm.changesData
                    ? _vm.changesData.find(
                        function (item) { return item.languageSlug === lang.slug; }
                      ).disabled
                    : null
                )
              : true
          )?_c('Radio',{key:index,attrs:{"label":"Broker is disabled","values":[
            { value: false, label: 'No' },
            { value: true, label: 'Yes' } ],"name":'disabled' + lang.name,"selected":_vm.brokerData && _vm.brokerData.length
              ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; })
                  .disabled
              : 'NO',"changesSelected":_vm.changesData
              ? _vm.changesData.find(function (data) { return data.languageSlug === lang.slug; })
                  .disabled
              : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? 'unset'
              : null},on:{"!input":function($event){return _vm.setDisableAll(lang.slug, $event)}}}):_vm._e(),_c('input',{attrs:{"type":"hidden","name":"id"},domProps:{"value":_vm.brokerData && _vm.brokerData.length
              ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; }).id
              : ''}})],1):_vm._e(),(_vm.name === 'vipCondition')?_c('div',[(
            _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? _vm.CompairingService.showField(
                  _vm.brokerData && _vm.brokerData.length
                    ? _vm.brokerData.find(
                        function (item) { return item.languageSlug === lang.slug; }
                      ).description
                    : null,
                  _vm.changesData
                    ? _vm.changesData.find(
                        function (item) { return item.languageSlug === lang.slug; }
                      ).description
                    : null
                )
              : true
          )?_c('Textarea',{attrs:{"label":"Conditions for VIP traders","name":'description',"value":_vm.brokerData && _vm.brokerData.length
              ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; })
                  .description
              : '',"changesValue":_vm.changesData
              ? _vm.changesData.find(function (data) { return data.languageSlug === lang.slug; })
                  .description
              : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? 'unset'
              : null}}):_vm._e(),_vm._v(" "),(
            (_vm.$route.name !== 'brokerReview' &&
              _vm.getSelectedTab.title !== 'logs') ||
            (_vm.$route.name === 'brokerEdit' && _vm.getSelectedTab.title !== 'logs')
          )?_c('p',{staticClass:"fieldset__title"},[_vm._v(" Links to inner page (if any non-English value is left empty, English will be used instead.) ")]):_vm._e(),(
            (_vm.$route.name !== 'brokerReview' &&
              _vm.getSelectedTab.title !== 'logs') ||
            (_vm.$route.name === 'brokerEdit' && _vm.getSelectedTab.title !== 'logs')
          )?_c('button',{staticClass:"btn btn-open-links",attrs:{"type":"button","name":"button"},on:{"click":function($event){$event.preventDefault();_vm.OpenMultipleLinks.showAll(
              $event.target
                .closest('div')
                .querySelector('.links_wrap')
                .querySelectorAll('input')
            )}}},[_vm._v(" Open all ")]):_vm._e(),_c('div',{staticClass:"links_wrap"},[(
              _vm.$route.name === 'brokerReview' ||
              _vm.getSelectedTab.title === 'logs'
                ? _vm.CompairingService.showField(
                    _vm.brokerData && _vm.brokerData.length
                      ? _vm.brokerData.find(
                          function (item) { return item.languageSlug === lang.slug; }
                        ).demoAccountLink
                      : null,
                    _vm.changesData
                      ? _vm.changesData.find(
                          function (item) { return item.languageSlug === lang.slug; }
                        ).demoAccountLink
                      : null
                  )
                : true
            )?_c('Input',{attrs:{"label":"Demo account","showLink":"","type":"url","name":'demoAccountLink',"value":_vm.brokerData && _vm.brokerData.length
                ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; })
                    .demoAccountLink
                : '',"changesValue":_vm.changesData
                ? _vm.changesData.find(function (data) { return data.languageSlug === lang.slug; })
                    .demoAccountLink
                : undefined,"checker":_vm.$route.name === 'brokerReview' ||
              _vm.getSelectedTab.title === 'logs'
                ? 'unset'
                : null}}):_vm._e(),(
              _vm.$route.name === 'brokerReview' ||
              _vm.getSelectedTab.title === 'logs'
                ? _vm.CompairingService.showField(
                    _vm.brokerData && _vm.brokerData.length
                      ? _vm.brokerData.find(
                          function (item) { return item.languageSlug === lang.slug; }
                        ).realAccountLink
                      : null,
                    _vm.changesData
                      ? _vm.changesData.find(
                          function (item) { return item.languageSlug === lang.slug; }
                        ).realAccountLink
                      : null
                  )
                : true
            )?_c('Input',{attrs:{"label":"Real account","showLink":"","type":"url","name":'realAccountLink',"value":_vm.brokerData && _vm.brokerData.length
                ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; })
                    .realAccountLink
                : '',"changesValue":_vm.changesData
                ? _vm.changesData.find(function (data) { return data.languageSlug === lang.slug; })
                    .realAccountLink
                : undefined,"checker":_vm.$route.name === 'brokerReview' ||
              _vm.getSelectedTab.title === 'logs'
                ? 'unset'
                : null}}):_vm._e(),(
              _vm.$route.name === 'brokerReview' ||
              _vm.getSelectedTab.title === 'logs'
                ? _vm.CompairingService.showField(
                    _vm.brokerData && _vm.brokerData.length
                      ? _vm.brokerData.find(
                          function (item) { return item.languageSlug === lang.slug; }
                        ).contractSpecsLink
                      : null,
                    _vm.changesData
                      ? _vm.changesData.find(
                          function (item) { return item.languageSlug === lang.slug; }
                        ).contractSpecsLink
                      : null
                  )
                : true
            )?_c('Input',{attrs:{"label":"Contract Specifications","showLink":"","type":"url","name":'contractSpecsLink',"value":_vm.brokerData && _vm.brokerData.length
                ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; })
                    .contractSpecsLink
                : '',"changesValue":_vm.changesData
                ? _vm.changesData.find(function (data) { return data.languageSlug === lang.slug; })
                    .contractSpecsLink
                : undefined,"checker":_vm.$route.name === 'brokerReview' ||
              _vm.getSelectedTab.title === 'logs'
                ? 'unset'
                : null}}):_vm._e()],1),(
            _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? _vm.CompairingService.showField(
                  _vm.brokerData && _vm.brokerData.length
                    ? _vm.brokerData.find(
                        function (item) { return item.languageSlug === lang.slug; }
                      ).specialConditions
                    : null,
                  _vm.changesData
                    ? _vm.changesData.find(
                        function (item) { return item.languageSlug === lang.slug; }
                      ).specialConditions
                    : null
                )
              : true
          )?_c('Textarea',{attrs:{"label":"Special conditions/offers","name":'specialConditions',"value":_vm.brokerData && _vm.brokerData.length
              ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; })
                  .specialConditions
              : '',"changesValue":_vm.changesData
              ? _vm.changesData.find(function (data) { return data.languageSlug === lang.slug; })
                  .specialConditions
              : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? 'unset'
              : null}}):_vm._e(),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"id"},domProps:{"value":_vm.brokerData && _vm.brokerData.length
              ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; }).id
              : ''}})],1):_vm._e(),(_vm.name === 'scalpingLimits')?_c('div',[(
            _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? _vm.CompairingService.showField(
                  _vm.brokerData && _vm.brokerData.length
                    ? _vm.brokerData.find(
                        function (item) { return item.languageSlug === lang.slug; }
                      ).description
                    : null,
                  _vm.changesData
                    ? _vm.changesData.find(
                        function (item) { return item.languageSlug === lang.slug; }
                      ).description
                    : null
                )
              : true
          )?_c('Textarea',{attrs:{"label":"Scalping Explanation","name":"description","value":_vm.brokerData && _vm.brokerData.length
              ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; })
                  .description
              : '',"changesValue":_vm.changesData
              ? _vm.changesData.find(function (data) { return data.languageSlug === lang.slug; })
                  .description
              : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? 'unset'
              : null}}):_vm._e(),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"id"},domProps:{"value":_vm.brokerData && _vm.brokerData.length
              ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; }).id
              : ''}})],1):_vm._e(),(
          _vm.name === 'history' &&
          ((_vm.getBrokerData.brokerHistory &&
            _vm.getBrokerData.brokerHistory.length &&
            _vm.currentHistoryData &&
            _vm.currentHistoryData.length) ||
            (_vm.getSelectedTab.title === 'logs' &&
              _vm.currentHistoryData &&
              _vm.currentHistoryData.length))
        )?_c('div',[_c('p',{staticClass:"fieldset__title"},[_vm._v("History")]),_vm._l((_vm.currentHistoryData.find(
            function (item) { return item.languageSlug === lang.slug; }
          ).data),function(historyItem){return _c('HistoryItem',{key:historyItem.id,attrs:{"id":historyItem.id,"lang":lang.slug,"data":_vm.getSelectedTab.title === 'logs'
              ? _vm.brokerData && _vm.brokerData.length
                ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; })
                    .data.length
                  ? _vm.brokerData
                      .find(function (item) { return item.languageSlug === lang.slug; })
                      .data.find(function (item) { return item.id === historyItem.id; })
                  : null
                : null
              : _vm.brokerData && _vm.brokerData.length
              ? _vm.brokerData
                  .find(function (item) { return item.languageSlug === lang.slug; })
                  .data.find(
                    function (changesHistoryItem) { return changesHistoryItem.id === historyItem.id; }
                  )
              : null,"changesData":_vm.changesData !== undefined
              ? _vm.changesData
                  .find(function (item) { return item.languageSlug === lang.slug; })
                  .data.find(
                    function (changesHistoryItem) { return changesHistoryItem.id === historyItem.id; }
                  ) || {}
              : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? 'unset'
              : null}})}),_c('input',{attrs:{"type":"hidden","name":"languageSlug"},domProps:{"value":lang.slug}}),_c('button',{staticClass:"add_history_item",attrs:{"type":"button"},on:{"click":function($event){_vm.createNewHistoryItem({
              lang: lang.slug,
              data: _vm.preparingDataServices.createHistoryItem(),
            })}}},[_c('img',{attrs:{"src":require("../../assets/svg/ico-plus.svg"),"alt":"+"}}),_c('span',[_vm._v(" Add ")])])],2):_vm._e(),(_vm.name === 'eaLimits')?_c('div',[(
            _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? _vm.CompairingService.showField(
                  _vm.brokerData && _vm.brokerData.length
                    ? _vm.brokerData.find(
                        function (item) { return item.languageSlug === lang.slug; }
                      ).description
                    : null,
                  _vm.changesData
                    ? _vm.changesData.find(
                        function (item) { return item.languageSlug === lang.slug; }
                      ).description
                    : null
                )
              : true
          )?_c('Textarea',{attrs:{"label":"EAs Explanation","name":"description","value":_vm.brokerData && _vm.brokerData.length
              ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; })
                  .description
              : '',"changesValue":_vm.changesData
              ? _vm.changesData.find(function (data) { return data.languageSlug === lang.slug; })
                  .description
              : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? 'unset'
              : null}}):_vm._e(),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"id"},domProps:{"value":_vm.brokerData && _vm.brokerData.length
              ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; }).id
              : ''}})],1):_vm._e(),(_vm.name === 'specialConditions')?_c('div',[(
            _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? _vm.CompairingService.showField(
                  _vm.brokerData && _vm.brokerData.length
                    ? _vm.brokerData.find(
                        function (item) { return item.languageSlug === lang.slug; }
                      ).description
                    : null,
                  _vm.changesData
                    ? _vm.changesData.find(
                        function (item) { return item.languageSlug === lang.slug; }
                      ).description
                    : null
                )
              : true
          )?_c('Textarea',{attrs:{"label":"Special conditions","name":"description","value":_vm.brokerData && _vm.brokerData.length
              ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; })
                  .description
              : '',"changesValue":_vm.changesData
              ? _vm.changesData.find(function (data) { return data.languageSlug === lang.slug; })
                  .description
              : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? 'unset'
              : null}}):_vm._e(),_c('input',{attrs:{"type":"hidden","name":"id"},domProps:{"value":_vm.brokerData && _vm.brokerData.length
              ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; }).id
              : ''}})],1):_vm._e(),(_vm.name === 'brokerNotes')?_c('div',[(
            _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? _vm.CompairingService.showField(
                  _vm.brokerData && _vm.brokerData.length
                    ? _vm.brokerData.find(
                        function (item) { return item.languageSlug === lang.slug; }
                      ).description
                    : null,
                  _vm.changesData
                    ? _vm.changesData.find(
                        function (item) { return item.languageSlug === lang.slug; }
                      ).description
                    : null
                )
              : true
          )?_c('Textarea',{attrs:{"label":"Admin`s notes","name":"description","value":_vm.brokerData && _vm.brokerData.length
              ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; })
                  .description
              : '',"changesValue":_vm.changesData
              ? _vm.changesData.find(function (data) { return data.languageSlug === lang.slug; })
                  .description
              : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? 'unset'
              : null}}):_vm._e(),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"id"},domProps:{"value":_vm.brokerData && _vm.brokerData.length
              ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; }).id
              : ''}})],1):_vm._e(),(_vm.name === 'brokerWarningSings')?_c('div',[(
            _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? _vm.CompairingService.showField(
                  _vm.brokerData && _vm.brokerData.length
                    ? _vm.brokerData.find(
                        function (item) { return item.languageSlug === lang.slug; }
                      ).description
                    : null,
                  _vm.changesData
                    ? _vm.changesData.find(
                        function (item) { return item.languageSlug === lang.slug; }
                      ).description
                    : null
                )
              : true
          )?_c('Textarea',{attrs:{"label":"Warning signs","name":"description","value":_vm.brokerData && _vm.brokerData.length
              ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; })
                  .description
              : '',"changesValue":_vm.changesData
              ? _vm.changesData.find(function (data) { return data.languageSlug === lang.slug; })
                  .description
              : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? 'unset'
              : null}}):_vm._e(),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"id"},domProps:{"value":_vm.brokerData && _vm.brokerData.length
              ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; }).id
              : ''}})],1):_vm._e(),(
          _vm.name === 'companyBlock' &&
          ((_vm.getBrokerData.brokerCompanies &&
            _vm.getBrokerData.brokerCompanies.length &&
            _vm.currentBrokerCompanies &&
            _vm.currentBrokerCompanies.length) ||
            (_vm.getSelectedTab.title === 'logs' &&
              _vm.currentBrokerCompanies &&
              _vm.currentBrokerCompanies.length))
        )?_c('div',[_c('p',{staticClass:"fieldset__title"},[_vm._v("Companies")]),_vm._l((_vm.currentBrokerCompanies.filter(
            function (item) { return item.languageSlug === lang.slug; }
          )),function(source){return _c('CompanyBlock',{key:source.id,attrs:{"id":source.id,"source":_vm.getSelectedTab.title === 'logs'
              ? _vm.brokerData && _vm.brokerData.length
                ? _vm.brokerData.find(function (item) { return item.id === source.id; }) || {}
                : {}
              : _vm.brokerData && _vm.brokerData.length
              ? _vm.brokerData.find(function (item) { return item.id === source.id; }) || {}
              : {},"changedSource":_vm.changesData !== undefined
              ? _vm.changesData.find(function (item) { return item.id === source.id; }) || {}
              : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? 'unset'
              : null,"language":lang.slug}})}),_c('input',{attrs:{"type":"hidden","name":"languageSlug"},domProps:{"value":lang.slug}}),(
            (_vm.$route.name === 'brokerEdit' ||
              _vm.$route.name === 'brokerCreate') &&
            _vm.currentBrokerCompanies.filter(
              function (item) { return item.languageSlug === lang.slug; }
            ).length < 10
          )?_c('button',{staticClass:"add_company_block",attrs:{"type":"button"},on:{"click":function($event){_vm.createNewCompanyBlock(Object.assign({}, {languageSlug: lang.slug},
              _vm.preparingDataServices.createBrokerCompany()))}}},[_c('img',{attrs:{"src":require("../../assets/svg/ico-plus.svg"),"alt":"+"}}),_c('span',[_vm._v("Add company")])]):_vm._e()],2):_vm._e(),(_vm.name === 'brokerFlags')?_c('div',[(
            _vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? _vm.CompairingService.showField(
                  _vm.brokerData && _vm.brokerData.length
                    ? _vm.brokerData.find(
                        function (item) { return item.languageSlug === lang.slug; }
                      ).flags
                    : null,
                  _vm.changesData
                    ? _vm.changesData.find(
                        function (item) { return item.languageSlug === lang.slug; }
                      ).flags
                    : null
                )
              : true
          )?_c('Textarea',{attrs:{"label":"Custom flags","desc":"Each flag on a new line","name":"flags","value":_vm.brokerData && _vm.brokerData.length
              ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; })
                  .flags
              : '',"changesValue":_vm.changesData
              ? _vm.changesData.find(function (data) { return data.languageSlug === lang.slug; })
                  .flags
              : undefined,"checker":_vm.$route.name === 'brokerReview' || _vm.getSelectedTab.title === 'logs'
              ? 'unset'
              : null}}):_vm._e(),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"id"},domProps:{"value":_vm.brokerData && _vm.brokerData.length
              ? _vm.brokerData.find(function (item) { return item.languageSlug === lang.slug; }).id
              : ''}})],1):_vm._e(),_c('input',{attrs:{"type":"hidden","name":"languageSlug"},domProps:{"value":lang.slug}})])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }