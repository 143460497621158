class BrokerDemoPlatform {
  constructor(id) {
    this.id = id;
    this.demoTradingPlatformId = "";
    this.demoTradingPlatformCondition = "YES";
    this.demoTradingPlatformConditionDays = null;
  }
}

export default BrokerDemoPlatform;
