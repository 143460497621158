<template>
  <div class="form-part">
    <Select
      label="Website Languages"
      :options="websiteLanguages"
      :optionalLabel="(option) => option.name"
      optionalValue="id"
      inline
      halfWidth
      :name="'websiteLanguages'"
      multiple
      :selected="brokerData ? brokerData.websiteLanguages : ''"
      :changesSelected="
        brokerChanges ? brokerChanges.websiteLanguages : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.websiteLanguages ? brokerData.websiteLanguages : [],
              brokerChanges && brokerChanges.websiteLanguages
                ? brokerChanges.websiteLanguages
                : []
            )
          : true
      "
    />
    <Select
      label="Support Languages"
      :options="supportLanguages"
      :optionalLabel="
        (option) =>
          option.name
            ? option.name[0].toUpperCase() + option.name.slice(1)
            : option
      "
      optionalValue="id"
      inline
      halfWidth
      :name="'supportLanguages'"
      multiple
      :selected="brokerData ? brokerData.supportLanguages : ''"
      :changesSelected="
        brokerChanges ? brokerChanges.supportLanguages : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.supportLanguages ? brokerData.supportLanguages : [],
              brokerChanges && brokerChanges.supportLanguages
                ? brokerChanges.supportLanguages
                : []
            )
          : true
      "
    />
    <Radio
      :halfWidth="true"
      label="Free Education"
      :values="radioSelections"
      :name="'freeEducation'"
      :selected="brokerData ? brokerData.freeEducation : ''"
      :changesSelected="brokerChanges ? brokerChanges.freeEducation : undefined"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.freeEducation,
              brokerChanges ? brokerChanges.freeEducation : null
            )
          : true
      "
    />
    <div
      style="width: 50%"
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.freeEducation,
              brokerChanges ? brokerChanges.freeEducation : null
            )
          : true
      "
    ></div>
    <Select
      label="VPS for Real Traders"
      :options="[
        {
          val: 'NO',
          name: 'No',
        },
        {
          val: 'FREE',
          name: 'Free',
        },
        {
          val: 'COMING_SOON',
          name: 'Coming soon',
        },
        {
          val: 'PRICE',
          name: 'Price, $:',
        },
        {
          val: 'DISCOUNT',
          name: 'Discount, %:',
        },
        {
          val: 'FREE_IF_DEPOSIT',
          name: 'Free if deposit >=',
        },
        {
          val: 'FREE_IF_LOTS',
          name: 'Free if lots >=',
        },
      ]"
      inline
      halfWidth
      :optionalLabel="(option) => option.name"
      optionalValue="val"
      name="vpsState"
      :selected="brokerData ? brokerData.vpsState : 'no'"
      :changesSelected="brokerChanges ? brokerChanges.vpsState : undefined"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData &&
                brokerData.vpsState &&
                brokerData.vpsState.toLowerCase() !== 'no'
                ? brokerData.vpsState.toLowerCase()
                : 'no',
              brokerChanges && brokerChanges.vpsState !== 'no'
                ? brokerChanges.vpsState
                : 'no'
            )
          : true
      "
    />
    <div
      style="width: 50%"
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData &&
                brokerData.vpsState &&
                brokerData.vpsState.toLowerCase() !== 'no'
                ? brokerData.vpsState.toLowerCase()
                : 'no',
              brokerChanges && brokerChanges.vpsState !== 'no'
                ? brokerChanges.vpsState
                : 'no'
            )
          : true
      "
    ></div>
    <Input
      :halfWidth="true"
      :name="'vpsValue'"
      label=" "
      type="number"
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.vpsValue,
              brokerChanges ? brokerChanges.vpsValue : null
            )
          : ($route.name === 'brokerCreate' || $route.name === 'brokerEdit') &&
            (getBrokerData.vpsState.toLowerCase() === 'price' ||
              getBrokerData.vpsState.toLowerCase() === 'free_if_deposit' ||
              getBrokerData.vpsState.toLowerCase() === 'free_if_lots' ||
              getBrokerData.vpsState.toLowerCase() === 'discount')
          ? true
          : false
      "
      :value="brokerData ? +brokerData.vpsValue : ''"
      :changesValue="brokerChanges ? +brokerChanges.vpsValue : undefined"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
    />
    <div
      style="width: 48%"
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.vpsValue,
              brokerChanges ? brokerChanges.vpsValue : null
            )
          : ($route.name === 'brokerCreate' || $route.name === 'brokerEdit') &&
            (getBrokerData.vpsState.toLowerCase() === 'price' ||
              getBrokerData.vpsState.toLowerCase() === 'free_if_deposit' ||
              getBrokerData.vpsState.toLowerCase() === 'free_if_lots' ||
              getBrokerData.vpsState.toLowerCase() === 'discount')
          ? true
          : false
      "
      :checker="$route.name === 'brokerReview' ? 'unset' : null"
    ></div>
    <Select
      label="Server Timezone"
      :options="timezones"
      inline
      halfWidth
      :name="'serverTimezone'"
      :selected="
        brokerData && brokerData.serverTimezone
          ? brokerData.serverTimezone
          : timezones[0]
      "
      :changesSelected="
        brokerChanges ? brokerChanges.serverTimezone : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.serverTimezone,
              brokerChanges ? brokerChanges.serverTimezone : null
            )
          : true
      "
    />
    <div
      style="width: 50%"
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.serverTimezone,
              brokerChanges ? brokerChanges.serverTimezone : null
            )
          : true
      "
    ></div>
    <p
      class="fieldset__title width-50"
      v-if="
        ($route.name !== 'brokerReview' && getSelectedTab.title !== 'logs') ||
        ($route.name === 'brokerEdit' && getSelectedTab.title !== 'logs')
      "
    >
      All rebates are given in base currency per 100,000 lot:
    </p>
    <div
      style="width: 50%"
      v-if="
        ($route.name !== 'brokerReview' && getSelectedTab.title !== 'logs') ||
        ($route.name === 'brokerEdit' && getSelectedTab.title !== 'logs')
      "
    ></div>
    <Input
      half-width
      label="Rebates for Stop Orders:"
      :name="'stopOrdersRebates'"
      :value="
        brokerData && brokerData.stopOrdersRebates !== ''
          ? brokerData.stopOrdersRebates
          : '0'
      "
      :changesValue="
        brokerChanges ? brokerChanges.stopOrdersRebates : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.stopOrdersRebates !== ''
                ? brokerData.stopOrdersRebates
                : '0',
              brokerChanges ? brokerChanges.stopOrdersRebates : null
            )
          : true
      "
    />
    <div
      style="width: 50%"
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.stopOrdersRebates !== ''
                ? brokerData.stopOrdersRebates
                : '0',
              brokerChanges ? brokerChanges.stopOrdersRebates : null
            )
          : true
      "
    ></div>
    <Input
      half-width
      label="Rebates for Limit Orders:"
      :name="'limitOrdersRebates'"
      :value="
        brokerData && brokerData.limitOrdersRebates !== ''
          ? brokerData.limitOrdersRebates
          : '0'
      "
      :changesValue="
        brokerChanges ? brokerChanges.limitOrdersRebates : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.limitOrdersRebates !== ''
                ? brokerData.limitOrdersRebates
                : '0',
              brokerChanges ? brokerChanges.limitOrdersRebates : null
            )
          : true
      "
    />
    <div
      style="width: 50%"
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.limitOrdersRebates !== ''
                ? brokerData.limitOrdersRebates
                : '0',
              brokerChanges ? brokerChanges.limitOrdersRebates : null
            )
          : true
      "
    ></div>
    <Input
      half-width
      label="Rebates for All Orders:"
      :name="'allOrdersRebates'"
      :value="
        brokerData && brokerData.allOrdersRebates !== ''
          ? brokerData.allOrdersRebates
          : '0'
      "
      :changesValue="brokerChanges ? brokerChanges.allOrdersRebates : undefined"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.allOrdersRebates !== ''
                ? brokerData.allOrdersRebates
                : '0',
              brokerChanges ? brokerChanges.allOrdersRebates : null
            )
          : true
      "
    />
    <div
      style="width: 50%"
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.allOrdersRebates !== ''
                ? brokerData.allOrdersRebates
                : '0',
              brokerChanges ? brokerChanges.allOrdersRebates : null
            )
          : true
      "
    ></div>
    <Select
      label="Support Methods"
      multiple
      :options="supportMethods"
      halfWidth
      :optionalLabel="
        (option) =>
          option.name
            ? option.name[0].toUpperCase() + option.name.slice(1)
            : option
      "
      :name="'supportMethods'"
      optionalValue="id"
      :selected="brokerData ? brokerData.supportMethods : ''"
      :changesSelected="
        brokerChanges ? brokerChanges.supportMethods : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.supportMethods ? brokerData.supportMethods : [],
              brokerChanges && brokerChanges.supportMethods
                ? brokerChanges.supportMethods
                : []
            )
          : true
      "
    />
  </div>
</template>
<script>
import Select from "@/components/formFields/Select";
import Radio from "@/components/formFields/Radio";
import Input from "@/components/formFields/Input";
import {
  websiteLanguages,
  supportLanguages,
  supportMethods,
} from "@/components/graphQL/brokerQueries";
import { mapGetters } from "vuex";
import _ from "lodash";
import CompairingService from "@/services/CompairingService";

export default {
  name: "BrokerWebsite",
  components: {
    Select,
    Radio,
    Input,
  },
  apollo: {
    websiteLanguages: websiteLanguages,
    supportLanguages: supportLanguages,
    supportMethods: supportMethods,
  },
  computed: {
    ...mapGetters(["getBrokerData", "getSelectedTab"]),
    _() {
      return _;
    },
  },
  props: {
    brokerData: Object,
    brokerChanges: Object,
  },
  data() {
    return {
      timezones: [
        "GMT",
        "GMT+1",
        "CET",
        "BST",
        "GMT-1",
        "GMT+2",
        "EET",
        "GMT-2",
        "GMT+3",
        "GMT-3",
        "GMT+4",
        "GMT-4",
        "GMT+5",
        "GMT-5",
        "EST",
        "GMT+6",
        "GMT-6",
        "GMT+7",
        "GMT-7",
        "GMT+8",
        "GMT-8",
        "PST",
        "GMT+9",
        "GMT-9",
        "GMT+10",
        "GMT-10",
        "GMT+11",
        "GMT-11",
        "GMT+12",
        "GMT-12",
      ],
      radioSelections: [
        { label: "No", value: "NO" },
        { label: "Yes", value: "YES" },
        { label: "Coming Soon", value: "COMING_SOON" },
      ],
      CompairingService: new CompairingService(),
    };
  },
};
</script>
