<template>
  <div>
    <LangTabs
      name="specialConditions"
      :languages="
        getCurrentMode === 'review' || getSelectedTab.title === 'logs'
          ? CompairingService.compareLangData(
              accountTypeData && accountTypeData.length
                ? accountTypeData
                : null,
              accountTypeChanges && accountTypeChanges.length
                ? accountTypeChanges
                : null
            )
          : languages
      "
      :brokerData="accountTypeData"
      :changesData="
        (getCurrentMode === 'review' || getSelectedTab.title === 'logs') &&
        accountTypeChanges &&
        accountTypeChanges.length
          ? accountTypeChanges
          : undefined
      "
    />
  </div>
</template>
<script>
import { languages } from "@/components/graphQL/brokerQueries";
import LangTabs from "@/components/formParts/LangTabs";
import { mapGetters } from "vuex";
import CompairingService from "@/services/CompairingService";

export default {
  name: "SpecialConditions",
  apollo: {
    languages: languages,
  },
  components: {
    LangTabs,
  },
  props: {
    accountTypeData: Array,
    accountTypeChanges: Array,
  },
  data() {
    return {
      CompairingService: new CompairingService(),
    };
  },

  computed: {
    ...mapGetters(["getCurrentMode", "getSelectedTab"]),
  },
};
</script>
