<template>
  <a href="#" class="scrollTop"
    ><svg
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.994141 8.5293L8.06521 1.45823L15.1363 8.5293"
        stroke="white"
      />
    </svg>
  </a>
</template>

<script>
export default {
  name: "ScrollTop",
};
</script>
