<template>
  <div class="tabs" id="tabs" v-if="getSelectedTab">
    <header class="tabs__header" :key="getSelectedTab.title || 'tab'">
      <button
        class="tabs__toggler"
        :class="[
          {
            'tabs__toggler--active':
              getSelectedTab.title.toLowerCase() === tab.title.toLowerCase(),
            'tabs__toggler--star': tab.star,
          },
        ]"
        v-for="(tab, tabIndex) in tabsList"
        :key="tabIndex"
        @click="changeTab(tab)"
        :disabled="tab.disabled"
      >
        {{ tab.title }}
      </button>
    </header>
    <div class="tabs__content">
      <AccountTypes
        v-if="getSelectedTab && getSelectedTab.title === 'account types'"
        :key="'acctype' + new Date().getMilliseconds()"
      />
      <Log
        v-if="
          getSelectedTab &&
          getSelectedTab.title === 'logs' &&
          !getSelectedTab.innerTab
        "
      />
      <BrokerRollback
        v-if="
          getSelectedTab &&
          getSelectedTab.title === 'logs' &&
          getSelectedTab.innerTab === 'broker rollback'
        "
      />
      <BrokerForm
        v-if="getSelectedTab && getSelectedTab.title === 'main data'"
        :key="'maindata' + new Date().getMilliseconds()"
      />
    </div>
  </div>
</template>

<script>
import AccountTypes from "@/components/tabs/AccountTypes.vue";
import Log from "@/components/tabs/Log.vue";
import BrokerRollback from "@/components/tabs/BrokerRollback.vue";
import BrokerForm from "@/components/tabs/BrokerForm";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Tabs",
  props: {
    tabsList: Array,
    creating: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AccountTypes,
    Log,
    BrokerForm,
    BrokerRollback,
  },
  data() {
    return {};
  },
  created() {
    this.setSelectedTab(
      this.$route.params.tab
        ? this.tabsList[
            this.tabsList.findIndex(
              (tab) =>
                this.$route.params.tab.toLowerCase() === tab.title.toLowerCase()
            )
          ]
        : this.tabsList[0]
    );
  },
  computed: {
    ...mapGetters(["getIsChanged", "getSelectedTab"]),
  },
  methods: {
    ...mapMutations(["isFormChangedDetect", "setSelectedTab", "setLinkToGo"]),
    changeTab(tabToGo) {
      if (this.getIsChanged) {
        document.querySelector("#unsavedData").classList.add("modal--open");
        this.setLinkToGo({ link: tabToGo, type: "tab" });
      } else {
        this.setSelectedTab(tabToGo);
      }

      // set page title
      let title = document.title.split('|');
      if (title[0]) {
        switch (tabToGo.title) {
          case 'account types':
            title[0] = ' New Account '
            break;
          case 'logs':
            title[0] = ' Logs '
            break;
          default:
            title[0] = ' Main Data '
        }
      }
      document.title = title.join('|');
    },
  },
};
</script>
