<template>
  <div class="broker-data">
    <div
      v-if="$route.params.id && relatedBrokers.length"
      class="broker-data__header"
    >
      <div class="broker-data__tabs">
        <router-link
          :id="`broker-${parentBroker.id}`"
          v-if="parentBroker"
          :to="`/edit_broker/${parentBroker.id}`"
          class="broker-data__tabs-item"
        >
          <span class="broker-data__tabs-item-label">{{
            parentBroker.name
          }}</span>
          <span
            :class="[
              'broker-data__tabs-item-status',
              {
                '--review': toReview(parentBroker),
                '--updated': !toReview(parentBroker),
              },
            ]"
            >{{
              toReview(parentBroker) ? "need to review" : "up-to-date"
            }}</span
          >
        </router-link>

        <router-link
          v-for="item in relatedBrokersFiltered"
          :key="item.id"
          :id="`broker-${item.id}`"
          :to="`/edit_broker/${item.id}`"
          class="broker-data__tabs-item"
        >
          <span class="broker-data__tabs-item-label">{{ item.name }}</span>
          <span
            :class="[
              'broker-data__tabs-item-status',
              {
                '--review': toReview(item),
                '--updated': !toReview(item),
              },
            ]"
            >{{ toReview(item) ? "need to review" : "up-to-date" }}</span
          >
        </router-link>
      </div>

      <router-link
        :to="`/broker/add_broker?broker=${
          parentBroker && parentBroker.id ? parentBroker.id : $route.params.id
        }`"
        class="broker-data__add-broker"
      >
        <img src="@/assets/svg/ico-plus.svg" alt="+" />
        <span>Add sub-broker</span>
      </router-link>
    </div>

    <div v-if="$route.query.broker" class="broker-data__back-to-broker">
      <button class="btn" @click="goBack">Back to main broker</button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SubBrokersNav",
  computed: {
    ...mapState({
      currentBrokerMainData: (state) => state.brokers.currentBrokerMainData,
    }),
    ...mapState({
      parentBrokerMainData: (state) => state.brokers.parentBrokerMainData,
    }),
    ...mapState({ relatedBrokers: (state) => state.brokers.relatedBrokers }),

    parentBroker() {
      if (this.parentBrokerMainData.id) {
        return this.relatedBrokers.find(
          (i) => i.id.toString() === this.parentBrokerMainData.id.toString()
        );
      } else {
        return {
          id: this.currentBrokerMainData.id,
          name: this.currentBrokerMainData.name,
          reviewed: this.currentBrokerMainData.reviewed,
        };
      }
    },
    relatedBrokersFiltered() {
      if (!this.relatedBrokers.length) return [];

      let tempArr = [];

      if (this.parentBrokerMainData.id) {
        tempArr = this.relatedBrokers.filter(
          (i) => i.id.toString() !== this.parentBrokerMainData.id.toString()
        );

        tempArr.push({
          id: this.currentBrokerMainData.id,
          name: this.currentBrokerMainData.name,
          reviewed: this.currentBrokerMainData.reviewed,
        });
      } else {
        tempArr = this.relatedBrokers;
      }

      return tempArr.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
    },
  },
  methods: {
    goBack() {
      window.history.length > 1
        ? this.$router.go(-1)
        : this.$router.push(`/edit_broker/${this.$route.query.broker}`);
    },
    toReview(item) {
      return (
        item.reviewed.toLowerCase() === "none" ||
        item.reviewed.toLowerCase() === "wait_after_update"
      );
    },
  },
};
</script>

<style lang="scss">
.broker-data {
  border-bottom: 2 px solid var(--accent);
  margin-bottom: 25px;
  &__header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 3px;
  }
  &__tabs {
    overflow: auto;
    white-space: nowrap;
    max-width: 930px;
    padding-bottom: 3px;
    @media (min-width: 992px) {
      scrollbar-color: #dcf2eb transparent;
      scrollbar-width: thin;
      &::-webkit-scrollbar-track {
        background-color: #dcf2eb;
      }
      &::-webkit-scrollbar {
        height: 10px;
        border-radius: 8px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--accent);
        width: 10px;
        border-radius: 8px;
      }
    }
    &-item {
      border: 1px solid var(--borders-color);
      display: inline-block;
      padding: 8px 15px;
      color: black;
      font-size: 24px;
      line-height: 1;
      flex-shrink: 0;
      white-space: nowrap;
      &.router-link-active {
        color: white;
        background-color: var(--accent);
      }
      &-label {
        display: block;
      }
      &-status {
        display: inline-block;
        padding: 1px 5px 0 20px;
        border-radius: 11px;
        font: 400 10px/16px "PFDinDisplayPro";
        background-size: 10px;
        background-position: 5px center;
        background-repeat: no-repeat;
        background-color: #f2f8f6;
        color: var(--main-bg);
        vertical-align: middle;
        &.--review {
          background-image: url(../assets/svg/ico-timer.svg);
          background-color: #f9e55b;
        }
        &.--updated {
          background-image: url(../assets/svg/ico-ok.svg);
        }
        &.--canceled {
          background-image: url(../assets/svg/ico-cancel.svg);
        }
      }
    }
    a.broker-data__tabs-item:hover {
      color: white;
      background-color: var(--accent);
    }
  }
  &__add-broker {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    color: var(--accent);
    font-size: 14px;
    border: none;
    background: none;
    cursor: pointer;
    &:hover img {
      transform: translateX(5px);
    }
    img {
      margin-right: 10px;
      transition: 0.3s;
    }
    span {
      padding: 0 0 2px;
      border-bottom: 1px dashed currentColor;
    }
  }
  &__back-to-broker {
    padding: 10px 0;
    .btn {
      width: max-content;
      margin: 0;
    }
  }
}
</style>
