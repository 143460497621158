<template>
  <div class="form-part form-part--account_types_main">
    <Input
      label="Account type/name"
      required
      name="name"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      :value="accountTypeData.name ? accountTypeData.name : undefined"
      :changesValue="accountTypeChanges ? accountTypeChanges.name : undefined"
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              accountTypeData && accountTypeData.name
                ? accountTypeData.name
                : null,
              accountTypeChanges && accountTypeChanges.name
                ? accountTypeChanges.name
                : null
            )
          : true
      "
      :error="anchorExist ? 'This name taken, try another' : ''"
    />
    <Input
      label="Minimum account size:"
      name="minAccountSize"
      type="number"
      required
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      :value="accountTypeData.minAccountSize"
      :changesValue="
        accountTypeChanges ? accountTypeChanges.minAccountSize : undefined
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              accountTypeData && accountTypeData.minAccountSize
                ? accountTypeData.minAccountSize
                : null,
              accountTypeChanges && accountTypeChanges.minAccountSize
                ? accountTypeChanges.minAccountSize
                : null
            )
          : true
      "
    />
    <Input
      label="Maximum account size"
      name="maxAccountSize"
      type="number"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      :value="accountTypeData.maxAccountSize"
      :changesValue="
        accountTypeChanges ? accountTypeChanges.maxAccountSize : undefined
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              accountTypeData && accountTypeData.maxAccountSize
                ? accountTypeData.maxAccountSize
                : null,
              accountTypeChanges && accountTypeChanges.maxAccountSize
                ? accountTypeChanges.maxAccountSize
                : null
            )
          : true
      "
    />
    <Input
      label="Minimum position size (in lots, 1 lot = 100,000)"
      name="minPositionSize"
      type="number"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      :value="accountTypeData.minPositionSize"
      step="0.0001"
      :changesValue="
        accountTypeChanges ? accountTypeChanges.minPositionSize : undefined
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              accountTypeData && accountTypeData.minPositionSize
                ? accountTypeData.minPositionSize
                : null,
              accountTypeChanges && accountTypeChanges.minPositionSize
                ? accountTypeChanges.minPositionSize
                : null
            )
          : true
      "
    />
  </div>
</template>
<script>
import Input from "@/components/formFields/Input";
import CompairingService from "@/services/CompairingService";
import { mapGetters } from "vuex";

export default {
  name: "AccountTypeMain",
  components: { Input },
  props: {
    accountTypeData: Object,
    accountTypeChanges: Object,
    anchorExist: Boolean,
  },
  data() {
    return {
      CompairingService: new CompairingService(),
    };
  },
  computed: {
    ...mapGetters(["getSelectedTab"]),
  },
};
</script>
