class MassToggle {
  massReview(valueToSet) {
    this.changeChekers(valueToSet);
    this.commentFieldsControls(valueToSet);
  }
  commentFieldsControls(valueToSet) {
    if (valueToSet.toLowerCase() === "false") {
      document.querySelectorAll(".checker").forEach((item) => {
        item.classList.add("checker--no_comment");
      });
      document.querySelector(".mass_canceled").style.display = "block";

      setTimeout(() => {
        document.querySelector(".mass_canceled__input").focus();
        document.querySelector(".mass_canceled__input").select();
      }, 0);
    } else if (document.querySelector(".mass_canceled")) {
      document.querySelector(".mass_canceled").style.display = "none";
    }
  }

  changeChekers(valueToSet) {
    document.querySelectorAll(".checker_set").forEach((item) => {
      item.elements.forEach((input) => {
        if (
          input.value.toLowerCase() === valueToSet.toLowerCase() &&
          !input.closest(".mass_togglers__container")
        ) {
          input.click();
        } else if (!input.closest(".mass_togglers__container")) {
          input.checked = false;
          input.removeAttribute("checked");
        }
      });
    });
  }
}

export default MassToggle;
