<template>
  <fieldset name="currencyPairs" class="currency_pairs_wrap form-part">
    <CurrencyPairs
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-for="(pair, index) in actualData"
      :key="pair.id"
      :id="pair.id"
      :value="oldValue(pair)"
      :changedValue="newValue(pair, index)"
      :disabled="index === 0"
      @deleted-pair="checkPairsLength"
    />
    <button
      class="add_history_item"
      @click.prevent="
        createNewCurrencyPair(preparingDataServices.createCurrencyPair())
      "
      v-if="
        actualData.length < 10 &&
        this.getCurrentMode !== 'review' &&
        this.getSelectedTab.title !== 'logs'
      "
    >
      <img src="../../assets/svg/ico-plus.svg" alt="+" />
      <span>Add another pair</span>
    </button>
  </fieldset>
</template>

<script>
import CompairingService from "@/services/CompairingService";
import CurrencyPairs from "@/components/formFields/CurrencyPairs";
import PreparingDataServices from "@/services/PreparingDataServices";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CurrencyPairsBlock",

  props: {
    brokerData: Array,
    changesBrokerData: Array,
  },
  components: {
    CurrencyPairs,
  },

  data() {
    return {
      showAll: false,
      compairingService: new CompairingService(),
      preparingDataServices: new PreparingDataServices(),
      mounted: false,
      created: false,
    };
  },
  mounted() {
    if (
      !this.brokerData.length &&
      this.getCurrentMode !== "review" &&
      this.getSelectedTab.title !== "logs" &&
      !this.getAccountType.accountTypeCurrencyPairs.length
    ) {
      this.createNewCurrencyPair(
        this.preparingDataServices.createCurrencyPair("EUR/USD")
      );
    }
  },
  computed: {
    ...mapGetters(["getCurrentMode", "getSelectedTab", "getAccountType"]),
    actualData: (vm) => {
      if (
        vm.$route.name !== "brokerReview" &&
        vm.getSelectedTab.title !== "logs"
      ) {
        if (
          !vm.getAccountType.accountTypeCurrencyPairs.length &&
          !vm.created &&
          !vm.getIsChanged
        ) {
          vm.createNewCurrencyPair(
            vm.preparingDataServices.createCurrencyPair("EUR/USD")
          );
          vm.created = true;
        }
        return vm.getAccountType.accountTypeCurrencyPairs;
      } else if (vm.getSelectedTab.title === "logs" && !vm.brokerData) {
        return vm.changesBrokerData;
      } else if (
        vm.changesBrokerData !== undefined &&
        vm.brokerData !== undefined
      ) {
        return vm.compairingService.getComparedInputData(
          vm.brokerData,
          vm.changesBrokerData,
          "checkboxes"
        );
      } else if (vm.changesBrokerData === undefined && vm.brokerData) {
        return vm.brokerData;
      }
    },
  },
  methods: {
    ...mapMutations(["createNewCurrencyPair"]),
    checkPairsLength() {
      if (!this.actualData.length) {
        this.createNewCurrencyPair(
          this.preparingDataServices.createCurrencyPair("EUR/USD")
        );
      }
    },
    oldValue(pair) {
      let foundedPair = this.brokerData.find((item) => {
        return (
          item.nameCurrencyPair === pair.nameCurrencyPair && item.id === pair.id
        );
      });
      let haveBrokerData = this.brokerData !== undefined;
      return haveBrokerData &&
        (this.$route.name === "brokerReview" ||
          this.getSelectedTab.title !== "logs") &&
        foundedPair
        ? foundedPair
        : haveBrokerData &&
          (this.$route.name === "brokerReview" ||
            this.getSelectedTab.title !== "logs") &&
          foundedPair === -1
        ? pair
        : haveBrokerData && this.$route.name === "brokerEdit" && foundedPair
        ? foundedPair
        : this.preparingDataServices.createNewLogItemCurrencyPair("EUR/USD");
    },
    newValue(pair, index) {
      if (this.changesBrokerData) {
        let foundedItem = this.changesBrokerData.find(
          (item) =>
            item.nameCurrencyPair === pair.nameCurrencyPair &&
            item.id === pair.id
        );
        return this.changesBrokerData !== undefined
          ? foundedItem
            ? this.brokerData.length > 0
              ? foundedItem
              : pair
            : this.preparingDataServices.createNewLogItemCurrencyPair()
          : !this.changesBrokerData
          ? undefined
          : !pair.length && this.changesBrokerData[index]
          ? this.changesBrokerData[index]
          : undefined;
      } else {
        return undefined;
      }
    },
  },
  destroyed() {
    // this.actualData = [];
  },
};
</script>
